import { call, put, takeLatest } from '@redux-saga/core/effects';
import FilesApi from '../../service/api/files';
import { safe } from '../../service/functions';
import { filesErrorsAction, FilesRequestAction, filesSuccessAction } from './actions';
import { DOWNLOADS_REQUEST } from './constants';

function* downloadsRequest(action: FilesRequestAction) {
    let files = yield call(FilesApi.get);

    yield put(filesSuccessAction({ files }));
}

function* onError() {
    yield put(filesErrorsAction({ input: 'Server not responding' }));
}

export default function* DonwloadsSaga() {
    yield takeLatest(DOWNLOADS_REQUEST, safe([onError], downloadsRequest));
}
