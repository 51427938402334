import {connect} from 'react-redux';
import {RootState} from "../../../store/root-reducer";
import AdminUsers from './component';
import {usersRequestAction, usersSuccessAction, blockRequestAction} from './actions';
import AdminUsersState from './state';

const mapStateToProps = (state: RootState): AdminUsersState => state.admin.users;

const actions = {
	usersRequestAction,
	usersSuccessAction,
	blockRequestAction,
};

export default connect(mapStateToProps, actions)(AdminUsers);
