import React, {useState, useEffect, useCallback} from 'react';
import {AnswerData, NewAnswerData} from '../../../service/api/conversation';
import AdminDialoguesState from './state';
import {
    ConversationsRequestArguments,
    QuestionUpdateRequestArguments,
    AnswerUpdateRequestArguments,
    ConversationCreateRequestArguments,
    ConversationDeleteRequestArguments,
} from './actions';
import {AreaData, Conversation} from '../../../service/api/area';
import {t} from "ttag";

export type AdminDialoguesProps = AdminDialoguesState & {
    areasRequestAction: () => void,
    conversationsRequestAction: (args: ConversationsRequestArguments) => void,
    conversationsClearAction: () => void,
    questionUpdateRequestAction: (args: QuestionUpdateRequestArguments) => void,
    answerUpdateRequestAction: (args: AnswerUpdateRequestArguments) => void,
    conversationCreateRequestAction: (args: ConversationCreateRequestArguments) => void,
    conversationDeleteRequestAction: (args: ConversationDeleteRequestArguments) => void,
};

enum DialoguesScreen {
    areas = 0,
    conversations = 1,
    conversation = 2,
}

const MAX_CHARS = 155;

const AdminDialogues: React.FunctionComponent<AdminDialoguesProps> = props => {
    const {
        areasRequestAction,
        conversationsRequestAction,
        conversationsClearAction,
        questionUpdateRequestAction,
        answerUpdateRequestAction,
        conversationCreateRequestAction,
        conversationDeleteRequestAction,
        areas,
        conversations,
        requesting,
    } = props;

    const [activeScreen, setActiveScreen] = useState(DialoguesScreen.areas);

    const [activeArea, setActiveArea] = useState<AreaData | null>(null);
    const [activeQuestion, setActiveQuestion] = useState<Conversation | null>(null);

    const [rightAnswer, setRightAnswer] = useState<AnswerData | null>(null);
    const [semirightAnswer, setSemirightAnswer] = useState<AnswerData | null>(null);
    const [wrongAnswer, setWrongAnswer] = useState<AnswerData | null>(null);

    const [newQuestion, setNewQuestion] = useState("");
    const [newRightAnswer, setNewRightAnswer] = useState<NewAnswerData>({
        questionId: 0,
        answer: '',
        message: '',
        hint: '',
        type: 'r'
    });
    const [newSemirightAnswer, setNewSemirightAnswer] = useState<NewAnswerData>({
        questionId: 0,
        answer: '',
        message: '',
        hint: '',
        type: 's'
    });
    const [newWrongAnswer, setNewWrongAnswer] = useState<NewAnswerData>({
        questionId: 0,
        answer: '',
        message: '',
        hint: '',
        type: 'w'
    });

    const clearNew = useCallback(() => {
        setNewQuestion('');
        setNewRightAnswer({questionId: rightAnswer?.questionId ?? 0, answer: '', message: '', hint: '', type: 'r'});
        setNewSemirightAnswer({
            questionId: semirightAnswer?.questionId ?? 0,
            answer: '',
            message: '',
            hint: '',
            type: 's'
        });
        setNewWrongAnswer({questionId: wrongAnswer?.questionId ?? 0, answer: '', message: '', hint: '', type: 'w'});
    }, [rightAnswer, semirightAnswer, wrongAnswer]);

    const clearOld = useCallback(() => {
        setRightAnswer({id: 0, questionId: rightAnswer?.questionId ?? 0, answer: '', message: '', hint: '', type: 'r'});
        setSemirightAnswer({
            id: 0,
            questionId: semirightAnswer?.questionId ?? 0,
            answer: '',
            message: '',
            hint: '',
            type: 's'
        });
        setWrongAnswer({id: 0, questionId: wrongAnswer?.questionId ?? 0, answer: '', message: '', hint: '', type: 'w'});
    }, [rightAnswer, semirightAnswer, wrongAnswer]);

    useEffect(() => {
        if (!areas || !areas.data || !areas.data.length)
            areasRequestAction();
    }, [areasRequestAction, areas]);

    useEffect(() => {
        if (areas && activeArea != null) {
            conversationsClearAction();
            conversationsRequestAction({area: activeArea});
        }
    }, [areas, conversationsRequestAction, conversationsClearAction, activeArea, activeScreen]);

    useEffect(() => {
        if (activeQuestion !== null) {
            clearOld();
            clearNew();

            let rightAnswer = activeQuestion.answers.find(a => a.type === 'r') ?? null;
            let semiRightAnswer = activeQuestion.answers.find(a => a.type === 's') ?? null;
            let wrongAnswer = activeQuestion.answers.find(a => a.type === 'w') ?? null;

            setRightAnswer(rightAnswer);
            setSemirightAnswer(semiRightAnswer);
            setWrongAnswer(wrongAnswer);

            setNewRightAnswer(rightAnswer as NewAnswerData);
            setNewSemirightAnswer(semiRightAnswer as NewAnswerData);
            setNewWrongAnswer(wrongAnswer as NewAnswerData);
        }
    }, [activeQuestion, clearNew, clearOld]);

    const calRemainingChars = (defaultValue: string, newValue: string) => {
        return Math.max(newValue.length ? (MAX_CHARS - newValue.length) : (MAX_CHARS - defaultValue.length), 0);
    }

    const isAnswerDifferent = (newAnswer: NewAnswerData, oldAnswer: AnswerData | null) => {
        if (!oldAnswer)
            return false;
        if (newAnswer.answer !== oldAnswer.answer)
            return true;
        else if (newAnswer.hint !== oldAnswer.hint)
            return true;
        else if (newAnswer.message !== oldAnswer.message)
            return true;
        return false;
    }

    const updateQuestion = () => {
        return new Promise((resolve) => {
            let counter = 0;

            if (newQuestion)
                questionUpdateRequestAction({
                    id: activeQuestion?.id ?? 0,
                    body: {areaId: activeArea?.id ?? 0, question: newQuestion, order: activeQuestion?.order ?? 0},
                    callback: () => {
                        counter++;
                        if (counter === 4)
                            resolve(true);
                    }
                });
            else
                counter++;

            if (isAnswerDifferent(newRightAnswer, rightAnswer))
                answerUpdateRequestAction({
                    id: rightAnswer?.id ?? 0, body: newRightAnswer, callback: () => {
                        counter++;
                        if (counter === 4)
                            resolve(true);
                    }
                });
            else
                counter++;

            if (isAnswerDifferent(newSemirightAnswer, semirightAnswer))
                answerUpdateRequestAction({
                    id: semirightAnswer?.id ?? 0, body: newSemirightAnswer, callback: () => {
                        counter++;
                        if (counter === 4)
                            resolve(true);
                    }
                });
            else
                counter++;

            if (isAnswerDifferent(newWrongAnswer, wrongAnswer))
                answerUpdateRequestAction({
                    id: wrongAnswer?.id ?? 0, body: newWrongAnswer, callback: () => {
                        counter++;
                        if (counter === 4)
                            resolve(true);
                    }
                });
            else
                counter++;

            if (counter === 4)
                resolve(true);
        });
    };

    const createQuestion = () => {
        let order = 0;

        if (conversations.data.length === 0) {
            order = 1;
        } else {
            do {
                order = parseInt(window.prompt('Enter the order of the new question:', (conversations.data[conversations.data.length - 1].order + 1).toString()) ?? '');
            } while (isNaN(order) || order < 1);
        }

        conversationCreateRequestAction({
            data: {
                areaId: activeArea?.id ?? 0,
                question: ' ',
                order,
            }
        });
    };

    const deleteQuestion = () => {
        conversationsClearAction();
        conversationDeleteRequestAction({areaId: activeArea?.id ?? 0, questionId: activeQuestion?.id ?? 0});
    };

    return (
        <div className="w-full min-h-screen admin-dialogues">
            <div className="admin-dialogues-inner-body w-full min-h-screen flex justify-center">
                <div className="w-full">
                    <div className="flex justify-center dialogues-nav mt-2">
                        <p style={{color: "white"}} onClick={() => {
                            clearNew();
                            setActiveScreen(DialoguesScreen.areas)
                        }}>
                            Game
                        </p>

                        <p style={{color: "white"}} onClick={() => {
                            clearNew();
                            setActiveScreen(DialoguesScreen.conversations)
                        }}>
                            {activeScreen >= DialoguesScreen.conversations ? " > Area " + (activeArea?.name ?? '') : null}
                        </p>

                        <p style={{color: "white"}}>
                            {activeScreen >= DialoguesScreen.conversation ? " > Question " + (activeQuestion?.order ?? 0) : null}
                        </p>
                    </div>

                    {requesting && (
                        <div className="scenario-cards flex flex-wrap mx-auto justify-center my-10 text-white p-10">
                            Loading...
                        </div>
                    )}

                    {!requesting && (
                        <div>
                            {activeScreen === DialoguesScreen.areas && (
                                <div className="scenario-cards flex flex-wrap mx-auto justify-center my-10">
                                    {areas.data && areas.data.map((area, index) => (
                                        <div key={index}
                                             className={"scenario-box flex justify-center mr-2 my-1 " + ((areas.data.length === (index + 1) && (areas.data.length % 2 === 1)) ? "mr-auto" : null)}
                                             onClick={() => {
                                                 setActiveScreen(DialoguesScreen.conversations);
                                                 setActiveArea(area)
                                             }}>
                                            <p style={{color: "white"}} className="my-auto">{area.name}</p>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {activeScreen === DialoguesScreen.conversations && (
                                <div>
                                    <div className="round-cards-body flex flex-wrap my-4 justify-center mx-auto">
                                        <div className="round-card flex align-center justify-center mr-2 my-1"
                                             onClick={() => { createQuestion() }}>
                                            <p style={{color: '#242E42'}} className="my-auto">+</p>
                                        </div>
                                    </div>
                                    <div className="round-cards-body flex flex-wrap my-4 justify-center mx-auto">
                                        {conversations && conversations.data && conversations.data.map((question, index) => (
                                            <div key={index}
                                                 className="round-card flex align-center justify-center mr-2 my-1"
                                                 onClick={() => {
                                                     setActiveScreen(DialoguesScreen.conversation)
                                                     setActiveQuestion(question);
                                                 }}>
                                                <p style={{color: '#242E42'}}
                                                   className="my-auto">Question {question.order}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {activeScreen === DialoguesScreen.conversation && (
                                <div
                                    className="round-modification-window my-10 relative flex mx-auto justify-center flex-wrap">
                                    <div className="mt-2">
                                        <div className="flex flex-row justify-between w-full">
                                            <p style={{color: '#D25B8D'}}>Question</p>
                                            <span
                                                className='text-warning'>* {calRemainingChars(activeQuestion ? activeQuestion!.question : '', newQuestion)}</span>
                                        </div>
                                        <textarea className="answers-input" cols={5} rows={3} maxLength={MAX_CHARS}
                                                  defaultValue={activeQuestion?.question}
                                                  onChange={e => setNewQuestion(e.target.value.trim())}/>
                                    </div>

                                    <div className="flex flex-col">
                                        <div className="flex flex-col lg:flex-row mt-4 items-stretch">
                                            <div className="flex flex-1 flex-col mt-2 lg:mr-2">
                                                <div className="flex flex-row w-full justify-between">
                                                    <p style={{color: "#F0F5AE"}}>Right answer</p>
                                                    <span
                                                        className='text-warning'>* {calRemainingChars(rightAnswer ? rightAnswer!.answer : '', newRightAnswer.answer)}</span>
                                                </div>
                                                <textarea key={rightAnswer?.answer}
                                                          className="flex flex-grow answers-input"
                                                          cols={5} maxLength={MAX_CHARS}
                                                          defaultValue={rightAnswer?.answer}
                                                          onChange={e => setNewRightAnswer({
                                                              ...newRightAnswer,
                                                              answer: e.target.value.trim()
                                                          })}/>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between w-full h-full">
                                                <div className="mt-2">
                                                    <p style={{color: "#F0F5AE"}}>Right answer message</p>
                                                    <textarea key={rightAnswer?.answer} className="answers-input"
                                                              cols={5}
                                                              rows={3} defaultValue={rightAnswer?.message}
                                                              onChange={e => setNewRightAnswer({
                                                                  ...newRightAnswer,
                                                                  message: e.target.value.trim()
                                                              })}/>
                                                    {/*<span className='text-warning'>*{t` ${calRemainingChars(rightAnswer ? rightAnswer!.answer : '', newRightAnswer)} characters left`}</span>*/}
                                                </div>
                                                <div className="mt-2">
                                                    <p style={{color: "#F0F5AE"}}>Right answer hint</p>
                                                    <textarea key={rightAnswer?.answer} className="answers-input"
                                                              cols={5}
                                                              rows={3} defaultValue={rightAnswer?.hint}
                                                              onChange={e => setNewRightAnswer({
                                                                  ...newRightAnswer,
                                                                  hint: e.target.value.trim()
                                                              })}/>
                                                    {/*<span className='text-warning'>*{t` ${calRemainingChars(rightAnswer ? rightAnswer!.answer : '', newRightAnswer)} characters left`}</span>*/}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="flex flex-col lg:flex-row mt-4 items-stretch">
                                            <div className="flex flex-1 flex-col mt-2 lg:mr-2">
                                                <div className="flex flex-row w-full justify-between">
                                                    <p style={{color: "#F0F5AE"}}>Semiright answer</p>
                                                    <span
                                                        className='text-warning'>* {calRemainingChars(semirightAnswer ? semirightAnswer!.answer : '', newSemirightAnswer.answer)}</span>
                                                </div>
                                                <textarea key={semirightAnswer?.answer}
                                                          className="flex flex-grow answers-input"
                                                          cols={5} rows={3} maxLength={MAX_CHARS}
                                                          defaultValue={semirightAnswer?.answer}
                                                          onChange={e => setNewSemirightAnswer({
                                                              ...newSemirightAnswer,
                                                              answer: e.target.value.trim()
                                                          })}/>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between w-full h-full">
                                                <div className="mt-2">
                                                    <p style={{color: "#F0F5AE"}}>Semiright answer message</p>
                                                    <textarea key={semirightAnswer?.answer} className="answers-input"
                                                              cols={5}
                                                              rows={3} defaultValue={semirightAnswer?.message}
                                                              onChange={e => setNewSemirightAnswer({
                                                                  ...newSemirightAnswer,
                                                                  message: e.target.value.trim()
                                                              })}/>
                                                    {/*<span className='text-warning'>*{t` ${calRemainingChars(semirightAnswer ? semirightAnswer!.answer : '', newSemirightAnswer)} characters left`}</span>*/}
                                                </div>
                                                <div className="mt-2">
                                                    <p style={{color: "#F0F5AE"}}>Semiright answer hint</p>
                                                    <textarea key={semirightAnswer?.answer} className="answers-input"
                                                              cols={5}
                                                              rows={3} defaultValue={semirightAnswer?.hint}
                                                              onChange={e => setNewSemirightAnswer({
                                                                  ...newSemirightAnswer,
                                                                  hint: e.target.value.trim()
                                                              })}/>
                                                    {/*<span className='text-warning'>*{t` ${calRemainingChars(semirightAnswer ? semirightAnswer!.answer : '', newSemirightAnswer)} characters left`}</span>*/}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col lg:flex-row mt-4 items-stretch">
                                            <div className="flex flex-1 flex-col mt-2 lg:mr-2">
                                                <div className="flex flex-row w-full justify-between">
                                                    <p style={{color: "#F0F5AE"}}>Wrong answer</p>
                                                    <span
                                                        className='text-warning'>* {calRemainingChars(wrongAnswer ? wrongAnswer!.answer : '', newWrongAnswer.answer)}</span>
                                                </div>
                                                <textarea key={wrongAnswer?.answer}
                                                          className="flex flex-grow answers-input"
                                                          cols={5} rows={3} maxLength={MAX_CHARS}
                                                          defaultValue={wrongAnswer?.answer}
                                                          onChange={e => setNewWrongAnswer({
                                                              ...newWrongAnswer,
                                                              answer: e.target.value.trim()
                                                          })}/>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-between w-full h-full">
                                                <div className="mt-2">
                                                    <p style={{color: "#F0F5AE"}}>Wrong answer message</p>
                                                    <textarea key={wrongAnswer?.answer} className="answers-input"
                                                              cols={5}
                                                              rows={3} defaultValue={wrongAnswer?.message}
                                                              onChange={e => setNewWrongAnswer({
                                                                  ...newWrongAnswer,
                                                                  message: e.target.value.trim()
                                                              })}/>
                                                    {/*<span className='text-warning'>*{t` ${calRemainingChars(wrongAnswer ? wrongAnswer!.answer : '', newWrongAnswer)} characters left`}</span>*/}
                                                </div>
                                                <div className="mt-2">
                                                    <p style={{color: "#F0F5AE"}}>Wrong answer hint</p>
                                                    <textarea key={wrongAnswer?.answer} className="answers-input"
                                                              cols={5}
                                                              rows={3} defaultValue={wrongAnswer?.hint}
                                                              onChange={e => setNewWrongAnswer({
                                                                  ...newWrongAnswer,
                                                                  hint: e.target.value.trim()
                                                              })}/>
                                                    {/*<span className='text-warning'>*{t` ${calRemainingChars(wrongAnswer ? wrongAnswer!.answer : '', newWrongAnswer)} characters left`}</span>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-row justify-between w-full mt-4">
                                        <button className="delete-question" onClick={() => {
                                            if (!window.confirm(t`Do you want to delete this question?`)) return;
                                            deleteQuestion();
                                            setActiveScreen(DialoguesScreen.conversations)
                                        }}>
                                            Delete Question
                                        </button>
                                        <button className="apply-changes"
                                                onClick={() => { updateQuestion().then(() => { setActiveScreen(DialoguesScreen.conversations)}) }}>
                                            Apply changes
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminDialogues;
