import {
    QR_REQUEST,
    QR_ERROR,
    QR_SUCCESS
} from './constants';
import {QRAction} from './actions';
import QRState from './state';

const InitialState: QRState = {
    requesting: false,
    success: false,
    errors: {},
};

export default function (state = InitialState, action: QRAction): QRState {
    switch (action.type) {
        case QR_REQUEST:
            return {
                requesting: true,
                success: false,
                errors: state.errors,
            };

        case QR_SUCCESS:
            return {
                requesting: false,
                success: true,
                errors: {},
            };

        case QR_ERROR:
            return {
                requesting: false,
                success: false,
                errors: action.errors,
            };

        default:
            return state;
    }
}
