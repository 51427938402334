import AdminDialogues from './dialogues';
import AdminDownloads from './downloads';
import AdminStatistics from './statistics';
import AdminUsers from './users';

const AdminRoutes = [
    {
        pathname: '/admin',
        exact: true,
        component: AdminStatistics,
    },
    {
        pathname: '/admin/dialogues',
        exact: true,
        component: AdminDialogues,
    },
    {
        pathname: '/admin/statistics',
        exact: true,
        component: AdminStatistics,
    },
    {
        pathname: '/admin/users',
        exact: true,
        component: AdminUsers,
    },
    {
        pathname: '/admin/downloads',
        exact: true,
        component: AdminDownloads,
    },
];

export default AdminRoutes;
