import {call, put, takeLatest} from 'redux-saga/effects';

import AreaApi from '../../../service/api/area';
import ClientStorage from '../../../client/storage';
import {t} from 'ttag';

import {
    dialogErrorAction,
    dialogSuccessAction,
} from './actions';
import {DIALOG_REQUEST} from './constants';
import {DialogRequestAction} from './actions';
import {safe} from '../../../service/functions';


function* dialogsRequest(action: DialogRequestAction) {
    let client = yield call(ClientStorage.getItem);

    let conversations = yield call(AreaApi.getAreaConversations, client.auth, action.area);

    yield put(dialogSuccessAction({conversations: {data: conversations}}));
}

function* onError() {
    yield put(dialogErrorAction({input: t`Areas error.` + ' - ' + t`server not responding`}));
}


export default function* DialogSaga() {
    yield takeLatest(DIALOG_REQUEST, safe([onError], dialogsRequest));
}
