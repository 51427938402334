import Action from '../../../service/action';
import { Files } from '../../../service/api/files';
import { Errors } from '../../../service/errors/state';
import {
    DOWNLOADS_ERROR,
    DOWNLOADS_REQUEST,
    DOWNLOADS_SUCCESS,
    FILE_CREATE_ERROR,
    FILE_CREATE_REQUEST,
    FILE_CREATE_SUCCESS,
    FILE_DELETE_ERROR,
    FILE_DELETE_REQUEST,
    FILE_DELETE_SUCCESS,
    FILE_UPDATE_ERROR,
    FILE_UPDATE_REQUEST,
    FILE_UPDATE_SUCCESS,
} from './constants';

export interface FilesErrorArguments {
    errors: Errors;
}

export interface FilesSuccessArguments {
    files: Files;
}

export interface FileDeleteRequestArguments {
    internalName: string;
}

export interface FileUpdateRequestArguments {
    internalName: string;
    title?: string;
    description?: string;
}

export interface FileUpdateSuccessArguments {
    internalName: string;
    name: string;
    title: string;
    description: string;
}

export interface FileCreateRequestArguments {
    file: FormData;
    title: string;
    description: string;
}

export interface FileCreateSuccessArguments {
    internalName: string;
}

export interface FilesRequestAction extends Action<typeof DOWNLOADS_REQUEST> {}
export interface FilesSuccessAction extends Action<typeof DOWNLOADS_SUCCESS>, FilesSuccessArguments {}
export interface FilesErrorAction extends Action<typeof DOWNLOADS_ERROR>, FilesErrorArguments {}
export interface FileDeleteRequestAction extends Action<typeof FILE_DELETE_REQUEST>, FileDeleteRequestArguments {}
export interface FileDeleteSuccessAction extends Action<typeof FILE_DELETE_SUCCESS> {}
export interface FileDeleteErrorAction extends Action<typeof FILE_DELETE_ERROR>, FilesErrorArguments {}
export interface FileUpdateRequestAction extends Action<typeof FILE_UPDATE_REQUEST>, FileUpdateRequestArguments {}
export interface FileUpdateSuccessAction extends Action<typeof FILE_UPDATE_SUCCESS>, FileUpdateSuccessArguments {}
export interface FileUpdateErrorAction extends Action<typeof FILE_UPDATE_ERROR>, FilesErrorArguments {}
export interface FileCreateRequestAction extends Action<typeof FILE_CREATE_REQUEST>, FileCreateRequestArguments {}
export interface FileCreateSuccessAction extends Action<typeof FILE_CREATE_SUCCESS>, FileCreateSuccessArguments {}
export interface FileCreateErrorAction extends Action<typeof FILE_CREATE_ERROR>, FilesErrorArguments {}

export type AdminDownloadsAction =
    | FilesRequestAction
    | FilesSuccessAction
    | FilesErrorAction
    | FileDeleteRequestAction
    | FileDeleteSuccessAction
    | FileDeleteErrorAction
    | FileUpdateRequestAction
    | FileUpdateSuccessAction
    | FileUpdateErrorAction
    | FileCreateRequestAction
    | FileCreateSuccessAction
    | FileCreateErrorAction;

export function filesRequestAction(): FilesRequestAction {
    return {
        type: DOWNLOADS_REQUEST,
    };
}

export function filesSuccessAction(args: FilesSuccessArguments): FilesSuccessAction {
    return {
        type: DOWNLOADS_SUCCESS,
        ...args,
    };
}

export function filesErrorsAction(errors: Errors): FilesErrorAction {
    return {
        type: DOWNLOADS_ERROR,
        errors,
    };
}

export function fileDeleteRequestAction(args: FileDeleteRequestArguments): FileDeleteRequestAction {
    return {
        type: FILE_DELETE_REQUEST,
        ...args,
    };
}

export function fileDeleteSuccessAction(): FileDeleteSuccessAction {
    return {
        type: FILE_DELETE_SUCCESS,
    };
}

export function fileDeleteErrorAction(errors: Errors): FileDeleteErrorAction {
    return {
        type: FILE_DELETE_ERROR,
        errors,
    };
}

export function fileUpdateRequestAction(args: FileUpdateRequestArguments): FileUpdateRequestAction {
    return {
        type: FILE_UPDATE_REQUEST,
        ...args,
    };
}

export function fileUpdateSuccessAction(args: FileUpdateSuccessArguments): FileUpdateSuccessAction {
    return {
        type: FILE_UPDATE_SUCCESS,
        ...args,
    };
}

export function fileUpdateErrorAction(errors: Errors): FileUpdateErrorAction {
    return {
        type: FILE_UPDATE_ERROR,
        errors,
    };
}

export function fileCreateRequestAction(args: FileCreateRequestArguments): FileCreateRequestAction {
    return {
        type: FILE_CREATE_REQUEST,
        ...args,
    };
}

export function fileCreateSuccessAction(args: FileCreateSuccessArguments): FileCreateSuccessAction {
    return {
        type: FILE_CREATE_SUCCESS,
        ...args,
    };
}

export function fileCreateErrorAction(errors: Errors): FileCreateErrorAction {
    return {
        type: FILE_CREATE_ERROR,
        errors,
    };
}
