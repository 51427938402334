import {combineReducers} from 'redux';

import login from './login/reducers';
import resetToken from './reset-token/reducers';
import passwordReset from './password-reset/reducers';
import signup from './signup/reducers';

const AuthReducer = combineReducers({
	login,
	resetToken,
	passwordReset,
	signup,
});

export type AuthState = ReturnType<typeof AuthReducer>;
export default AuthReducer;
