import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {facebookSignupAction, signupClearAction, signupRequestAction} from './actions';

import Signup from './component';
import {RootState} from '../../../store/root-reducer';
import SignupState from './state';

const mapStateToProps = (state: RootState): SignupState => state.auth.signup;
const actions = {
    signupRequestAction,
    facebookSignupAction,
    signupClearAction,
};

export default withRouter(connect(mapStateToProps, actions)(Signup));
