import React, {createContext, useEffect, useState} from 'react';

import {Link} from 'react-router-dom';
import {BrowserHistory, Location} from 'history';
import LoginState from './state';
import {LoginRequestArguments} from './actions';
import Error from '../../../service/errors';

import facebookLogo from '../../../assets/icons/facebook.svg';
import checkmarkIcon from '../../../assets/icons/checkmark.png';
import {actionOnEnter, focusOnEnter} from '../../../service/functions';
import {t} from 'ttag';
import {Roles} from "../../../client/state";
import ResetToken from "../reset-token";

type LoginProps = LoginState & {
	loginRequestAction: (args: LoginRequestArguments) => void,
	facebookLoginAction: () => void,
	loginClearAction: () => void,
	location: Location,
	history: BrowserHistory,
};

export const LoginModalContext = createContext(() => {});

const Login: React.FunctionComponent<LoginProps> = props => {
	const {
		loginRequestAction,
		facebookLoginAction,
		loginClearAction,
		success,
		errors,
		location,
		history,
	} = props;

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [displayModal, setDisplayModal] = useState(false);
	const [signedUp, setSignedUp] = useState(false);
	const [gameLogin, setGameLogin] = useState(false);

	useEffect(() => {
		loginClearAction();

		if (location.state && location.state.hasOwnProperty('signup')) {
			setSignedUp(true);
		}

		if (location.state && location.state.hasOwnProperty('game')) {
			setGameLogin(true);
		}

	}, [loginClearAction, location.state]);


	return (
		<div className='page-container flex flex-grow justify-center items-center'>
			{displayModal && (
				<LoginModalContext.Provider value={() => setDisplayModal(false)}>
					<ResetToken/>
				</LoginModalContext.Provider>
			)}

			<div className='main-container flex flex-col justify-center items-center w-full max-w-full h-auto'>

				{success && (
					<span className='text-error flex flex-row w-full'>
					<img src={checkmarkIcon} alt='success'/>
						{t`signed`}
					</span>
				)}

				<div className='m-8 mt-10'>
					<Link to='/' className='text-2xl underline'>
						{t`go to main page`}
					</Link>
				</div>
				<div className='form-container'>
					<div className={`flex flex-grow justify-start ${signedUp ? '' : 'mb-6'}`}>
						{signedUp && (
							<span className='text-success'>
								<img src={checkmarkIcon} alt="success"
									 className='input-success'
								/>
								{t`You have successfully been registered, please log in.`}
							</span>
						)}
						{gameLogin && (
							<span className='text-warning ml-4'>
								{t`Please log in to play the game.`}
							</span>
						)}
					</div>
					<div className='login-email-div flex flex-col flex-grow justify-center m-4 mb-4'>
						<p className='input-header'>
							{t`email`}
						</p>
						<input onKeyPress={(e) => {focusOnEnter(e.key, 'input-password');}}
							   className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'email' ? 'bg-warning' : 'bg-opacity-25'}`}
							   type="email"
							   value={email}
							   onChange={e => setEmail(e.target.value)}
							   disabled={displayModal}
						/>
					</div>
					<Error {...errors} value='email'/>

					<div className='flex flex-col flex-grow justify-center m-4 mb-8'>
						<p className='input-header'>
							{t`password`}
						</p>
						<input
							onKeyPress={e => (
								actionOnEnter(e.key, () => loginRequestAction({
									email,
									password,
									callback: (client) => {
										if(client && client.user && client.user.role === Roles.admin) {
											history.push('/admin');
										}
										if(gameLogin) {
											history.push('/avatar');
										}
									}
								}))
							)}
							className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'password' ? 'bg-warning' : 'bg-opacity-25'}`}
							id="input-password"
							type="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
							disabled={displayModal}
						/>
					</div>
					<Error {...errors} value='password'/>
					<Error {...errors} value='input'/>

					<div className='flex justify-center m-5 mb-12'>
						<button onClick={() => loginRequestAction({email, password, callback: (client) => {
								if(client && client.user && client.user.role === Roles.admin) {
									history.push('/admin');
								}
								if(gameLogin) {
									history.push('/avatar');
								}
							}})}
								className='login-button text-button mb-3'>
							{t`login`}
						</button>
					</div>
					<div className='flex flex-col flex-grow flex-wrap justify-center items-center m-5 mb-10 mt-10'>
						<button onClick={() => facebookLoginAction()} className='fb-signup-button text-button'>
							<div className='flex flex-row flex-grow justify-center'>
								{t`login with`} <img className='ml-2' src={facebookLogo} alt='facebook'/>
							</div>
						</button>
						<Link to='/signup' className='signup-button text-button text-blue'>
							{t`sign up`}
						</Link>
					</div>
					<div className='flex flex-grow justify-center mb-20'>
						<button onClick={() => setDisplayModal(true)}
								className='text-link underline'>
							{t`forgot your password?`}
						</button>
					</div>
				</div>
			</div>
		</div>

	);
};

export default Login;
