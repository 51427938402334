import React from 'react';

import {renderRedirect, renderRouter} from '../router';
import AuthRoutes from './routes';

export type AuthProps = {
	authenticated: boolean,
}

const Auth: React.FunctionComponent<AuthProps> = ({authenticated}) =>
	authenticated ?
		renderRedirect('/') :
		renderRouter('auth', AuthRoutes);

export default Auth;
