import React, { useState } from 'react';
import CancelButton from '../../../../assets/icons/delete-button.png';

export type EditBoxProps = {
    mode: 'new' | 'edit';
    title: string;
    setTitle: (newVal: string) => void;
    description: string;
    setDescription: (newVal: string) => void;
    confirmChanges: () => void;
    cancelChanges: () => void;
    setFile?: (newVal: File) => void;
};

export const EditBox: React.FC<EditBoxProps> = (props) => {
    const { title, description, setTitle, setDescription, cancelChanges, confirmChanges, mode, setFile } = props;
    const [fileName, setFileName] = useState('');
    return (
        <div className='bg-blue px-2 py-2 w-screen flex flex-col md:w-full'>
            <img src={CancelButton} alt='cancel' className='ml-auto w-8 cursor-pointer' onClick={cancelChanges} />
            <div className='flex flex-col'>
                <p className='text-yellow'>Title</p>
                <input
                    type='text'
                    placeholder='Title'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className='bg-opacity-25 bg-white my-2 text-white py-2 px-2'
                />
                <p className='text-yellow mt-2'>Description</p>
                <textarea
                    placeholder='Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className='bg-opacity-25 bg-white my-2 text-white py-2 px-2'
                />
                {mode === 'new' && !!setFile && (
                    <div>
                        <p className='text-yellow mt-2'>Upload file</p>
                        <label htmlFor='new-file-upload'>
                            <p className='choose-button pl-3 py-2 w-16 text-blue cursor-pointer'>Choose</p>
                        </label>
                        <input
                            type='file'
                            id='new-file-upload'
                            className='hidden'
                            onChange={(e) => {
                                setFile(e.target.files![0]);
                                setFileName(e.target.files![0].name);
                            }}
                        />
                        <p className='text-white'>{!!fileName ? fileName : 'no file chosen'}</p>
                    </div>
                )}
                <button className='apply-changes text-white py-2 mt-4 w-24 mx-auto' onClick={confirmChanges}>
                    {mode === 'new' ? 'Create' : 'Apply changes'}
                </button>
            </div>
        </div>
    );
};
