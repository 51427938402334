import React, {useEffect} from 'react';

import {renderRedirect, renderRouter} from '../router';
import GameRoutes from './routes';
import {LoginRefreshArguments} from "../auth/login/actions";

export type GameProps = {
    authenticated: boolean,
    loginRefreshAction: (args: LoginRefreshArguments) => void,
}

const Game: React.FunctionComponent<GameProps> = props => {
    const {
        authenticated,
        loginRefreshAction,
    } = props;

    useEffect(() => {
        loginRefreshAction({callback: () => {}});
    }, [authenticated, loginRefreshAction])

    return (authenticated ?
                renderRouter('game', GameRoutes) :
                renderRedirect('/'))
};

export default Game;
