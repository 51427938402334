import {
    AREAS_REQUEST,
    AREAS_CLEAR,
    AREAS_ERROR,
    AREAS_SUCCESS,
    AREA_SET,
    AREA_CLEAR,
    AREA_HISTORY_POST,
} from './constants';
import Action from '../../../service/action';
import {Errors} from "../../../service/errors/state";
import {AreaData, AreaHistoryData, Areas} from "../../../service/api/area";


export interface AreasErrorArguments {
    errors: Errors,
}

export interface AreasSuccessArguments {
    areas: Areas,
}

export interface AreaSetArguments {
    area: AreaData,
}

export interface AreaHistoryPostArguments {
    area: AreaData,
    data: AreaHistoryData,
}

export interface AreasRequestAction extends Action<typeof AREAS_REQUEST> {}
export interface AreasSuccessAction extends Action<typeof AREAS_SUCCESS>, AreasSuccessArguments {}
export interface AreasClearAction extends Action<typeof AREAS_CLEAR> {}
export interface AreasErrorAction extends Action<typeof AREAS_ERROR>, AreasErrorArguments {}
export interface AreaSetAction extends Action<typeof AREA_SET>, AreaSetArguments {}
export interface AreaClearAction extends Action<typeof AREA_CLEAR> {}
export interface AreaHistoryPostAction extends Action<typeof AREA_HISTORY_POST>, AreaHistoryPostArguments {}

export type AreasAction =
    AreasRequestAction |
    AreasSuccessAction |
    AreasClearAction |
    AreasErrorAction |
    AreaSetAction |
    AreaClearAction |
    AreaHistoryPostAction;

export function areasRequestAction(): AreasRequestAction {
    return {
        type: AREAS_REQUEST
    };
}

export function areasSuccessAction(args: AreasSuccessArguments): AreasSuccessAction {
    return {
        type: AREAS_SUCCESS,
        ...args
    };
}

export function areasErrorAction(errors: Errors): AreasErrorAction {
    return {
        type: AREAS_ERROR,
        errors
    };
}

export function areasClearAction(): AreasClearAction {
    return {
        type: AREAS_CLEAR
    };
}

export function areaSetAction(args: AreaSetArguments): AreaSetAction {
    return {
        type: AREA_SET,
        ...args
    };
}

export function areaHistoryPostAction(args: AreaHistoryPostArguments): AreaHistoryPostAction {
    return {
        type: AREA_HISTORY_POST,
        ...args
    };
}

export function areaClearAction(): AreaClearAction {
    return {
        type: AREA_CLEAR
    };
}
