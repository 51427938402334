import React, { useEffect, useState } from "react";
import volumeOn from "../../assets/icons/volume_on.png";
import volumeOff from "../../assets/icons/volume_off.png";

type MusicTogglerProps = {
  handleChange: () => void;
};

const MusicToggler: React.FC<MusicTogglerProps> = ({ handleChange }) => {
  const [musicMuted, setMusicMuted] = useState(false);
  const toggleMute = () => {
    if (!window.localStorage.getItem('MUSIC_DATA')) {
      window.localStorage.setItem(
        'MUSIC_DATA',
        JSON.stringify({ MUSIC_MUTED: true })
      );
    } else {
      const isMuted = JSON.parse(window.localStorage.getItem("MUSIC_DATA")!);
      if (!isMuted['MUSIC_MUTED']) {
        window.localStorage.setItem(
          'MUSIC_DATA',
          JSON.stringify({ MUSIC_MUTED: true })
        );
      } else {
        window.localStorage.setItem(
          'MUSIC_DATA',
          JSON.stringify({ MUSIC_MUTED: false })
        );
      }
    }
  };
  useEffect(() => {
    if (!!window.localStorage.getItem('MUSIC_DATA')) {
      setMusicMuted(
        JSON.parse(window.localStorage.getItem('MUSIC_DATA')!)["MUSIC_MUTED"]
      );
    }
  }, [musicMuted]);

  return (
    <div className="absolute top-0 right-0 m-4">
      <img src={musicMuted ? volumeOff : volumeOn} alt="mute" className="w-8" onClick={() => {handleChange(); toggleMute(); setMusicMuted(prev => !prev)}}/>
    </div>
  );
};

export default MusicToggler;
