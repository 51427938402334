import {
    FACEBOOK_SIGNUP_REQUEST,
    SIGNUP_CANCEL,
    SIGNUP_CLEAR,
    SIGNUP_ERROR,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
} from './constants';
import {SignupData, UserData} from '../../../service/api/auth';
import Action from '../../../service/action';
import {Errors} from '../../../service/errors/state';

export interface SignupErrorArguments {
    errors: Errors,
}

export interface SignupRequestArguments extends SignupData, UserData {
    passwordConfirm: string,
    callback?: () => void,
}
export interface SignupCancelAction extends Action<typeof SIGNUP_CANCEL> {}
export interface FacebookSignupRequestAction extends Action<typeof FACEBOOK_SIGNUP_REQUEST> {}
export interface SignupErrorAction extends Action<typeof SIGNUP_ERROR>, SignupErrorArguments {}
export interface SignupRequestAction extends Action<typeof SIGNUP_REQUEST>, SignupRequestArguments {}
export interface SignupClearAction extends Action<typeof SIGNUP_CLEAR> {}
export interface SignupSuccessAction extends Action<typeof SIGNUP_SUCCESS> {}

export type SignupAction =
    SignupCancelAction |
    FacebookSignupRequestAction |
    SignupErrorAction |
    SignupRequestAction |
    SignupClearAction |
    SignupSuccessAction;

export function signupErrorAction(errors: Errors): SignupErrorAction {
    return {
        type: SIGNUP_ERROR,
        errors,
    };
}

export function signupRequestAction(arg: SignupRequestArguments): SignupRequestAction {
    return {
        type: SIGNUP_REQUEST,
        ...arg,
    };
}

export function signupClearAction(): SignupClearAction {
    return {
        type: SIGNUP_CLEAR,
    };
}

export function signupCancelAction(): SignupCancelAction {
    return {
        type: SIGNUP_CANCEL,
    };
}

export function facebookSignupAction(): FacebookSignupRequestAction {
    return {
        type: FACEBOOK_SIGNUP_REQUEST,
    };
}

export function signupSuccessAction(): SignupSuccessAction {
    return {
        type: SIGNUP_SUCCESS,
    };
}
