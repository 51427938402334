import {applyMiddleware, createStore, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';

import RootReducer from './root-reducer';
import RootSaga from './root-saga';


const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const sagaMiddleware = createSagaMiddleware();

const ReduxStore = createStore(
    RootReducer,
    compose(applyMiddleware(sagaMiddleware), sentryReduxEnhancer),
);
sagaMiddleware.run(RootSaga);

export default ReduxStore;
