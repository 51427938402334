import {CLIENT_SET, CLIENT_UNSET} from './constants';
import ClientState from './state';
import {ClientAction} from './actions';

const initialState: ClientState = {
	user: null,
	auth: null,
};

export default function (state = initialState, action: ClientAction): ClientState {
	switch (action.type) {
		case CLIENT_SET:
			return {
				...state,
				user: action.data.user,
				auth: action.data.auth,
			};

		case CLIENT_UNSET:
			return initialState;

		default:
			return state;
	}
}
