import {
	PASSWORD_RESET_CANCEL,
	PASSWORD_RESET_CLEAR,
	PASSWORD_RESET_ERROR,
	PASSWORD_RESET_REQUEST,
	PASSWORD_RESET_SUCCESS,
} from './constants';
import {PasswordResetCredentials} from '../../../service/api/auth';
import Action from '../../../service/action';
import {Errors} from '../../../service/errors/state';

export interface PasswordResetRequestArguments extends PasswordResetCredentials {
	callback?: () => void,
}
export interface PasswordResetErrorArguments {
	errors: Errors,
}

export interface PasswordResetRequestAction extends Action<typeof PASSWORD_RESET_REQUEST>, PasswordResetRequestArguments {}
export interface PasswordResetSuccessAction extends Action<typeof PASSWORD_RESET_SUCCESS> {}
export interface PasswordResetErrorAction extends Action<typeof PASSWORD_RESET_ERROR>, PasswordResetErrorArguments {}
export interface PasswordResetClearAction extends Action<typeof PASSWORD_RESET_CLEAR> {}
export interface PasswordResetCancelAction extends Action<typeof PASSWORD_RESET_CANCEL> {}

export type PasswordResetAction =
	PasswordResetRequestAction |
	PasswordResetSuccessAction |
	PasswordResetErrorAction |
	PasswordResetClearAction |
	PasswordResetCancelAction;

export function passwordResetRequestAction(arg: PasswordResetRequestArguments): PasswordResetRequestAction {
	return {
		type: PASSWORD_RESET_REQUEST,
		...arg,
	};
}

export function passwordResetSuccessAction(): PasswordResetSuccessAction {
	return {
		type: PASSWORD_RESET_SUCCESS,
	}
}

export function passwordResetErrorAction(errors: Errors): PasswordResetErrorAction {
	return {
		type: PASSWORD_RESET_ERROR,
		errors,
	}
}

export function passwordResetClearAction(): PasswordResetClearAction {
	return {
		type: PASSWORD_RESET_CLEAR,
	}
}

export function passwordResetCancelAction(): PasswordResetCancelAction {
	return {
		type: PASSWORD_RESET_CANCEL,
	}
}
