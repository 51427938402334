import AdminDialogues from './component';
import {connect} from 'react-redux';
import {RootState} from '../../../store/root-reducer';
import {
	areasRequestAction,
	conversationsRequestAction,
	conversationsClearAction,
	questionUpdateRequestAction,
	answerUpdateRequestAction,
	conversationCreateRequestAction,
	conversationDeleteRequestAction,
} from './actions';
import AdminDialoguesState from './state';

const mapStateToProps = (state: RootState): AdminDialoguesState => state.admin.dialogues;

const actions = {
	areasRequestAction,
	conversationsRequestAction,
	conversationsClearAction,
	questionUpdateRequestAction,
	answerUpdateRequestAction,
	conversationCreateRequestAction,
	conversationDeleteRequestAction,
};

export default connect(mapStateToProps, actions)(AdminDialogues);
