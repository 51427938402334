import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Avatar from './component';
import {RootState} from "../../../store/root-reducer";
import {
    avatarClearAction,
    avatarErrorAction,
    avatarRequestAction,
    avatarSetAction,
    avatarSuccessAction
} from "./actions";
import AvatarState from "./state";

const mapStateToProps = (state: RootState): AvatarState => state.game.avatar;
const actions = {
    avatarRequestAction,
    avatarSuccessAction,
    avatarErrorAction,
    avatarClearAction,
    avatarSetAction
}

export default withRouter(connect(mapStateToProps, actions)(Avatar));
