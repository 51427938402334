export const AREAS_REQUEST = 'AREAS_REQUEST';
export const AREAS_SUCCESS = 'AREAS_SUCCESS';
export const AREAS_ERROR = 'AREAS_ERROR';

export const CONVERSATIONS_REQUEST = 'CONVERSATIONS_REQUEST';
export const CONVERSATIONS_SUCCESS = 'CONVERSATIONS_SUCCESS';
export const CONVERSATIONS_ERROR = 'CONVERSATIONS_ERROR';
export const CONVERSATIONS_CLEAR = 'CONVERSATIONS_CLEAR';

export const QUESTION_UPDATE_REQUEST = 'QUESTION_UPDATE_REQUEST';
export const QUESTION_UPDATE_SUCCESS = 'QUESTION_UPDATE_SUCCESS';
export const QUESTION_UPDATE_ERROR = 'QUESTION_UPDATE_ERROR';

export const ANSWER_UPDATE_REQUEST = 'ANSWER_UPDATE_REQUEST';
export const ANSWER_UPDATE_SUCCESS = 'ANSWER_UPDATE_SUCCESS';
export const ANSWER_UPDATE_ERROR = 'ANSWER_UPDATE_ERROR';

export const CONVERSATION_CREATE_REQUEST = 'CONVERSATION_CREATE_REQUEST';
export const CONVERSATION_CREATE_SUCCESS = 'CONVERSATION_CREATE_SUCCESS';
export const CONVERSATION_CREATE_ERROR = 'CONVERSATION_CREATE_ERROR';

export const CONVERSATION_DELETE_REQUEST = 'CONVERSATION_DELETE_REQUEST';
export const CONVERSATION_DELETE_SUCCESS = 'CONVERSATION_DELETE_SUCCESS';
export const CONVERSATION_DELETE_ERROR = 'CONVERSATION_DELETE_ERROR';
