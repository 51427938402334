import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import Landing, {LandingState} from './component';
import {RootState} from '../../store/root-reducer';
import {logoutAction, loginRefreshAction} from "../auth/login/actions";

const mapStateToProps = (state: RootState): LandingState => ({
    authenticated: !!state.client.auth,
    user: state.client.user,
});
const actions = {
    logoutAction,
    loginRefreshAction,
};

export default withRouter(connect(mapStateToProps, actions)(Landing));
