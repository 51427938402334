import {AuthData} from '../service/auth';

export enum Roles {
	user="user",
	admin="admin",
}

export interface UserData {
	id: number,
	username: string,
	name: string,
	gender: string,
	age: number,
	email: string,
	picture: string | null,
	role: Roles,
	authentication: string,
	blocked: boolean,
	createdAt: string,
	updatedAt: string,
	deletedAt: string | null,
}

export default interface ClientState {
	user: UserData | null,
	auth: AuthData | null,
}
