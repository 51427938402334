import { all } from 'redux-saga/effects';

import AdminUsersSagas from './users/sagas';
import AdminDialoguesSagas from './dialogues/sagas';
import AdminStatisticsSagas from './statistics/sagas';
import AdminDownloadsSaga from './downloads/sagas';

export default function* AdminSaga() {
    yield all([AdminUsersSagas(), AdminDialoguesSagas(), AdminStatisticsSagas(), AdminDownloadsSaga()]);
}
