import React, {useEffect, useState} from 'react';

import {Link} from 'react-router-dom';
import {BrowserHistory} from 'history';
import SignupState from './state';
import {SignupRequestArguments} from './actions';
//import facebookLogo from '../../../assets/icons/facebook.svg';
import Error from '../../../service/errors';
import {actionOnEnter, focusOnEnter} from '../../../service/functions';
import {t} from 'ttag';
import inputWarning from '../../../assets/icons/input_warning.svg';

type SignupProps = SignupState & {
	signupRequestAction: (args: SignupRequestArguments) => void,
	facebookSignupAction: () => void,
	signupClearAction: () => void,
	history: BrowserHistory,
};

const Signup: React.FunctionComponent<SignupProps> = props => {
	const {
		signupRequestAction,
//		facebookSignupAction,
		signupClearAction,
		requesting,
		errors,
		history,
	} = props;

	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [age, setAge] = useState('');
	const [gender, setGender] = useState('');
	const [GDPRAgreed, setGDPRAgreed] = useState(false);
	const [GDPRError, setGDPRError] = useState(false)

	useEffect(() => signupClearAction, [signupClearAction]);

	const signupRequest = () => {
		const data = {
			name: name + ' ' + surname,
			surname: surname,
			username: username,
			email: email,
			password: password,
			passwordConfirm: passwordConfirm,
			age: parseInt(age),
			gender: gender,
			callback: () => {
				history.push('/login', {signup: true});
			},
		};

		signupRequestAction(data);
	};

	function isAge(value: string) {
		if (value === '') return true;
		if (value.length > 3) return false;
		return /^-?\d+$/.test(value);
	}

	if (requesting) {
		return (
			<p>Requesting</p>
		);
	}

	return (
		<div className='page-container flex flex-grow justify-center items-center'>
			<div className='main-container flex flex-col justify-center items-center pt-6 w-full max-w-full h-auto'>
				<div className='flex justify-center m-8 mt-10'>
					<Link to='/' className='text-link underline'>
                        {t`go to main page`}
					</Link>
				</div>
				<div className='form-container'>
					<div className='flex flex-grow flex-col justify-center m-4 mb-8'>
						<p className='input-header'>
                            {t`name`}<sup>*</sup>
						</p>
						<input onKeyPress={(e) => {focusOnEnter(e.key, 'input-surname');}}
							   className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'name' ? 'bg-warning' : 'bg-opacity-25'}`}
							   type="text"
							   value={name}
							   onChange={e => setName(e.target.value)}
							   required={true}
						/>
					</div>
					<div className='flex flex-grow flex-col justify-center m-4 mb-8'>
						<p className='input-header'>
                            {t`surname`}<sup>*</sup>
						</p>
						<input onKeyPress={(e) => {focusOnEnter(e.key, 'input-username');}}
							   className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'surname' ? 'bg-warning' : 'bg-opacity-25'}`}
							   id="input-surname"
							   type="text"
							   value={surname}
							   onChange={e => setSurname(e.target.value)}
							   required={true}/>
					</div>
					<div className='flex flex-grow flex-col justify-center m-4 mb-8'>
						<p className='input-header'>
                            {t`username`}<sup>*</sup>
						</p>
						<input onKeyPress={(e) => {focusOnEnter(e.key, 'input-age');}}
							   className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'username' ? 'bg-warning' : 'bg-opacity-25'}`}
							   id="input-username"
							   type="text"
							   value={username}
							   onChange={e => setUsername(e.target.value)}
							   required={true}/>
					</div>
					<div className='flex flex-row justify-between  m-4 mb-8'>
						<div className='gender-div flex flex-col justify-center'>
							<p className='input-header'>
                                {t`gender`}
							</p>
							<select className={`text-input-half ${gender === '' ? 'text-placeholder' : ''}`}
									id="input-gender"
									value={gender}
									onChange={e => setGender(e.target.value)}
									required={true}>
								<option value="" disabled={true} className='hidden'></option>
								<option value="female" className='text-black'>Female</option>
								<option value="male" className='text-black'>Male</option>
								<option value="other" className='text-black'>Other</option>
							</select>
						</div>
						<div className='age-div flex flex-col justify-center'>
							<p className='input-header'>
                                {t`age`}
							</p>
							<input onKeyPress={(e) => {focusOnEnter(e.key, 'input-email');}}
								   type='text' className='text-input-half'
								   id="input-age"
								   value={age}
								   onChange={e => isAge(e.target.value) ? setAge(e.target.value) : ''}
								   required={true}/>
						</div>
					</div>
					<div className='flex flex-grow flex-col justify-center m-4 mb-8'>
						<p className='input-header'>
                            {t`email`}<sup>*</sup>
						</p>
						<input onKeyPress={(e) => {focusOnEnter(e.key, 'input-password');}}
							   className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'email' ? 'bg-warning' : 'bg-opacity-25'}`}
							   id="input-email"
							   type="email"
							   value={email}
							   onChange={e => setEmail(e.target.value)}
							   required={true}/>
					</div>
					<div className='flex flex-grow flex-col justify-center m-4 mb-8'>
						<p className='input-header'>
                            {t`password`}<sup>*</sup>
						</p>
						<input onKeyPress={(e) => {focusOnEnter(e.key, 'input-passwordConfirm');}}
							   className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'password' ? 'bg-warning' : 'bg-opacity-25'}`}
							   id="input-password"
							   type="password"
							   value={password}
							   onChange={e => setPassword(e.target.value)}
							   required={true}/>
					</div>
					<div className='flex flex-grow flex-col justify-center m-4 mb-8'>
						<p className='input-header'>
                            {t`password confirm`}<sup>*</sup>
						</p>
						<input onKeyPress={(e) => {actionOnEnter(e.key, () => {signupRequest();});}}
							   className={`text-input ${errors.hasOwnProperty('password') ? 'bg-warning' : 'bg-opacity-25'}`}
							   id="input-passwordConfirm"
							   type="password"
							   value={passwordConfirm}
							   onChange={e => setPasswordConfirm(e.target.value)}
							   required={true}/>
					</div>
					<div className='flex flex-grow justify-center m-4'>
						<input type="checkbox" name="" id="" className='mr-4 w-8' onChange={(e) => setGDPRAgreed(e.target.checked)} required={true}/>
						<p>
							Prečítal/a som si{' '} 
							<Link 
							to='/privacy-policy' 
							target='_blank'
							className='underline text-link'>podmienky ochrany súkromia</Link> 
							{' '}a súhlasím s nimi<sup>*</sup>
						</p>
					</div>
					<Error {...errors} value='email'/>
					<Error {...errors} value='password'/>
					<Error {...errors} value='input'/>
					{GDPRError && (
						<div className="flex flex-grow justify-start mb-6">
							<span className='text-error'>
								<img src={inputWarning} alt="warning" className='input-warning'/>
								Prosím súhlas s podmienkami ochrany súkromia
							</span>
						</div>
					)}

					<div className='flex flex-col flex-grow flex-wrap justify-center items-center m-5 mt-10 mb-10'>
{/*
						<button className='fb-signup-button text-button' type="submit"
								onClick={() => facebookSignupAction()}>
							<div className='flex flex-row justify-center items-center w-full'>
								sign up with <img className='ml-2' src={facebookLogo} alt='facebook'/>
							</div>
						</button>
*/}
						<button className='signup-button text-button text-blue' type="submit"
								onClick={() => {
									if (!GDPRAgreed) {
										setGDPRError(true)
									} else {
										setGDPRError(false)
										signupRequest()
									}
								}}>
                            {t`sign up`}
						</button>
					</div>
					<div className='flex flex-grow justify-center mb-20'>
						<Link to='/login' className='text-link underline'>
                            {t`already registered`}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Signup;
