import {connect} from 'react-redux'

import Game from './component';
import {RootState} from '../../store/root-reducer';
import {loginRefreshAction} from "../auth/login/actions";

const mapStateToProps = (state: RootState): {authenticated: boolean} => ({
    authenticated: !!state.client.auth,
});

const actions = {
    loginRefreshAction
};

export default connect(mapStateToProps, actions)(Game);
