import { all } from 'redux-saga/effects';

import AreasSagas from './scenario/sagas';
import DialogSaga from './dialog/sagas';
import AvatarSaga from './avatar/sagas';
import DonwloadsSaga from '../downloads/sagas';

export default function* GameSaga() {
    yield all([AreasSagas(), DialogSaga(), AvatarSaga(), DonwloadsSaga()]);
}
