import {CLIENT_SET, CLIENT_UNSET} from './constants';
import ClientState from './state';

interface SetClientAction {
	type: typeof CLIENT_SET,
	data: ClientState,
}

interface UnsetClientAction {
	type: typeof CLIENT_UNSET,
}

export type ClientAction = SetClientAction | UnsetClientAction;

export function setClient(data: ClientState): ClientAction {
	return {
		type: CLIENT_SET,
		data,
	};
}

export function unsetClient(): ClientAction {
	return {
		type: CLIENT_UNSET,
	};
}

