import React, { useEffect } from 'react';
import { UserData } from '../../client/state';
import { LoginRefreshAction, LoginRefreshArguments } from '../auth/login/actions';
import { FilesSuccesArguments, FilesSuccessAction } from './actions';
import DownloadsState from './state';
import DownloadIcon from '../../assets/icons/download-icon.png';

type DownloadsProps = DownloadsState & {
    filesRequestAction: () => void;
    filesSuccessAction: (args: FilesSuccesArguments) => FilesSuccessAction;
    loginRefreshAction: (arg: LoginRefreshArguments) => LoginRefreshAction;
    authenticated: boolean;
    user: UserData | null;
    logoutAction: () => void;
};

const Downloads: React.FC<DownloadsProps> = (props) => {
    const { files, requesting, filesRequestAction, authenticated, logoutAction, user } = props;

    useEffect(() => {
        if (!files.data || !files || !files.data.length) {
            filesRequestAction();
        }
    }, [files, filesRequestAction]);
    return (
        <div className='landing-body m-0 p-0 min-h-screen'>
            <div className='landing-topnav'>
                <a href='/'>
                    <h1 className='h-full flex items-center justify-center'>HOPE</h1>
                </a>
                {!authenticated && (
                    <div className='landing-topnav-right'>
                        <a href='/login' className='landing-login-button'>
                            Login
                        </a>
                        <a href='/signup' className='landing-signup-button'>
                            Sign up
                        </a>
                    </div>
                )}
                {authenticated && (
                    <div className='landing-topnav-right'>
                        {user && user.name && (
                            <p className='landing-user-text'>
                                {user.name.length > 18 ? user.name.split(' ')[0] : user.name ?? ''}
                            </p>
                        )}
                        <span
                            onClick={() => {
                                logoutAction();
                            }}
                            className='landing-login-button'
                        >
                            Log out
                        </span>
                    </div>
                )}
            </div>
            <div className='py-10'>
                <div className='flex flex-col items-center'>
                    {files.data &&
                        files.data.map((file, index) => (
                            <div
                                key={`downloadable-${index}`}
                                className='text-blue text-center my-4 flex flex-col items-center'
                            >
                                <h2 className='mb-2'>{file.title}</h2>
                                <p className='text-xs mb-2 opacity-75 w-56 text-center break-all'>{file.description}</p>
                                <p className='text-sm mb-2'>Download here</p>
                                <a
                                    href={`/server/files/${file.internalName}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <div className='download-button flex items-center px-6 py-2 cursor-pointer lg:px-2'>
                                        <span className='text-xs text-white'>{file.name}</span>
                                        <img src={DownloadIcon} alt='download' className='ml-auto w-4' />
                                    </div>
                                </a>
                            </div>
                        ))}
                </div>
            </div>
            {requesting && (
                <div>
                    <span>Loading...</span>
                </div>
            )}
        </div>
    );
};

export default Downloads;
