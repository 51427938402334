import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import './components/locale/init';
import './assets/scss/index.scss';

import AppBody from './components/app-body';
import AppFooter from './components/app-footer';

export default () => (
	<Router>
		<div className="w-full h-auto min-h-screen flex flex-col items-stretch">
			<AppBody/>
                        <AppFooter/>
		</div>
	</Router>
);
