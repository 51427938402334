import {
    USERS_REQUEST,
    USERS_ERROR,
    USERS_SUCCESS,
    BLOCK_REQUEST,
    BLOCK_ERROR,
    BLOCK_SUCCESS,
} from './constants';

import Action from '../../../service/action';
import {Errors} from "../../../service/errors/state";
import {UsersPrivate} from '../../../service/api/user';

export interface UsersRequestArguments {
	page: number,
}

export interface UsersErrorArguments {
	errors: Errors,
}

export interface UsersSuccessArguments {
	users: UsersPrivate,
}

export interface BlockRequestArguments {
	id: number,
	page: number,
}

export interface BlockErrorArguments {
	errors: Errors,
}

export interface UsersRequestAction extends Action<typeof USERS_REQUEST>, UsersRequestArguments {}
export interface UsersSuccessAction extends Action<typeof USERS_SUCCESS>, UsersSuccessArguments {}
export interface UsersErrorAction extends Action<typeof USERS_ERROR>, UsersErrorArguments {}
export interface BlockRequestAction extends Action<typeof BLOCK_REQUEST>, BlockRequestArguments {}
export interface BlockSuccessAction extends Action<typeof BLOCK_SUCCESS> {}
export interface BlockErrorAction extends Action<typeof BLOCK_ERROR>, BlockErrorArguments {}

export type AdminUsersAction =
	UsersRequestAction |
	UsersSuccessAction |
	UsersErrorAction |
	BlockRequestAction |
	BlockSuccessAction |
	BlockErrorAction;

export function usersRequestAction(args: UsersRequestArguments): UsersRequestAction {
	return {
		type: USERS_REQUEST,
		...args
	};
}

export function usersSuccessAction(args: UsersSuccessArguments): UsersSuccessAction {
    return {
        type: USERS_SUCCESS,
        ...args
    };
}

export function usersErrorAction(errors: Errors): UsersErrorAction {
    return {
        type: USERS_ERROR,
        errors
    };
}

export function blockRequestAction(args: BlockRequestArguments): BlockRequestAction {
	return {
		type: BLOCK_REQUEST,
		...args
	};
}

export function blockSuccessAction(): BlockSuccessAction {
    return {
        type: BLOCK_SUCCESS,
    };
}

export function blockErrorAction(errors: Errors): BlockErrorAction {
    return {
        type: BLOCK_ERROR,
        errors
    };
}
