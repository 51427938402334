import {call, put, takeLatest} from 'redux-saga/effects';

import AreaApi from '../../../service/api/area';
import ClientStorage from '../../../client/storage';
import {t} from 'ttag';

import {
    AreaHistoryPostAction,
    areasErrorAction,
    areasSuccessAction,
} from './actions';
import {AREA_HISTORY_POST, AREAS_REQUEST} from './constants';
import {safe} from '../../../service/functions';


function* areasRequest() {
    let client = yield call(ClientStorage.getItem);

    let areas = yield call(AreaApi.get, client.auth);

    yield put(areasSuccessAction({areas}));
}

function* areaHistoryRequest(action: AreaHistoryPostAction) {
    let client = yield call(ClientStorage.getItem);

    let history = yield call(AreaApi.createAreaHistory, client.auth, action.area, action.data);

    if (!history.hasOwnProperty('data')) {
        console.log('Error posting area history');
    }
}

function* onError() {
    yield put(areasErrorAction({input: t`Areas error.` + ' - ' + t`server not responding`}));
}


export default function* AreasSaga() {
    yield takeLatest(AREAS_REQUEST, safe([onError], areasRequest));
    yield takeLatest(AREA_HISTORY_POST, safe([onError], areaHistoryRequest));
}
