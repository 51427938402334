import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import ReduxStore from './store';

import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';


const setup = () => {
    if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment: process.env.REACT_APP_SENTRY_ENV ?? 'development',
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 0.2,
        });
    }
};

const render = () => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={ReduxStore}>
                <App/>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root'),
    );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
setup();
render();
ReduxStore.subscribe(render);
serviceWorker.unregister();

