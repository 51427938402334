import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ScorePage from './component';

import {RootState} from "../../../store/root-reducer";
import ScoreState from "./state";
import {AreaData, ConversationData} from "../../../service/api/area";
import {AnswerData} from "../../../service/api/conversation";

const mapStateToProps = (state: RootState): ScoreState &
    {
        area: AreaData | null,
        conversations: ConversationData | null,
        lastAnswer: AnswerData | null,
    } => {
    return {
        ...state.game.scorepage,
        area: state.game.scenario.area,
        conversations: state.game.dialog.conversations,
        lastAnswer: state.game.dialog.lastAnswer,
    }
};

// @ts-ignore
export default withRouter(connect(mapStateToProps, null)(ScorePage));
