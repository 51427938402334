import React, {useState, useEffect,} from 'react';

import {gettext, t} from 'ttag';
import AreasState from "./state";
import {AreaSetArguments} from "./actions";
import {AreaData} from "../../../service/api/area";
import {BrowserHistory} from "history";
import {currentQuestionSetAction, CurrentQuestionSetArguments} from "../dialog/actions";
import {StartTimeSetArguments} from "../scorepage/actions";
import click1music from "../../../assets/music/scenar_klik_1.wav";
import MusicToggler from '../../music-toggler';

export type ScenarioProps = AreasState & {
	areasRequestAction: () => void,
	areaSetAction: (args: AreaSetArguments) => void,
	areaClearAction: () => void,
	currentQuestionSetAction: (args: CurrentQuestionSetArguments) => void,
	startTimeSetAction: (args: StartTimeSetArguments) => void,
	dialogClearAction: () => void,
	scoreResetAction: () => void,
	history: BrowserHistory,
}

const Scenario: React.FunctionComponent<ScenarioProps> = props => {
	const {
		areas,
		areaSetAction,
		areaClearAction,
		areasRequestAction,
		startTimeSetAction,
		dialogClearAction,
		scoreResetAction,
		history
	} = props;

	const [scenarioChosen, setScenarioChosen] = useState("");
	const [areaChosen, setAreaChosen] = useState<AreaData | null>(null);
	const [enemyChosen, setEnemyChosen] = useState("")
	const [temporaryScenario, setTemporaryScenario] = useState<AreaData | null>(null)
	const [soundsMuted, setSoundsMuted] = useState(false);

	const enemies = {
		"troll": "Trolovia",
		"stalker": "Stalkeri",
		"fanatik": "Fanatici",
		"hater": "Hateri",
	}

	const getEnemy = (val: string) => {
        return enemies.hasOwnProperty(val) ? (enemies as any)[val] : null;
    }

	const stalkerDescription =`
		Stalker je čudák. Niekedy iba sleduje a obťažuje druhých. Inokedy sa snaží z ľudí vytiahnuť 
		informácie, ktoré zneužíva na dosiahnutie svojich cieľov. Predáva ich na čiernom trhu alebo vďaka 
		nim kradne profily na sociálnych sieťach. Niekedy to zájde až tak ďaleko, že správami vydiera tých, 
		ktorí nespolupracujú.
	`;

	const trollDescription = `
		Troll bol kedysi normálny človek. Teda stále je - len ho ovládli zlé pocity. Prežíva strach, 
		pretože prestal vidieť svoju hodnotu. Sám o sebe pochybuje. Týmto pocitom však nechce čeliť a 
		namiesto toho sa snaží na svoju úroveň stiahnuť ostatných. Snaží sa provokovať, niekedy veľmi 
		premyslene, inokedy iba nadáva a vyskakuje.
	`;

	const haterDescription = `
		Hater by si mohol s Trollom ruky podať, sú si totiž veľmi podobní. Hater ale nenávidí všetkých 
		a všetko - on si ruku s nikým nepodá. Jeho útoky sú ostré, plné hnevu a nadávok. A čo je horšie, 
		nemajú nič poločné so zdravým rozumom. Nie je schopný zamyslieť sa sám za sebou a uvedomiť si, že 
		ubližuje druhým.
	`;

	const fanatikDescription = `
		Fanatik si dal za cieľ zastaviť pokrok v meste. Chce vrátiť veci do starých koľají. No zápal pre 
		vec ho zmenil na nepoznanie. Nemá v sebe štipku pochopenia pre druhých. Vidí pravdu len v svojich 
		názoroch a spôsoboch. Dávno zišiel z cesty, ktorú si kedysi stanovil. Dáva si záležať na tom, aby 
		bolo počuť iba jeho. Diskusiu nepozná.
	`;

	const scenarioTexts = {
		"troll": trollDescription,
		"stalker": stalkerDescription,
		"fanatik": fanatikDescription,
		"hater": haterDescription,
	}

	const getText = (val: string) => {
		return scenarioTexts.hasOwnProperty(val) ? (scenarioTexts as any)[val] : null;
	}

	const triggerAnimation = (index: number) => {
		const indices = ['area-1', 'area-2', 'area-3', 'area-4'].filter(area => area !== `area-${index}`);
		for (let _index of indices) {
			document.querySelector(`.${_index}`)?.classList.add('area-disappear');
		}
		const music = new Audio(click1music)
		if (!soundsMuted) music.play()
	}


	useEffect(()=>{
		if(!areas || !areas.data || !areas.data.length) {
			areasRequestAction();
			areaClearAction();
		}
		setSoundsMuted((prev) => {
            if (!!window.localStorage.getItem('MUSIC_DATA')) {
                const isMuted: boolean = JSON.parse(window.localStorage.getItem('MUSIC_DATA')!)['MUSIC_MUTED']
                return isMuted;
            } else {
                return false;
            }
        })
	}, [areasRequestAction, areas, areaClearAction])

	return (
		<div className='page-container scenario flex flex-grow justify-center items-center'>
			<div className='main-container flex flex-col items-center w-full max-w-full h-auto' onAnimationEnd={(e) => {
				if (e.animationName === 'area-dis') {
					setScenarioChosen(temporaryScenario!.name);
					setAreaChosen(temporaryScenario!);
					setEnemyChosen(temporaryScenario!.name)
				}
			}}>
				<div className='text-link underline mt-16 cursor-pointer'
                     onClick={() => {
                         if(scenarioChosen) {
							 setScenarioChosen("");
							 setEnemyChosen("");
						 } else {
							 history.push('/avatar');
						 }
                     }}
                >
					{'< '} {t`back`}
				</div>

				<div className='mt-8'>
					{t`choose scenario`}
				</div>

				{!scenarioChosen && (
					<div className='scenarios-container flex flex-wrap justify-evenly'>
						{areas.data && areas.data.map((value, index) => (
							<div key={`scenario-${index}`} className={`scenario-element flex flex-col justify-center area-${index+1}`}>
								<div className={`building-image ${value.name}-building`}
									 onClick={() => {
										 triggerAnimation(index+1);
										 setTemporaryScenario(value);
									}}
								/>

								<div className='scenario-info flex justify-center items-center'
									 onClick={() => {setScenarioChosen(value.name); setAreaChosen(value); setEnemyChosen(value.name)}}
								>
									<span>{gettext(getEnemy(value.name))}</span>
								</div>
							</div>
						))}
					</div>
				)}

				{scenarioChosen && (
					<div className='scenarios-container flex flex-wrap justify-evenly mt-4 scenario-appear'>
						<div className='scenario-element flex flex-col justify-center'>
							<div className={`building-image ${scenarioChosen}-building-chosen` + (scenarioChosen === 'hater' ? ' mb-4' : '')}/>
							<div className='scenario-info flex justify-center items-center'>
								{getEnemy(enemyChosen)}
							</div>
						</div>
						<div className='scenario-info-long p-6 flex items-center text-blue'>
							{getText(scenarioChosen)}
						</div>
						<div className="select-div">
							<button onClick={() => {
								if(areaChosen) {
									areaSetAction({area: areaChosen});
									currentQuestionSetAction({currentQuestion: 0});
									dialogClearAction();
									scoreResetAction();
									startTimeSetAction({start_time: (new Date()).getTime()});
									history.push('/dialog');
								}
								}} className='choose-scenario-button text-button text-blue mt-4'>
								{t`choose`}
							</button>
						</div>
					</div>
				)}
				<MusicToggler handleChange={() => setSoundsMuted(prev => !prev)} />
			</div>
		</div>
	);
};

export default Scenario;
