import {
    RESET_TOKEN_REQUEST,
    RESET_TOKEN_SUCCESS,
    RESET_TOKEN_CLEAR,
    RESET_TOKEN_ERROR,
} from './constants';
import Action from '../../../service/action';
import {Errors} from '../../../service/errors/state';
import {ResetTokenCredentials} from "../../../service/api/auth";

export interface ResetTokenRequestArguments extends ResetTokenCredentials {
    callback?: () => void,
}
export interface ResetTokenErrorArguments {
    errors: Errors,
}

export interface ResetTokenRequestAction extends Action<typeof RESET_TOKEN_REQUEST>, ResetTokenRequestArguments {}
export interface ResetTokenSuccessAction extends Action<typeof RESET_TOKEN_SUCCESS> {}
export interface ResetTokenErrorAction extends Action<typeof RESET_TOKEN_ERROR>, ResetTokenErrorArguments {}
export interface ResetTokenClearAction extends Action<typeof RESET_TOKEN_CLEAR> {}

export type ResetTokenAction =
    ResetTokenRequestAction |
    ResetTokenSuccessAction |
    ResetTokenErrorAction |
    ResetTokenClearAction;

export function resetTokenRequestAction(arg: ResetTokenRequestArguments): ResetTokenRequestAction {
    return {
        type: RESET_TOKEN_REQUEST,
        ...arg,
    };
}

export function resetTokenSuccessAction(): ResetTokenSuccessAction {
    return {
        type: RESET_TOKEN_SUCCESS,
    }
}

export function resetTokenErrorAction(errors: Errors): ResetTokenErrorAction {
    return {
        type: RESET_TOKEN_ERROR,
        errors,
    }
}

export function resetTokenClearAction(): ResetTokenClearAction {
    return {
        type: RESET_TOKEN_CLEAR,
    }
}
