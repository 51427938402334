import {combineReducers} from 'redux';

import scenario from './scenario/reducers';
import avatar from './avatar/reducers';
import dialog from './dialog/reducers';
import scorepage from './scorepage/reducers';

const GameReducer = combineReducers({
    scenario,
    avatar,
    dialog,
    scorepage,
});

export type GameState = ReturnType<typeof GameReducer>;
export default GameReducer;
