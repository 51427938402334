import { call, put, takeLatest } from '@redux-saga/core/effects';
import ClientStorage from '../../../client/storage';
import FilesApi from '../../../service/api/files';
import { safe } from '../../../service/functions';
import { FileCreateRequestAction, FileDeleteRequestAction, filesErrorsAction, filesSuccessAction, FileUpdateRequestAction } from './actions';
import { DOWNLOADS_REQUEST, FILE_CREATE_REQUEST, FILE_DELETE_REQUEST, FILE_UPDATE_REQUEST } from './constants';

function* filesRequest() {
    let files = yield call(FilesApi.get);

    yield put(filesSuccessAction({ files }));
}

function* fileDeleteRequest(action: FileDeleteRequestAction) {
    let client = yield call(ClientStorage.getItem);

    yield call(FilesApi.delete, client.auth, action.internalName);

    let files = yield call(FilesApi.get);
    yield put(filesSuccessAction({ files }));
}

function* fileUpdateRequest(action: FileUpdateRequestAction) {
    let client = yield call(ClientStorage.getItem);

    yield call(FilesApi.put, client.auth, action.internalName, {
        description: action.description,
        title: action.title,
    });

    let files = yield call(FilesApi.get);
    yield put(filesSuccessAction({ files }));
}

function* fileCreateRequest(action: FileCreateRequestAction) {
    let client = yield call(ClientStorage.getItem);

    let {internalName} = yield call(FilesApi.post, client.auth, action.file);
    yield call(FilesApi.put, client.auth, internalName, {
        title: action.title,
        description: action.description,
    });
    let files = yield call(FilesApi.get);
    yield put(filesSuccessAction({ files }));
}

function* onError() {
    yield put(filesErrorsAction({ files: 'Server not responding' }));
}

export default function* AdminDownloadsSaga() {
    yield takeLatest(FILE_DELETE_REQUEST, safe([onError], fileDeleteRequest));
    yield takeLatest(DOWNLOADS_REQUEST, safe([onError], filesRequest));
    yield takeLatest(FILE_UPDATE_REQUEST, safe([onError], fileUpdateRequest));
    yield takeLatest(FILE_CREATE_REQUEST, safe([onError], fileCreateRequest));
}
