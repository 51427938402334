import {
    DIALOG_REQUEST,
    DIALOG_SUCCESS,
    DIALOG_CLEAR,
    DIALOG_ERROR,
    CURRENT_QUESTION_SET,
    LAST_ANSWER_SET,
    LIVES_SET,
} from './constants';
import Action from '../../../service/action';
import {Errors} from "../../../service/errors/state";
import {AreaData, ConversationData} from "../../../service/api/area";
import {AnswerData} from "../../../service/api/conversation";


export interface DialogErrorArguments {
    errors: Errors,
}

export interface DialogSuccessArguments {
    conversations: ConversationData,
}

export interface DialogRequestArguments {
    area: AreaData,
}

export interface CurrentQuestionSetArguments {
    currentQuestion: number,
}

export interface LastAnswerSetArguments {
    lastAnswer: AnswerData,
}

export interface LivesSetArguments {
    lives: number,
}

export interface CurrentQuestionSetAction extends Action<typeof CURRENT_QUESTION_SET>, CurrentQuestionSetArguments {}
export interface LastAnswerSetAction extends Action<typeof LAST_ANSWER_SET>, LastAnswerSetArguments {}
export interface LivesSetAction extends Action<typeof LIVES_SET>, LivesSetArguments {}
export interface DialogRequestAction extends Action<typeof DIALOG_REQUEST>, DialogRequestArguments {}
export interface DialogSuccessAction extends Action<typeof DIALOG_SUCCESS>, DialogSuccessArguments {}
export interface DialogClearAction extends Action<typeof DIALOG_CLEAR> {}
export interface DialogErrorAction extends Action<typeof DIALOG_ERROR>, DialogErrorArguments {}

export type DialogAction =
    DialogRequestAction |
    DialogSuccessAction |
    DialogClearAction |
    DialogErrorAction |
    CurrentQuestionSetAction |
    LastAnswerSetAction |
    LivesSetAction;

export function dialogRequestAction(args: DialogRequestArguments): DialogRequestAction {
    return {
        type: DIALOG_REQUEST,
        ...args
    };
}

export function dialogSuccessAction(args: DialogSuccessArguments): DialogSuccessAction {
    return {
        type: DIALOG_SUCCESS,
        ...args
    };
}

export function dialogErrorAction(errors: Errors): DialogErrorAction {
    return {
        type: DIALOG_ERROR,
        errors
    };
}

export function dialogClearAction(): DialogClearAction {
    return {
        type: DIALOG_CLEAR
    };
}

export function currentQuestionSetAction(args: CurrentQuestionSetArguments): CurrentQuestionSetAction {
    return {
        type: CURRENT_QUESTION_SET,
        ...args
    };
}

export function lastAnswerSetAction(args: LastAnswerSetArguments): LastAnswerSetAction {
    return {
        type: LAST_ANSWER_SET,
        ...args
    };
}

export function livesSetAction(args: LivesSetArguments): LivesSetAction {
    return {
        type: LIVES_SET,
        ...args
    };
}
