import React, {useEffect} from 'react';

import QRState from './state';
import {QRRequestArguments} from './actions';
import {BrowserHistory} from "history";

type QRProps = QRState & {
    qrRequestAction: (args: QRRequestArguments) => void,
    history: BrowserHistory,
};

const QR: React.FunctionComponent<QRProps> = props => {
    const {
        qrRequestAction,
        requesting,
        errors,
        history,
    } = props;

    useEffect(() => {
        if(!requesting) {
            qrRequestAction({callback: () => {
                history.push('/');
            }});
        }
        if(errors) {
            history.push('/');
        }

    }, [history, qrRequestAction, requesting, errors]);


    return (
        <div className='h-screen w-full'>
        </div>
    );
};

export default QR;
