const HttpResponseCode = {
	OK: 200,
	Created: 201,
	Accepted: 202,
	NoContent: 204,
	Unauthorized: 401,
	ServerError: 500,
};

export default HttpResponseCode;
