import { combineReducers } from 'redux';

import users from './users/reducers';
import dialogues from './dialogues/reducers';
import statistics from './statistics/reducers';
import downloads from './downloads/reducers';

const AdminReducer = combineReducers({
    users,
    dialogues,
    statistics,
    downloads,
});

export type AdminState = ReturnType<typeof AdminReducer>;
export default AdminReducer;
