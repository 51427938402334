import React from 'react';

import AppBodyRoutes from './routes';
import {renderRedirect, renderRouter} from '../router';
import {RouteComponentProps} from 'react-router';

const AppBody: React.FunctionComponent<RouteComponentProps> = ({location}) => {

	AppBodyRoutes.every((route) => {
		if(route.title && route.pathname === location.pathname) {
			document.title = (AppBodyRoutes[0].title === route.title) ? route.title : document.title + ' - ' + route.title;
			return false;
		} else {
			document.title = AppBodyRoutes[0].title ?? "HOPE";
			return true;
		}
	});

	return (
		AppBodyRoutes.findIndex(route => route.pathname === location.pathname) === -1 ?
		renderRedirect('/') :
		renderRouter('appBody', AppBodyRoutes)
	);
};

export default AppBody;
