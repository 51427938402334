import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';

import {setClient, unsetClient} from '../../../client/actions';
import ClientStorage from '../../../client/storage';
import AuthApi, {FacebookLoginData, LoginFacebookCredentials} from '../../../service/api/auth';
import AccountApi from '../../../service/api/account';
import EventApi from '../../../service/api/event';
import Auth from '../../../service/auth';
import {t, gettext} from 'ttag';

import {
    ClientUserUpdateAction, facebookLoginAction, FacebookLoginRequestAction,
    loginErrorAction, loginRefreshAction,
    loginSuccessAction, logoutAction,
    LoginRefreshAction,
    LoginRequestAction,
} from './actions';
import {CLIENT_USER_UPDATE, FACEBOOK_LOGIN_REQUEST, LOGIN_REFRESH, LOGIN_REQUEST, LOGOUT} from './constants';
import ClientState from '../../../client/state';
import {safe} from '../../../service/functions';

declare global {
    interface Window {
        FB: any,
        fbLoaded: any,
    }
}


export function* loginRefresh(action?: LoginRefreshAction) {
    let client = yield call(ClientStorage.getItem);

    let active = false;
    if (client && client.user && client.auth) {
        active = true;
    }
    let facebookLogin = false;

    if (window.FB) {
        let response = yield new Promise((resolve) => {
            window.FB.getLoginStatus((response: FacebookLoginData) => {
                resolve(response);
            });
        });
        if (response && response.authResponse !== null && response.status !== 'unknown') {
            active = true;
            facebookLogin = true;
        }
    } else {
        if (!active) {
            yield window.fbLoaded.promise.then(() => {
            });
            yield put(loginRefreshAction(action ?? {}));
            return;
        }
    }

    if (!active) {
        yield call(logout);
        return;
    } else {
        if (facebookLogin) {
            yield put(setClient(client));
        }
    }

    if (Auth.isExpired(client.auth)) {
        client.auth = yield call(AuthApi.refresh, client.auth);
        if (client.auth.status) {
            yield call(logout);
            return;
        }
    }

    client.user = yield call(AccountApi.get, client.auth);
    if (!client.user.hasOwnProperty('id')) {
        yield call(logout);
        return;
    }

    yield put(setClient(client));
    yield call(ClientStorage.setItem, client);

    if (action && action.callback)
        yield call(action.callback);
}


function* logout() {
    if (window.FB) {
        yield new Promise((resolve) => {
            window.FB.getLoginStatus((response: FacebookLoginData) => {
                if (response.authResponse !== null && response.status !== 'unknown') {
                    window.FB.logout((response: any) => {
                        resolve(response);
                    });
                } else {
                    resolve(false);
                }
            });
        });
    } else {
        yield window.fbLoaded.promise.then(() => {
        });
        yield put(logoutAction());
        return;
    }

    yield call(ClientStorage.removeItem);
    yield put(unsetClient());
}


function* handleLogin(auth: any) {
    if (!auth.accessToken) {
        yield call(logout);
        yield put(loginErrorAction({input: gettext(auth.message.replace('Request failed: ', '').replaceAll('"', ''))}));
        return;
    }

    let authInfo = Auth.info(auth);

    let user = yield call(AccountApi.get, auth);
    if (!user.hasOwnProperty('id')) {
        yield call(logout);
        yield put(loginErrorAction({input: t`Invalid credentials.`}));
        return;
    }

    auth.expiry = authInfo.exp;
    user.roles = authInfo.roles;

    yield call(handleLoginSuccess, {user, auth});

    return {user, auth} as ClientState;
}


function* login(action: LoginRequestAction) {
    for (let [key, value] of Object.entries(action)) {
        if (!value) {
            yield put(loginErrorAction({input: t`Please fill ` + key + `.`, type: key}));
            return;
        }
    }

    let auth = yield call(AuthApi.login, action);

    const clientState = yield handleLogin(auth);

    yield call(EventApi.registerEvent, 'login', JSON.stringify({timestamp: Date.now()}));

    if (action.callback) {
        yield call(action.callback, clientState);
    }
}


function* loginFacebook(action: FacebookLoginRequestAction) {
    let response = null;
    if (window.FB) {
        response = yield new Promise((resolve) => {
            window.FB.login((response: FacebookLoginData) => {
                resolve(response);
            }, {
                scope: 'email',
                return_scopes: true,
            });
        });
    } else {
        yield window.fbLoaded.promise.then(() => {
        });
        yield put(facebookLoginAction());
        return;
    }

    if (!response || !response.authResponse || !response.authResponse.accessToken || response.status === 'unknown') {
        yield call(logout);
        yield put(loginErrorAction({input: t`Facebook login failed on the facebook's side.`}));
        return;
    }

    let token = {accessToken: response.authResponse.accessToken} as LoginFacebookCredentials;
    let auth = yield call(AuthApi.loginFacebook, token);

    yield handleLogin(auth);

    yield call(EventApi.registerEvent, 'login_facebook', JSON.stringify({timestamp: Date.now()}));
}


export function* updateClientUser({data}: ClientUserUpdateAction) {
    const client = yield call(ClientStorage.getItem);
    if (!client) {
        yield call(logout);
        return;
    }

    client.user = {
        ...client.user,
        ...data,
    };

    yield put(setClient(client));
    yield call(ClientStorage.setItem, client);
}


function* handleLoginSuccess(client: ClientState, callback?: (args: ClientState) => void) {
    yield put(setClient(client));
    yield call(ClientStorage.setItem, client);
    yield put(loginSuccessAction());

    if (callback)
        yield call(callback, client);
}


function* onError() {
    yield put(loginErrorAction({input: t`Login error.` + ' - ' + t`server not responding`}));
}

export default function* LoginSaga() {
    yield call(loginRefresh);
    yield takeEvery(LOGOUT, logout);

    yield takeLatest(LOGIN_REFRESH, safe([logout, onError], loginRefresh));
    yield takeLatest(LOGIN_REQUEST, safe([logout, onError], login));
    yield takeLatest(FACEBOOK_LOGIN_REQUEST, safe([logout, onError], loginFacebook));

    yield takeLatest(CLIENT_USER_UPDATE, updateClientUser);
}
