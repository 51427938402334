import React, {useEffect} from 'react';
import {renderRedirect, renderRouter} from '../router';
import AdminRoutes from './routes';

import AdminHeader from './header';
import {Roles, UserData} from "../../client/state";
import {LoginRefreshArguments} from "../auth/login/actions";

export type AdminProps = {
    authenticated: boolean,
    user: UserData | null,
    loginRefreshAction: (args: LoginRefreshArguments) => void,
};

const Admin: React.FunctionComponent<AdminProps> = props => {
    const {
        user,
        authenticated,
        loginRefreshAction
    } = props;

    useEffect(() => {
        loginRefreshAction({callback: () => {}});
    }, [authenticated, loginRefreshAction])

    return (
        <div className="flex flex-col justify-center items-center text-blue w-full">
            {user && user.role === Roles.admin && (
                <AdminHeader/>
            )}
            {(user && user.role === Roles.admin) ?
                renderRouter('admin', AdminRoutes) :
                renderRedirect('/')}
        </div>
    )
};

export default Admin;
