import {
    RESET_TOKEN_REQUEST,
    RESET_TOKEN_SUCCESS,
    RESET_TOKEN_CLEAR,
    RESET_TOKEN_ERROR
} from './constants';
import ResetTokenState from './state';
import {ResetTokenAction} from './actions';

const InitialState: ResetTokenState = {
    requesting: false,
    success: false,
    errors: {},
};

export default function (state = InitialState, action: ResetTokenAction): ResetTokenState {
    switch (action.type) {
        case RESET_TOKEN_REQUEST:
            return {
                requesting: true,
                success: false,
                errors: state.errors,
            };

        case RESET_TOKEN_SUCCESS:
            return {
                requesting: false,
                success: true,
                errors: {},
            };

        case RESET_TOKEN_ERROR:
            return {
                requesting: false,
                success: false,
                errors: action.errors,
            };

        case RESET_TOKEN_CLEAR:
            return InitialState;

        default:
            return state;
    }
}
