import React, {useState, useEffect} from 'react';
import {arrayStatistics} from '../../../service/functions';
import {LoginsRequestArguments, QrcodesRequestArguments, RegistersRequestArguments} from './actions';
/* import AdminUser from '../user'; */
import AdminStatisticsState from './state';

export type AdminStatisticsProps = AdminStatisticsState & {
	loginsRequestAction: (args: LoginsRequestArguments) => void,
	registersRequestAction: (args: RegistersRequestArguments) => void,
	qrcodesRequestAction: (args: QrcodesRequestArguments) => void,
}

const AdminStatistics: React.FunctionComponent<AdminStatisticsProps> = props => {
	const {
		logins,
		loginsRequestAction,
		requestingLogins,
		registers,
		registersRequestAction,
		requestingRegisters,
		qrcodes,
		qrcodesRequestAction,
		requestingQrcodes,
	} = props;

	const [page, setPage] = useState(0);
	const [qrStats, setQrStats] = useState<Array<Array<any>>>([]);

	const [loginsInitialized, setLoginsInitialized] = useState(false);
	const [registersInitialized, setRegistersInitialized] = useState(false);
	const [qrcodesInitialized, setQrcodesInitialized] = useState(false);

	useEffect(() => {
		if (!loginsInitialized) {
			loginsRequestAction({page: 0});
			setLoginsInitialized(true);
		}
	}, [loginsRequestAction, loginsInitialized]);

	useEffect(() => {
		if (!registersInitialized) {
			registersRequestAction({page: 0});
			setRegistersInitialized(true);
		}
	}, [registersRequestAction, registersInitialized]);

	useEffect(() => {
		if (!qrcodesInitialized) {
				qrcodesRequestAction({page: 0});
				setQrcodesInitialized(true);
		} else {
			const stats = arrayStatistics(qrcodes.data, i => JSON.parse(i.data)?.browser);
			setQrStats(Object.entries(stats).sort((a, b) => b[1] - a[1]));
		}
	}, [qrcodesRequestAction, qrcodes, qrcodesInitialized]);

	const changePage = (p: number) => {
		if (p < 0)
			return;

		loginsRequestAction({page: p});
		registersRequestAction({page: p});
		qrcodesRequestAction({page: p});
		setPage(p);
	};

	const calcMonth = (offset: number) => {
		let date = new Date();
		date.setMonth(date.getMonth() - offset);
		return `${date.getMonth() + 1}/${date.getFullYear()}`;
	};

	return (
		<div className="flex flex-col justify-center items-center my-2 px-4 w-full max-w-screen-lg">
			{/*<div className="flex flex-row justify-between w-full">
				<p>Show by month</p>
				<p>2 Nov, 2020</p>
			</div>*/}
			<div className="flex flex-row justify-center mt-4">
				<button onClick={() => changePage(page + 1)}>&lt;-</button>
				<p className="mx-2">{calcMonth(page)}</p>
				<button onClick={() => changePage(page - 1)}>-&gt;</button>
			</div>
			<div className="flex lg:flex-row flex-col justify-around items-center mt-2">
				<div className="flex flex-row">
					<div className="flex flex-col justify-center items-center mx-6 w-32 h-32 bg-success text-white">
						<p>logins</p>
						{!requestingLogins ? (<p>+{logins.data.length}</p>) : (<p>loading...</p>)}
					</div>
					<div className="flex flex-col justify-center items-center mx-6 w-32 h-32 bg-warning text-white">
						<p>registrations</p>
						{!requestingRegisters ? (<p>+{registers.data.length}</p>) : (<p>loading...</p>)}
					</div>
				</div>
				<div className="flex flex-col justify-center items-center mt-8 lg:mt-0 mx-6 w-32 h-32 bg-filled text-white">
					<p>qr codes</p>
					{!requestingQrcodes ? (<p>+{qrcodes.data.length}</p>) : (<p>loading...</p>)}
				</div>
			</div>
			<div className="flex flex-col-reverse lg:flex-row self-center max-w-screen-lg mt-6 w-full">
				<div className="mt-4 lg:mt-0 w-full">

				</div>
				<div className="bg-filled text-white px-2 py-1 w-full lg:max-w-screen-lg">
					{!requestingQrcodes ?
						qrStats.length !== 0 ? (
							<table className="w-full">
								<tbody>
									{qrStats.map((browser, index) => (
										<tr key={index}>
											<td>{browser[0]}</td>
											<td className="text-center">{browser[1]}</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<p>no data...</p>
						)
					: (
						<p>loading...</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default AdminStatistics;
