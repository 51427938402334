import { combineReducers } from 'redux';

import client from '../client/reducers';
import auth from '../components/auth/reducers';
import game from '../components/game/reducers';
import admin from '../components/admin/reducers';
import qr from '../components/qr/reducers';
import downloads from '../components/downloads/reducers';

const RootReducer = combineReducers({
    client,
    auth,
    game,
    admin,
    qr,
    downloads,
});

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;
