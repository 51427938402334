import {END_TIME_SET, GAME_STATE_SET, SCORE_RESET, SCORE_SET, START_TIME_SET,} from './constants';
import Action from '../../../service/action';
import {GameState} from "./state";

export interface ScoreSetArguments {
    score: number,
}

export interface StartTimeSetArguments {
    start_time: number,
}

export interface EndTimeSetArguments {
    end_time: number,
}

export interface GameStateSetArguments {
    game_state: GameState,
}


export interface ScoreSetAction extends Action<typeof SCORE_SET>, ScoreSetArguments {}
export interface StartTimeSetAction extends Action<typeof START_TIME_SET>, StartTimeSetArguments {}
export interface EndTimeSetAction extends Action<typeof END_TIME_SET>, EndTimeSetArguments {}
export interface GameStateSetAction extends Action<typeof GAME_STATE_SET>, GameStateSetArguments {}
export interface ScoreResetAction extends Action<typeof  SCORE_RESET> {}

export type ScoreAction =
    ScoreSetAction |
    StartTimeSetAction |
    EndTimeSetAction |
    GameStateSetAction |
    ScoreResetAction;


export function scoreSetAction(args: ScoreSetArguments): ScoreSetAction {
    return {
        type: SCORE_SET,
        ...args
    };
}

export function startTimeSetAction(args: StartTimeSetArguments): StartTimeSetAction {
    return {
        type: START_TIME_SET,
        ...args
    };
}

export function endTimeSetAction(args: EndTimeSetArguments): EndTimeSetAction {
    return {
        type: END_TIME_SET,
        ...args
    };
}

export function gameStateSetAction(args: GameStateSetArguments): GameStateSetAction {
    return {
        type: GAME_STATE_SET,
        ...args
    };
}

export function scoreResetAction(): ScoreResetAction {
    return {
        type: SCORE_RESET,
    };
}

