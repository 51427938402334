import Avatar from './avatar';
import Scenario from "./scenario";
import ScorePage from './scorepage';
import Dialog from "./dialog";

const GameRoutes = [
    {
        pathname: '/avatar',
        exact: true,
        component: Avatar,
    },
    {
        pathname: '/scenario',
        exact: true,
        component: Scenario,
    },
    {
        pathname: '/scorepage',
        exact: true,
        component: ScorePage,
    },
    {
        pathname: '/dialog',
        exact: true,
        component: Dialog,
    }
];

export default GameRoutes;
