import Storage from '../service/storage';
import ClientState from './state';

export default class ClientStorage {
	static ClientDataStorageKey: string = 'CLIENT_DATA';

	/**
	 * Returns the client object from local storage
	 */
	static getItem(): ClientState {
		return Storage.getItem(ClientStorage.ClientDataStorageKey);
	}

	/**
	 * Sets the local storage client entry
	 */
	static setItem(value: ClientState) {
		return Storage.setItem(ClientStorage.ClientDataStorageKey, value);
	}

	/**
	 * Removes the client local storage entry
	 */
	static removeItem() {
		window.localStorage.removeItem(ClientStorage.ClientDataStorageKey);
	}
}
