import React from "react";

const PrivacyPolicy: React.FunctionComponent<{}> = () => {
  return (
      <div className='flex justify-center'>
        <div className="privacy-policy px-5 w-5/6">
        <h1>Podmienky ochrany súkromia (Privacy Policy)</h1>
        
        <p> 
            Záleží nám na ochrane Vášho súkromia. Preto si prosím pozorne prečítajte
            tieto podmienky ochrany súkromia (ďalej len „Podmienky ochrany
            súkromia“).
        </p>
        <p>
            Tieto Podmienky ochrany súkromia podrobne popisujú ako získavame,
            používame, uchovávame a prípadne zverejňujeme Vaše osobné údaje pri
            jednotlivých činnostiach vykonávaných Nadáciou otvorenej spološnosti. V
            prípade akýchkoľvek otázok, dotazov alebo žiadostí týkajúcich sa ochrany
            súkromia nás prosím kontaktujte na adrese osf@osf.sk.
        </p>
        <p>
            Ak sa v týchto Podmienkach ochrany súkromia používa pojem „Nadácia“,
            „my“, „nás“ a pod. má sa tým na mysli Nadácia otvorenej spoločnosti,
            sídlom Baštová 5, Bratislava, Slovensko.
        </p>
        <p>
            <strong>
            Na účely týchto Podmienok majú nasledovné pojmy tento význam:
            </strong>
        </p>
        <p>
            „Osobné údaje“ znamenajú všetky osobné údaje, ktoré sa vzťahujú ku
            konkrétnej žijúcej fyzickej osobe, ktorá je identifikovateľná alebo by
            prostredníctvom týchto údajov mohla byť identifikovanáa ktoré spracúvame
            na niektorý z účelov uvedených v týchto Podmienkach ochrany súkromia.
        </p>
        <p>
            „Prevádzkovateľ“ znamená konkrétnu osobu, ktorá určuje účel spracúvania
            Osobných údajov a je ním Nadácia v súlade s týmto Podmienkami
            ochrany súkromia. „Dotknutá osoba“ ste Vy ako fyzická osoba, ktorej
            Osobné údaje sú Nadáciou spracúvané.
        </p>
        <p>
            „Pseudonymizované údaje“ znamená informácie a údaje o všetkých
            Dotknutých osobách alebo jednotlivých kategóriách a skupinách Dotknutých
            osôb, ktoré sú anonymizované a spracované do formy, ktorá už neumožňuje
            identifikovať konkrétnu fyzickú osobu bez použitia dodatočných údajov.
        </p>
        <p>
            „Sprostredkovateľ“ znamená iná osoba ako Prevádzkovateľ v danom prípade,
            ktorá v mene Prevádzkovateľa v konkrétnom prípade spracúva Osobné
            údaje.
        </p>
        <p>
            „GDPR“ znamená Nariadenie Európskeho parlamentu a Rady (EÚ) 2016/679 z
            27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov
            a o voľnom pohybe takýchto údajov.
        </p>
        <p>
             „Zákon o ochrane osobných údajov“ znamená zákon č. 18/2018 Z. z. o
            ochrane osobných údajov a o zmene a doplnení niektorých zákonov.
        </p>
        <p>
            Vaše Osobné údaje spracúvame v súlade s GDPR a Zákonom o ochrane
            osobných údajov.
        </p>
        <p>
            <strong>Základné informácie o spracúvaní Osobných údajov</strong>
        </p>
        <p>
            V tejto časti Podmienok ochrany súkromia Vám poskytneme informácie o
            tom, ako nakladáme s Vašimi Osobnými údajmi, ktoré nám poskytujete pri
            používaní našej webovej stránky alebo inej spolupráci s Nadáciou.
        </p>
        <p>Aké Osobné údaje o Vás získavame?</p>
        <p>Osobné údaje nám poskytujete v prípade, že</p>
        <ul>
            <li>
            vyplníte Vaše Osobné údaje pri darovaní prostredníctvom platobných
            metód uverejnených na našej webovej stránke alebo iným spôsobom;
            </li>
            <li>s nami uzatvoríte osobitnú zmluvu;</li>
            <li>sa zapojíte do niektorého z Grantov;</li>
            <li>
            sa rozhodnete nás kontaktovať prostredníctvom e-mailu alebo iným
            spôsobom;
            </li>
            <li>
            sa rozhodnete prihlásiť na odber noviniek („newsletter“) na našej
            webovej stránke.
            </li>
        </ul>
        <p>
            Akékoľvek Osobné údaje nám poskytujete dobrovoľne a ste povinní sám
            zvážiť do akej miery takéto Osobné údaje poskytnete. Bez poskytnutia
            niektorých Osobných údajov však nemusí byť možné uskutočniť darovanie
            alebo zabezpečiť účasť v Programe alebo Grante. Bez poskytnutia
            niektorých Vašich Osobných údajov taktiež nemôžeme reagovať na Váš
            podnet či žiadosť, ktorú nám zašlete.
        </p>
        <p>
            Zodpovedáte za správnosť, úplnosť a pravdivosť poskytnutých Osobných
            údajov a budeme sa na správnosť, úplnosť a pravdivosť týchto Osobných
            údajov spoliehať. Nezodpovedáme za žiadne škody, ktoré by Vám alebo
            akejkoľvek tretej osobe mohli vzniknúť v súvislosti s uvedením
            nesprávnych, neúplných alebo nepravdivých Osobných údajov.
        </p>
        <p>
            Ak nám poskytujete svoje podobizne, obrazové snímky a obrazové a zvukové
            záznamy, tak nám týmto zároveň udeľujte svoje privolenie s použitím
            tejto podobizne, obrazovej snímky a obrazového a zvukového záznamu v
            zmysle ust. § 12 ods. 1 Občianskeho zákonníka.
        </p>
        <p>
            <strong>
            Niektoré Údaje, ktoré umožňujú Vašu identifikáciu nepriamo môžeme
            získavať aj z Vášho používania našej webovej stránky. Ide predovšetkým
            o nasledujúce údaje:
            </strong>
        </p>
        <ul>
            <li>
            informácie a dáta zo zariadení, ktoré používate na prístup na našu
            webovú stránku, ktoré môžu zahŕňať všeobecné informácie o Vašom
            zariadení;
            </li>
            <li>
            dáta logov, ktoré predstavujú dáta a informácie, ktoré spracúvame
            pomocou služby Google Analytics (najmä IP adresa, čas prístupu, údaje
            o hardware a software, ktorý používate, počet klikov, stránky,
            ktoré uvidíte alebo ich poradie a čas, ktorý na stránkach strávite a
            iné);
            </li>
            <li>
            informácie získané prostredníctvom súborov cookies a iných programov
            na monitorovanie užívateľov, a to vrátane cookies a monitorovacích
            programov našich partnerov alebo prevádzkovateľov sociálnych sietí.
            </li>
        </ul>
        <p>
            Vyššie uvedené údaje neumožňujú samo o sebe identifikovať konkrétnu
            žijúcu fyzickú osobu.
        </p>
        <p>
            Vyššie uvedené údaje sa stávajú Osobnými údajmi v momente kedy ich
            priradíme ku konkrétnej identifikovanej alebo identifikovateľnej žijúcej
            fyzickej osobe.
        </p>
        <p>
            <strong>Akým spôsobom Vaše osobné údaje spracúvame?</strong>
        </p>
        <p>
            Ako Prevádzkovateľ spracúvame Vaše Osobné údaje v rozsahu a v súlade s
            týmito Podmienkami ochrany súkromia a za účelom uvedeným nižšie v týchto
            Podmienkach ochrany súkromia.
        </p>
        <p>
            V prípade, že nám ako fyzická osoba poskytujete Vaše Osobné údaje pri
            darovaní prostredníctvom niektorej z metód dostupných na našej webovej
            stránke alebo iným spôsobom, tak budeme Vaše osobné údaje spracúvať za
            účelom vykonania úkonov potrebných na prijatie tohto daru a plnenie
            povinností vyplývajúcich z prijatia tohto Daru.
        </p>
        <p>
            Právnym základom pre spracúvanie Osobných údajov je v tomto prípade
            nevyhnutnosť spracovania Vašich osobných údajov na plnenie zmluvy
            vznikajúcej Vašim darovaním a vykonanie opatrení pred vznikom tejto
            zmluvy (právny dôvod podľa čl. 6 ods. 1 písm. b) Nariadenia GDPR).
        </p>
        <p>
            Vaše údaje budú v prípade tohto spracovania spracúvané po dobu trvania
            zmluvy (napr. ak ste sa rozhodli pre automatické opakované darovania
            alebo je súčasťou darovacej zmluvy aj informovanie o využití Vášho daru)
            a po dobu 4 rokov od darovania z dôvodu obrany proti Vašim prípadným
            nárokom. Po dlhšiu dobu budeme tieto Osobné údaje spracúvať len ak si to
            budú vyžadovať osobitné predpisy.
        </p>
        <p>
            Vaše Osobné údaje v rozsahu v akom ste uviedli pri uzatváraní akejkoľvek
            zmluvy s Nadáciou spracúvame za účelom plnenia zmluvy medzi Vami a
            Nadáciou.
        </p>
        <p>
            Právnym základom pre spracúvanie Osobných údajov je v tomto prípade
            plnenie zmluvy, ktorej zmluvnou stranou ste Vy ako dotknutá osoba
            (právny dôvod podľa čl. 6 ods. 1 písm. b) Nariadenia GDPR).
        </p>
        <p>
            Vaše Osobné údaje poskytnuté v súvislosti s uzatvorením akejkoľvek
            nepomenovanej zmluvy s Nadáciou budú spracúvané po dobu jej platnosti, a
            po dobu 4 rokov od jej zániku z dôvodu obrany proti Vašim prípadným
            nárokom. Po dlhšiu dobu budeme tieto Osobné údaje spracúvať len ak si to
            budú vyžadovať osobitné predpisy
        </p>
        <p>
            V prípade, že nám ako fyzická osoba poskytujete Vaše Osobné údaje pri
            zapojení sa do niektorého z dostupných Grantov, tak budeme Vaše osobné
            údaje spracúvať za účelom vykonania úkonov potrebných na Vaše zapojenie
            do Grantu a plnenie povinností vyplývajúcich z Vašej následnej účasti v
            Grante. Právnym základom pre spracúvanie Osobných údajov je v tomto
            prípade nevyhnutnosť spracovania Vašich osobných údajov na vykonanie
            opatrení pred vznikom tejto zmluvy o účasti v príslušnom grante a
            prípadné následné plnenie tejto zmluvy (právny dôvod podľa čl. 6 ods. 1
            písm. b) Nariadenia GDPR).
        </p>
        <p>
            Vaše údaje budú v prípade tohto spracovania spracúvané po dobu trvania
            vyhodnocovania Vášho projektu, ktorý ste prihlásili do Grantu a prípadne
            aj po dobu Vašej účasti v Grante (ak Váš projekt splní podmienky
            účasti). Po zamietnutí Vášho projektu alebo po zrealizovaní projektu v
            rámci Grantu budú Vaše Osobné údaje spracúvané ešte po dobu 4 rokov z
            dôvodu obrany proti Vašim prípadným nárokom. Po dlhšiu dobu budeme tieto
            Osobné údaje spracúvať len ak si to budú vyžadovať osobitné predpisy.
        </p>
        <p>
            Vaše Osobné údaje v rozsahu ako nám ich poskytnete (najmä e-mail) pri
            prihlásení na odber noviniek budeme spracúvať za účelom pravidelného
            zasielania informácii, na ktorých odber ste sa prihlásili.
        </p>
        <p>
            Právnym základom pre takéto spracúvanie Osobných údajov je Váš súhlas so
            s odberom noviniek (právny dôvod podľa čl. 6 ods. 1 písm. a)
            Nariadenia GDPR).
        </p>
        <p>
            Vaše Osobné údaje budú za týmto účelom spracúvané po dobu platnosti
            Vášho súhlasu.
        </p>
        <p>
            Vaše Osobné údaje, vrátane údajov získaných z Vášho používania našej
            webovej stránky špecifikovaných vyššie, používame aj na účely
            poskytovania, zlepšovania a optimalizáciu prevádzky, na zvýšenie
            komfortu pri jeho používaní, na zabezpečenie technickej podpory našej
            webovej stránky, analytického a štatistického vyhodnotenia používania a
            profilovania, zistenia a zabránenia zneužívania (vrátane prevencie pred
            podvodmi, bezpečnostnými incidentami a inými podobnými aktivitami),
            vyhodnotenia rizík a plnenia zákonných povinností.
        </p>
        <p>
            Právnym základom pre spracúvanie Osobných údajov je v tomto prípade náš
            oprávnený záujem na zaistení riadnej prevádzky našej webovej stránky a
            optimalizácií jeho funkcionalít (právny dôvod podľa čl. 6 ods. 1 písm.
            f) Nariadenia GDPR).
        </p>
        <p>
            Takémuto spracúvaniu môžete zabrániť zakázaním používania alebo
            ukladania súborov cookies na Vašom zariadení tým, že zmeníte nastavenie
            Vášho prehliadača. Niektoré funkcie našej webovej stránky však potom
            nemusia fungovať správne.
        </p>
        <p>
            Môžete tiež odstrániť súbory cookies, ktoré sa uložili na Vašom
            zariadení. Môžete tiež zvoliť prehliadanie našej webovej stránky v
            „privátnom režime“, ktorý obmedzí rozsah údajov ukladaných súbormi
            cookies. Bližšie informácie o používaní súborov cookies sa dozviete v
            našich podmienkach používania súborov cookies uverejnených na našej
            webovej stránke.
        </p>
        <p>
            Vaše Osobné údaje sprístupníme tretím osobám v nasledovných prípadoch:
        </p>
        <ul>
            <li>
            ak nám taká povinnosť vyplýva z platných právnych predpisov, najmä na
            žiadosť orgánov činných v trestnom konaní, súdov, iných orgánov
            verejnej moci alebo iných oprávnených osôb;
            </li>
            <li>
            ak je to nevyhnutné na plnenie našich povinností a pri zabezpečení
            našich oprávnených záujmov najmä našim advokátom, poradcom, audítorom,
            poskytovateľom IT služieb, marketingovým a reklamným agentúram a pod.;
            </li>
            <li>
            ak nie je Nadácia Prevádzkovateľom pri spracúvaní Osobných údajov ale
            je napríklad postavení ich sprostredkovateľa pre tretiu osobu budú
            tieto Osobné údaje poskytnuté príslušnému Prevádzkovateľovi, o ktorého
            identite budete vopred informovaný.
            </li>
        </ul>
        <p>
            S osobami, ktoré sú voči nám v postavení Sprostredkovateľa uzatvárame v
            súlade s GDPR zmluvy o spracúvaní osobných údajov a Sprostredkovatelia
            spracúvajú Osobné údaje vždy na základe pokynov a v mene Nadácie. Všetci
            naši Sprostredkovatelia spracúvajú Osobné údaje v súlade s týmito
            Podmienkami ochrany súkromia.
        </p>
        <p>
            Vaše Osobné údaje s výnimkou e-mailovej adresy pre účely zasielania
            noviniek neprenášame mimo Európskeho Hospodárskeho priestoru, ak by k
            tomu v budúcnosti malo dôjsť, zabezpečíme aby tento prenos prebiehal za
            podmienok a v štandarde stanovenom GDPR a Zákonom o ochrane osobných
            údajov.
        </p>
        <p>
            E-mailové adresy za účelom odosielania noviniek prenášame do USA, kde
            využívame služby MailChimp. Tento prenos Vašich osobných údajov podlieha
            režimu vykonávacieho rozhodnutia podľa smernice Európskeho parlamentu a
            Rady 95/46/ES o primeranosti ochrany poskytovanej štítom na ochranu
            osobných údajov medzi EÚ a USA (tzv. Štít na ochranu osobných údajov
            medzi EÚ a USA , v anglickom jazyku označený ako „EU – U.S. Privacy
            Shield“), ktoré vydala Európska komisia dňa 12.07.2016.
        </p>
        <p>
            Štít na ochranu osobných údajov medzi EÚ a USA je právny rámec pre
            transatlantické výmeny osobných údajov na obchodné účely medzi EÚ a USA.
            Európska komisia potvrdila, že Štít na ochranu osobných údajov poskytuje
            primeranú úroveň ochrany osobných údajov poskytovaných z EÚ do USA pre
            spoločnosti zapísané v zozname Štítu na ochranu osobných údajov, a je
            teda nástrojom zaisťujúcim právne záruky pre takto poskytnuté osobné
            údaje. Cieľom Štítu na ochranu osobných údajov je umožniť jednoduchší
            prenos osobných údajov z EÚ do USA a zároveň poskytnúť ochranu
            týmto osobným údajom a možnosť dotknutých osôb jednoduchšie si uplatniť
            svoje práva pred príslušnými inštitúciami.
        </p>
        <p>
            Ako dotknutá osoba máte vo vzťahu k spracúvaným Osobným údajom
            nasledovné práva. Máte právo požadovať od nás potvrdenie o tom, či sa
            Vaše Osobné údaje spracúvajú a žiadať o sprístupnenie týchto
            Osobných údajov.
        </p>
        <p>
            Máte právo požadovať od nás aby sme bez zbytočného odkladu opravili
            nesprávne Osobné údaje, ktoré sa Vás týkajú a so zreteľom na účel
            spracúvania Osobných údajov máte právo aj na doplnenie Vašich neúplných
            Osobných údajov.
        </p>
        <p>
            Máte právo požadovať od nás aby sme bez zbytočného odkladu vymazali
            Osobné údaje, ktoré sa Vás týkajú ak:
        </p>
        <ul>
            <li>
            už nie sú potrebné na účel, na ktorý sa získali alebo inak spracúvali;
            </li>
            <li>
            ste odvolali svoj súhlas s ich spracúvaním a ak neexistuje iný právny
            základ pre ich spracúvanie;
            </li>
            <li>namietate ich spracúvanie;</li>
            <li>
            sa Vaše Osobné údaje získali nezákonne, je dôvod pre výmaz splnenie
            povinnosti podľa GDPR, osobitného predpisu alebo medzinárodnej zmluvy,
            ktorou je Slovenská republika viazaná; alebo boli Osobné údaje získane
            v súvislosti s ponukou služieb informačnej spoločnosti od Nadácie
            podľa čl. 8 ods. 1 GDPR.
            </li>
        </ul>
        <p>
            Máte právo namietať z dôvodov týkajúcich sa Vašej konkrétnej situácie
            spracúvanie Vašich Osobných údajov vrátane profilovania, ktoré je
            vykonávané na právnom základe, ktorým je náš oprávnený záujem. Na
            základe Vašej námietky vyhodnotíme, či s ohľadom na Vašu konkrétnu
            situáciu, prevažuje ochrana Vášho oprávneného záujmu, Vašich práv a
            slobôd nad našimi oprávnenými záujmami, ktoré sledujeme spracúvaním
            Vašich Osobných údajov alebo ak oprávneným dôvodom je preukazovanie,
            uplatňovanie alebo obhajovanie našich právnych nárokov.
        </p>
        <p>
            Máte právo odvolať kedykoľvek súhlas so spracúvaním Osobných údajov.
            Odvolanie súhlasunemá vplyv na zákonnosť spracúvania Osobných údajov
            vykonávaného na jeho základe pred jeho odvolaním. Odvolanie súhlasu sa
            netýka spracúvania Osobných údajov vykonávaných Nadáciou na inom právnom
            základe ako na základe Vášho súhlasu.
        </p>
        <p>
            Máte právo iniciovať konanie o ochrane osobných údajov pred Úradom na
            ochranu osobných údajov Slovenskej republiky alebo iným dozorným orgánom
            v mieste bydliska.
        </p>
        <p>
            <strong>Záverečné ustanovenia</strong>
        </p>
        <p>
            Všetky Osobné údaje, ktoré zhromažďujeme a spracúvame, sú chránené
            primeranými technickými prostriedkami a bezpečnostnými opatreniami, aby
            nedošlo k neoprávnenému prístupu alebo zneužitiu týchto Osobných údajov.
            Neustále zlepšujeme a implementujeme nové administratívne, technické a
            organizačné opatrenia na zabezpečenie primeranej bezpečnosti Osobných
            údajov.
        </p>
        <p>
            Bez ohľadu na všetky naše opatrenia je potrebné mať na pamäti, že prenos
            dát cez verejnú sieť internetu ani akékoľvek ukladanie elektronických
            dát nemôže byť 100% bezpečné.
        </p>
        <p>
            Sme oprávnení tieto Podmienky ochrany súkromia kedykoľvek meniť alebo
            upravovať.
        </p>
        <p>
            Ak urobíme akékoľvek zmeny v týchto Podmienkach ochrany súkromia,
            umiestnime nové Podmienky ochrany súkromia na našej webovej stránke a
            podľa potreby Vás o zmene Podmienok ochrany súkromia budeme informovať
            aj prostredníctvom emailu.
        </p>
        <p>
            Takto zmenené Podmienky ochrany súkromia budú platné a účinné od
            momentu, ktorý určíme ako deň ich platnosti a účinnosti a používaním
            našej webovej stránky (alebo aplikácie) vyjadrujete súhlas s týmito
            novými Podmienkami ochrany súkromia.
        </p>
        <p>
            Ak máte otázky, dotazy alebo žiadosti k týmto Podmienkam ochrany
            súkromia, chcete odvolať súhlas alebo uplatniť Vaše práva, kontaktujte
            nás prostredníctvom emailovej správy na emailovej adrese osf@osf.sk
        </p>
        </div>
    </div>
  );
};

export default PrivacyPolicy;
