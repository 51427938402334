import {connect} from 'react-redux';

import {RootState} from '../../../store/root-reducer';

import {passwordResetClearAction, passwordResetErrorAction, passwordResetRequestAction} from './actions';
import PasswordReset from './component';
import PasswordResetState from './state';
import {withRouter} from "react-router-dom";

const mapStateToProps = (state: RootState): PasswordResetState => state.auth.passwordReset;
const actions = {
    passwordResetRequestAction,
    passwordResetClearAction,
    passwordResetErrorAction,
};

export default withRouter(connect(mapStateToProps, actions)(PasswordReset));
