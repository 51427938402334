import Routes, {combineRoutes, mapRoutesToComponent} from '../../service/routes';
import AuthRoutes from '../auth/routes';
import GameRoutes from "../game/routes";
import AdminRoutes from "../admin/routes";

import Landing from '../landing';
import QR from '../qr';
import Auth from '../auth';
import Game from "../game";
import Admin from "../admin";
import Downloads from '../downloads';

const AppBodyRoutes: Routes = combineRoutes([
	[{
		pathname: '/',
		exact: true,
		component: Landing,
		title: 'HOPE',
	}],
	[{
		pathname: '/qr',
		exact: true,
		component: QR,
		title: 'QR',
	}],
	[{
		pathname: '/downloads',
		exact: true,
		component: Downloads,
		title: 'Downloads'
	}],
	mapRoutesToComponent(AuthRoutes, Auth),
	mapRoutesToComponent(GameRoutes, Game),
	mapRoutesToComponent(AdminRoutes, Admin, 'Admin'),
]);

export default AppBodyRoutes;
