import {request} from './request';
import {AuthData} from "../auth";
import {AnswerData} from "./conversation";

export interface AreaData {
    id: number,
    name: string,
}

export interface Areas {
    data: AreaData[]
}

export interface NewAreaData {
    name: string,
}

export interface AreaHistoryData {
    points: number,
    start: number,
    end: number,
}

export interface HistoryData {
    id: number,
    areaId: number,
    userId: number,
    points: number
}

export interface AreasHistory {
    data: HistoryData[]
}

export interface Conversation {
    id: number,
    areaId: number,
    question: string,
    order: number,
    answers: AnswerData[]
}

export interface ConversationData {
    data: Conversation[]
}

export default class AreaApi {
    /**
     * Executes a request to get all areas from the server
     */
    static get(auth: AuthData): Promise<Areas> {
        return request('/area/all').get().auth(auth).json().execute() as Promise<Areas>;
    }

    /**
     * Executes a request to get specific are from the server
     */
    static getArea(auth: AuthData, data: AreaData): Promise<AreaData> {
        return request('/area/' + data.id).get().auth(auth).json().execute() as Promise<AreaData>;
    }

    /**
     * Executes a request to get history for specific area from the server
     */
    static getAreaHistory(auth: AuthData, data: HistoryData): Promise<AreasHistory> {
        return request('/area/' + data.id + '/history').get().auth(auth).json().execute() as Promise<AreasHistory>;
    }

    /**
     * Executes a request to get conversations for specific area from the server
     */
    static getAreaConversations(auth: AuthData, data: AreaData): Promise<ConversationData> {
        return request('/area/' + data.id + '/conversations').get().auth(auth).json().execute() as Promise<ConversationData>;
    }

    /**
     * Executes a request to create new area with specified name
     */
    static createArea(auth: AuthData, data: NewAreaData): Promise<AreaData> {
        return request('/area').post(data).auth(auth).json().execute() as Promise<AreaData>;
    }

    /**
     * Executes a request to create history entry for are
     */
    static createAreaHistory(auth: AuthData, data: AreaData, history: AreaHistoryData): Promise<AreasHistory> {
        return request('/area/' + data.id + '/history').post(history).auth(auth).json().execute() as Promise<AreasHistory>;
    }
}
