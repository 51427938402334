import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {RootState} from '../../../store/root-reducer';

import {resetTokenRequestAction, resetTokenClearAction} from './actions';
import ResetTokenState from './state';
import ResetToken from "./component";

const mapStateToProps = (state: RootState): ResetTokenState => state.auth.resetToken;
const actions = {
    resetTokenRequestAction,
    resetTokenClearAction,
};

export default withRouter(connect(mapStateToProps, actions)(ResetToken));
