import {
	AREAS_REQUEST,
	AREAS_ERROR,
	AREAS_SUCCESS,
	CONVERSATIONS_REQUEST,
	CONVERSATIONS_ERROR,
	CONVERSATIONS_SUCCESS,
	CONVERSATIONS_CLEAR,
	QUESTION_UPDATE_REQUEST,
	QUESTION_UPDATE_SUCCESS,
	QUESTION_UPDATE_ERROR,
	ANSWER_UPDATE_REQUEST,
	ANSWER_UPDATE_SUCCESS,
	ANSWER_UPDATE_ERROR,
	CONVERSATION_CREATE_REQUEST,
	CONVERSATION_CREATE_SUCCESS,
	CONVERSATION_CREATE_ERROR,
	CONVERSATION_DELETE_REQUEST,
	CONVERSATION_DELETE_SUCCESS,
	CONVERSATION_DELETE_ERROR,
} from './constants';

import {AdminDialoguesAction} from './actions';
import AdminDialoguesState from './state';

const initialState: AdminDialoguesState = {
	requesting: false,
	success: false,
	errors: {},
	areas: {
		data: [],
	},
	conversations: {
		data: [],
	},
};

export default function (state = initialState, action: AdminDialoguesAction): AdminDialoguesState {
	switch (action.type) {
		case AREAS_REQUEST:
			return {
				...state, ...{
					requesting: true,
					success: false,
					errors: state.errors,
				}
			};

		case AREAS_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requesting: false,
					success: true,
					areas: action.areas,
				}
			};

		case AREAS_ERROR:
			return {
				...state, ...{
					requesting: false,
					success: false,
					errors: action.errors,
				}
			};

		case CONVERSATIONS_REQUEST:
			return {
				...state, ...{
					requesting: true,
					success: false,
					errors: state.errors,
				}
			};

		case CONVERSATIONS_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requesting: false,
					success: true,
					conversations: action.conversations,
				}
			};

		case CONVERSATIONS_ERROR:
			return {
				...state, ...{
					requesting: false,
					success: false,
					errors: action.errors,
				}
			};

		case CONVERSATIONS_CLEAR:
			return {
				...state, ...{
					conversations: {
						data: []
					}
				}
			}

		case QUESTION_UPDATE_REQUEST:
			return {
				...state, ...{
					requesting: true,
					success: false,
					errors: state.errors,
				}
			};

		case QUESTION_UPDATE_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requesting: false,
					success: true,
				}
			};

		case QUESTION_UPDATE_ERROR:
			return {
				...state, ...{
					requesting: false,
					success: false,
					errors: action.errors,
				}
			};

		case ANSWER_UPDATE_REQUEST:
			return {
				...state, ...{
					requesting: true,
					success: false,
					errors: state.errors,
				}
			};

		case ANSWER_UPDATE_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requesting: false,
					success: true,
				}
			};

		case ANSWER_UPDATE_ERROR:
			return {
				...state, ...{
					requesting: false,
					success: false,
					errors: action.errors,
				}
			};

		case CONVERSATION_CREATE_REQUEST:
			return {
				...state, ...{
					requesting: true,
					success: false,
					errors: state.errors,
				}
			};

		case CONVERSATION_CREATE_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requesting: false,
					success: true,
				}
			};

		case CONVERSATION_CREATE_ERROR:
			return {
				...state, ...{
					requesting: false,
					success: false,
					errors: action.errors,
				}
			};

		case CONVERSATION_DELETE_REQUEST:
			return {
				...state, ...{
					requesting: true,
					success: false,
					errors: state.errors,
				}
			};

		case CONVERSATION_DELETE_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requesting: false,
					success: true,
				}
			};

		case CONVERSATION_DELETE_ERROR:
			return {
				...state, ...{
					requesting: false,
					success: false,
					errors: action.errors,
				}
			};

		default:
			return state;
	}
}
