import {
    AREAS_REQUEST,
    AREAS_CLEAR,
    AREAS_ERROR,
    AREAS_SUCCESS,
    AREA_SET,
    AREA_CLEAR,
} from './constants';
import {AreasAction} from './actions';
import AreasState from './state';

const initialState: AreasState = {
    requesting: false,
    success: false,
    errors: {},
    areas: {
        data: []
    },
    area: null
};

export default function (state = initialState, action: AreasAction): AreasState {
    switch (action.type) {
        case AREAS_REQUEST:
            return {
                ...state, ...{
                    requesting: true,
                    success: false,
                    errors: state.errors,
                }
            };

        case AREAS_SUCCESS:
            return {
                ...state, ...{
                    errors: {},
                    requesting: false,
                    success: true,
                    areas: action.areas,
                }
            };

        case AREAS_ERROR:
            return {
                ...state, ...{
                    requesting: false,
                    success: false,
                    errors: action.errors,
                }
            };

        case AREAS_CLEAR:
            return {
                ...state, ...{
                    requesting: false,
                    success: false,
                    errors: {},
                }
            };

        case AREA_SET:
            return {
                ...state, ...{
                    area: action.area
                }
            };

        case AREA_CLEAR:
            return {
                ...state, ...{
                    area: null
                }
            };

        default:
            return state;
    }
}
