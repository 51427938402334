import {call, put, takeLatest} from 'redux-saga/effects';

import AuthApi, {AccountData, SignupData} from '../../../service/api/auth';
import EventApi from '../../../service/api/event';
import {gettext, t} from 'ttag';

import {
    signupErrorAction,
    SignupRequestAction,
    signupSuccessAction,
} from './actions';
import {SIGNUP_REQUEST} from './constants';
import AccountApi from '../../../service/api/account';
import {safe} from '../../../service/functions';


function* signup(action: SignupRequestAction) {
    for (let [key, value] of Object.entries(action)) {
        if (key !== 'gender' && key !== 'age' && key !== 'passwordConfirm' && (!value || value === ' ')) {
            yield put(signupErrorAction({input: t`Please fill ` + key + `.`, type: key}));
            return;
        }
    }

    if (action.password !== action.passwordConfirm) {
        yield put(signupErrorAction({password: t`Password confirmation mismatch.`}));
        return;
    }

    const signup_data = {
        username: action.username,
        email: action.email,
        password: action.password,
    } as SignupData;

    let auth = yield call(AuthApi.signup, signup_data);
    if (auth.error === 'CONFLICT') {
        if(!auth.info || !auth.info.duplicate) {
            yield put(signupErrorAction({input: t`User with this email already exists.`}));
            return;
        }

        if(auth.info.duplicate.value === action.email) {
            yield put(signupErrorAction({input: t`User with this email already exists.`}));
            return;
        }

        if(auth.info.duplicate.value === action.username) {
            yield put(signupErrorAction({input: t`User with this username already exists.`}));
            return;
        }

        yield put(signupErrorAction({input: t`User with this email already exists.`}));
        return;
    }

    if (!auth.accessToken) {
        yield put(signupErrorAction({input: gettext(auth.message.replace('Request failed: ', '').replaceAll('"', ''))}));
        return;
    }

    const account_data = {
        username: action.username,
        age: action.age,
        gender: action.gender,
        name: action.name,
    } as AccountData;

    let dataSuccess = yield call(AccountApi.put, auth, account_data);
    if (!dataSuccess) {
        yield put(signupErrorAction({input: t`Signup partial fail.` + ' - ' + t`server failed to save profile data`}));
    }

    yield put(signupSuccessAction());

    yield call(EventApi.registerEvent, 'register', JSON.stringify({timestamp: Date.now()}));

    if (action.callback)
        yield call(action.callback);

}

function* onError() {
    yield put(signupErrorAction({input: t`Signup error.` + ' - ' + t`server not responding`}));
}

export default function* SignupSaga() {
    yield takeLatest(SIGNUP_REQUEST, safe([onError], signup));
}
