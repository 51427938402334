import {call, put, takeLatest} from 'redux-saga/effects';
import ClientStorage from '../../../client/storage';
import {t} from 'ttag';
import {
    avatarErrorAction,
    avatarSuccessAction,
} from './actions';

import {AVATAR_REQUEST} from './constants';
import CharacterApi from '../../../service/api/character';
import {safe} from '../../../service/functions';


function* avatarRequest() {
    let client = yield call(ClientStorage.getItem);

    let avatars = yield call(CharacterApi.getAllCharacters, client.auth);

    yield put(avatarSuccessAction({avatars}));
}

function* onError() {
    yield put(avatarErrorAction({input: t`Avatar error.` + ' - ' + t`server not responding`}));
}

export default function* AvatarSaga() {
    yield takeLatest(AVATAR_REQUEST, safe([onError], avatarRequest));
}
