import {
    FACEBOOK_SIGNUP_REQUEST,
    SIGNUP_CANCEL,
    SIGNUP_CLEAR,
    SIGNUP_ERROR,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
} from './constants';
import {SignupAction} from './actions';
import SignupState from './state';

const initialState: SignupState = {
    requesting: false,
    success: false,
    errors: {},
};

export default function (state = initialState, action: SignupAction): SignupState {
    switch (action.type) {
        case SIGNUP_REQUEST:
            return {
                requesting: true,
                success: false,
                errors: state.errors,
            };

        case FACEBOOK_SIGNUP_REQUEST:
            return {
                requesting: true,
                success: false,
                errors: state.errors,
            };

        case SIGNUP_SUCCESS:
            return {
                errors: {},
                requesting: false,
                success: true,
            };

        case SIGNUP_ERROR:
            return {
                requesting: false,
                success: false,
                errors: action.errors,
            };

        case SIGNUP_CANCEL:
            return {
                requesting: false,
                success: false,
                errors: {},
            };

        case SIGNUP_CLEAR:
            return {
                requesting: false,
                success: false,
                errors: {},
            };

        default:
            return state;
    }
}
