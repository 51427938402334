import {call} from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

export const focusOnEnter = (key: string, id: string) => {
    if (key === 'Enter') {
        let object = document.getElementById(id);
        if (object) {
            object.focus();
        }
    }
}

export const actionOnEnter = (key: string, action: any) => {
    if (key === 'Enter') {
        action();
    }
}

export const arrayStatistics = (data: Array<any>, predicate: (el: any) => string) => {
    let stats: { [key: string]: number } = {};

    for (const i of data) {
        const value = predicate(i);

        if (value === undefined)
            continue;

        if (stats.hasOwnProperty(value)) {
            stats[value]++;
        } else {
            stats[value] = 1;
        }
    }

    return stats;
}

export const monthBoundary = (offset: number) => {
    let since = new Date();
    since.setDate(1);
    since.setMonth(since.getMonth() - offset);

    let until = new Date(since);
    until.setMonth(until.getMonth() + 1);

    let formatSince = `${since.getFullYear()}-${(since.getMonth() + 1).toString().padStart(2, '0')}-01`;
    let formatUntil = `${until.getFullYear()}-${(until.getMonth() + 1).toString().padStart(2, '0')}-01`;

    return {since: formatSince, until: formatUntil};
}

export const browserNameFromAgent = (userAgent: string) => {
    if (userAgent.indexOf('Firefox') > -1) {
        return 'Mozilla Firefox';
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (userAgent.indexOf('SamsungBrowser') > -1) {
        return 'Samsung Internet';
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        return 'Opera';
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (userAgent.indexOf('Trident') > -1) {
        return 'Microsoft Internet Explorer';
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (userAgent.indexOf('Edge') > -1) {
        return 'Microsoft Edge';
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (userAgent.indexOf('Chrome') > -1) {
        return 'Google Chrome or Chromium';
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (userAgent.indexOf('Safari') > -1) {
        return 'Apple Safari';
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        return 'unknown';
    }
}

export const safe = (handlers: any[] = [null], saga: any, ...args: any) => function* (action: any) {
    try {
        yield call(saga, ...args, action);
    } catch (err) {
        for (let handler of handlers) {
            yield call(handler, ...args, err);
        }
        Sentry.captureException(err);
    }
}
