import {
    SCORE_SET,
    START_TIME_SET,
    END_TIME_SET,
    GAME_STATE_SET,
    SCORE_RESET,
} from './constants';
import {ScoreAction} from './actions';
import ScoreState, {GameState} from './state';

const initialState: ScoreState = {
    game_state: GameState.playing,
    score: 0,
    start_time: 0,
    end_time: 0,
};

export default function (state = initialState, action: ScoreAction): ScoreState {
    switch (action.type) {
        case SCORE_SET:
            return {
                ...state, ...{
                    score: action.score,
                }
            };

        case START_TIME_SET:
            return {
                ...state, ...{
                    start_time: action.start_time,
                }
            };

        case END_TIME_SET:
            return {
                ...state, ...{
                    end_time: action.end_time,
                }
            };

        case GAME_STATE_SET:
            return {
                ...state, ...{
                    game_state: action.game_state,
                }
            };

        case SCORE_RESET:
            return {
                ...initialState,
            }


        default:
            return state;
    }
}
