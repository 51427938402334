import {request} from './request';
import {AuthData} from "../auth";


export interface CharacterData {
    id: number,
    name: string,
    picture: string
}

export interface CharacterList {
    data: CharacterData[]
}

export default class CharacterApi {
    /**
     * Executes a request to create new character
     */
    static createCharacter(auth: AuthData, {name}: CharacterData): Promise<CharacterData> {
        return request('/character').post({name}).auth(auth).json().execute() as Promise<CharacterData>;
    }

    /**
     * Executes a request to get all characters
     */
    static getAllCharacters(auth: AuthData): Promise<CharacterList> {
        return request('/character/all').get().auth(auth).json().execute() as Promise<CharacterList>;
    }

    /**
     * Executes a request to get specific character
     */
    static getCharacter(auth: AuthData, id: number): Promise<CharacterData> {
        return request('/character/' + id).get().auth(auth).json().execute() as Promise<CharacterData>;
    }

    /**
     * Executes a request to delete character
     */
    static deleteCharacter(auth: AuthData, id: number): Promise<any> {
        return request('/character/' + id).delete().auth(auth).execute();
    }

    /**
     * Executes a request to update character picture
     */
    static setCharacterPicture(auth: AuthData, id: number, body: {}): Promise<CharacterData> {
        return request('/character/' + id + '/picture').post(body).auth(auth).json().execute() as Promise<CharacterData>;
    }
}
