import {all} from 'redux-saga/effects';

import LoginSaga from './login/sagas';
import ResetTokenSaga from './reset-token/sagas';
import ResetPasswordSaga from './password-reset/sagas';
import SignupSaga from './signup/sagas';

export default function* AuthSaga() {
	yield all([
		LoginSaga(),
		ResetTokenSaga(),
		ResetPasswordSaga(),
		SignupSaga(),
	]);
}

