import { RootState } from '../../../store/root-reducer';
import { AdminDownloads } from './component';
import AdminDownloadsState from './state';
import { filesRequestAction, fileDeleteRequestAction, fileUpdateRequestAction, fileCreateRequestAction } from './actions';
import { connect } from 'react-redux';

const mapStateToProps = (state: RootState): AdminDownloadsState => state.admin.downloads;

const actions = {
    filesRequestAction,
    fileDeleteRequestAction,
    fileUpdateRequestAction,
    fileCreateRequestAction,
};

export default connect(mapStateToProps, actions)(AdminDownloads);
