import CryptoJS from 'crypto-js';

export default class Storage {
	static EncryptionKey = process.env.REACT_APP_COOKIES_ENCRYPTION_KEY;

	/**
	 * Returns an object from local storage associated with key
	 */
	static getItem(key: string): any {
		try {
			let serializedValue = window.localStorage.getItem(key) || '';

			if (Storage.EncryptionKey)
				serializedValue = CryptoJS.AES.decrypt(serializedValue, Storage.EncryptionKey).toString(CryptoJS.enc.Utf8);

			return JSON.parse(serializedValue);
		} catch (_) {
			return null;
		}
	}

	/**
	 * Sets the local storage entry with key to value
	 */
	static setItem(key: string, value: any) {
		let serializedValue = JSON.stringify(value);

		if (Storage.EncryptionKey)
			serializedValue = CryptoJS.AES.encrypt(serializedValue, Storage.EncryptionKey).toString();

		window.localStorage.setItem(key, serializedValue);
	}

	/**
	 * Removes the local storage entry associated with key
	 */
	static removeItem(key: string) {
		window.localStorage.removeItem(key);
	}

	/**
	 * Clears all entries in local storage
	 */
	static clear() {
		window.localStorage.clear();
	}
}

