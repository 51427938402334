import {call, put, takeLatest} from 'redux-saga/effects';

import {PASSWORD_RESET_REQUEST} from './constants';
import {passwordResetErrorAction, PasswordResetRequestAction, passwordResetSuccessAction} from './actions';
import AuthApi from '../../../service/api/auth';
import HttpResponseCode from '../../../service/api/responseCode';
import {t} from 'ttag';
import {safe} from '../../../service/functions';

function* resetPassword(action: PasswordResetRequestAction) {
    for (let [key, value] of Object.entries(action)) {
        if (!value) {
            yield put(passwordResetErrorAction({input: t`Please fill ` + key + `.`, type: key}));
            return;
        }
    }
    const result = yield call(AuthApi.resetPassword, action);
    if (result.hasOwnProperty('id')) {
        yield put(passwordResetSuccessAction());

        if (action.callback)
            yield call(action.callback);
    } else {
        if (result.status === HttpResponseCode.ServerError) {
            yield put(passwordResetErrorAction({input: t`Reset password error.` + ' - ' + t`server made a mistake`}));
        } else {
            yield put(passwordResetErrorAction({input: t`Reset password error.` + ' - ' + t`wrong token`}));
        }
    }
}

function* onError() {
    yield put(passwordResetErrorAction({input: t`Reset password error.` + ' - ' + t`server not responding`}));
}

export default function* ResetPasswordSaga() {
    yield takeLatest(PASSWORD_RESET_REQUEST, safe([onError], resetPassword));
}
