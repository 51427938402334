import Login from './login';
import Signup from './signup';
import PasswordReset from './password-reset';
import PrivacyPolicy from './privacy-policy';

const AuthRoutes = [
	{
		pathname: '/login',
		exact: true,
		component: Login,
	},
	{
		pathname: '/signup',
		exact: true,
		component: Signup,
	},
	{
		pathname: '/password-reset',
		exact: true,
		component: PasswordReset,
	},
	{
		pathname: '/privacy-policy',
		exact: true,
		component: PrivacyPolicy,
	}
];

export default AuthRoutes;
