import React, {useEffect, useState} from 'react';

import heart from '../../../assets/icons/hearth.svg';
import troll from '../../../assets/characters/troll/troll-cervena.png';
import hater from '../../../assets/characters/hater/hater-cervena.png';
import fanatik from '../../../assets/characters/fanatik/fanatik-cervena.png';
import stalker from '../../../assets/characters/stalker/stalker-cervena.png';
import ScoreState, {GameState} from "./state";
import {AreaData, ConversationData} from "../../../service/api/area";
import {BrowserHistory} from "history";
import {AnswerData} from "../../../service/api/conversation";

export type ScorePageProps = ScoreState & {
    area: AreaData | null,
    conversations: ConversationData | null,
    lastAnswer: AnswerData | null,
    history: BrowserHistory,
}


const ScorePage: React.FunctionComponent<ScorePageProps> = props => {
    const {
        game_state,
        area,
        score,
        start_time,
        lastAnswer,
        conversations,
        end_time,
        history,
    } = props;

    const initialMessage = (gameState: GameState): string => {
        switch (gameState) {
            case GameState.game_lost:
                return 'Prehral si!';
            case GameState.game_won:
                return 'Vyhral si!';
            case GameState.life_lost:
                return 'Dávaj pozor';
            case GameState.question_passed:
                return 'Len tak ďalej';
            default:
                return '';
        }
    }
    const [warningMessage, adviceMessage] = [lastAnswer ? lastAnswer!.message : "Message", lastAnswer ? lastAnswer.hint : "Hint"];

    const [enemy, setEnemy] = useState("");
    const [maxScore, setMaxScore] = useState(0);

    useEffect(() => {
        if (!area) {
            history.push('/dialog');
        } else {
            setEnemy(area.name);
        }
        if (conversations && conversations.data && !maxScore) {
            setMaxScore(conversations.data.length);
        }

    }, [enemy, area, history, maxScore, conversations]);


    const enemies = {
        "hater": hater,
        "troll": troll,
        "stalker": stalker,
        "fanatik": fanatik,
    }

    const getEnemy = (val: string) => {
        return enemies.hasOwnProperty(val) ? (enemies as any)[val] : null;
    }

    return (
        <div className={"scorepage relative m-0 p-0 flex justify-center text-center text-white text-xl " + enemy}>
            <div className="py-8 w-full blackBg relative min-h-screen">
                <div className="scorepage-inner-body relative">
                    <h2 className="underline">{initialMessage(game_state)}</h2>
                    {(game_state === GameState.life_lost || game_state === GameState.question_passed) && (
                        <div>
                            <p className="my-2">{game_state === GameState.life_lost ? "Stratil si jeden" : "Tvoje priebežné skóre"}</p>
                            {game_state === GameState.life_lost ? (
                                <img src={heart} alt="heart" style={{width: "40.54px"}} className=""/>
                            ) : (
                                <p style={{fontSize: "32px"}}>{score}/{maxScore}</p>
                            )}
                            <br/>
                            <img src={getEnemy(enemy)} alt="enemy" style={{width: "90px"}} className="py-6"/>
                            <div className="scorepage-message mx-auto mb-2 py-4 px-4">
                                <div className={'advisor' + (game_state === GameState.life_lost ? ' advisor-red' : ' advisor-green')}>Poradca</div>
                                <p className="pb-2" style={{color: game_state === GameState.life_lost ? '#E23955' : '#55e239'}}>{game_state === GameState.life_lost ? 'Pozor!' : 'Výborne!'}</p>
                                <div className="my-auto inner-message-body">
                                    <p className="text-black" style={{fontSize: "14px"}}>{warningMessage}</p>
                                    <p className="text-black py-2"
                                       style={{fontSize: "14px", color: "grey"}}>{adviceMessage}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {(game_state === GameState.game_won || game_state === GameState.game_lost) && (
                        <div>
                            <img src={getEnemy(enemy)} alt="enemy" style={{width: "90px"}} className="py-6"/>
                            <div>
                                <p className="py-2">
                                    Celkový čas
                                    <br/>
                                    {new Date(end_time - start_time).toISOString().substr(14, 5)}
                                </p>
                                <p className="py-2">
                                    Tvoje skóre
                                    <br/>
                                    {score}/{maxScore}
                                </p>
                            </div>
                            {game_state === GameState.game_lost && (
                                <div className="scorepage-message mx-auto mb-2 py-4 px-4">
                                    <div className="advisor advisor-red">Poradca</div>
                                    <p className="pb-2" style={{color: '#E23955'}}>Pozor!</p>
                                    <div className="my-auto inner-message-body">
                                        <p className="text-black" style={{fontSize: "14px"}}>{warningMessage}</p>
                                        <p className="text-black py-2"
                                            style={{fontSize: "14px", color: "grey"}}>{adviceMessage}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className='scorepage-buttons flex flex-col  justify-center'>
                        <button className='continue-button flex items-center justify-center mx-auto my-4 p-2'
                                onClick={() => {
                                    if (game_state === GameState.life_lost || game_state === GameState.question_passed) {
                                        history.push('/dialog')
                                    } else {
                                        history.push('/scenario')
                                    }
                                }}>
                            <p className="text-blue">
                                {(game_state === GameState.life_lost || game_state === GameState.question_passed) ? "Pokračovať" : "Ísť na mapu"}
                            </p>
                        </button>


                        <button className="end-button flex items-center justify-center mx-auto p-2" onClick={() => {
                            history.push('/')
                        }}>
                            <p className="text-blue">
                                {(game_state === GameState.life_lost || game_state === GameState.question_passed) ? "Vzdať sa" : "Ísť na stránku"}
                            </p>
                        </button>
                    </div>

                </div>
            </div>
        </div>)
}

export default ScorePage;
