import {
	PASSWORD_RESET_CANCEL,
	PASSWORD_RESET_CLEAR,
	PASSWORD_RESET_ERROR,
	PASSWORD_RESET_REQUEST,
	PASSWORD_RESET_SUCCESS,
} from './constants';
import PasswordResetState from './state';
import {PasswordResetAction} from './actions';

const InitialState: PasswordResetState = {
	requesting: false,
	success: false,
	errors: {},
};

export default function (state = InitialState, action: PasswordResetAction): PasswordResetState {
	switch (action.type) {
		case PASSWORD_RESET_REQUEST:
			return {
				requesting: true,
				success: false,
				errors: state.errors,
			};

		case PASSWORD_RESET_SUCCESS:
			return {
				requesting: false,
				success: true,
				errors: {},
			};

		case PASSWORD_RESET_ERROR:
			return {
				requesting: false,
				success: false,
				errors: action.errors,
			};

		case PASSWORD_RESET_CLEAR:
		case PASSWORD_RESET_CANCEL:
			return InitialState;

		default:
			return state;
	}
}
