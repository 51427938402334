import React, {useState, useEffect} from 'react';
import AdminUser from '../user';
import AdminUsersState from './state';
import {UsersRequestArguments, UsersSuccessArguments, BlockRequestArguments} from './actions';

export type AdminUsersProps = AdminUsersState & {
	usersRequestAction: (args: UsersRequestArguments) => void,
	usersSuccessAction: (args: UsersSuccessArguments) => void,
	blockRequestAction: (args: BlockRequestArguments) => void,
}

const AdminUsers: React.FunctionComponent<AdminUsersProps> = props => {
	const {
		requesting,
		errors,
		users,
		usersRequestAction,
		usersSuccessAction,
		blockRequestAction,
	} = props;

	const [page, setPage] = useState(0);

	useEffect(() => {
		if (!users || !users.data || !users.data.length)
			usersRequestAction({page: 0});
	}, [usersRequestAction, setPage, page, users]);

	useEffect(() => {
		if (errors.type === 'maxpage') {
			setPage(page - 1);
			usersSuccessAction({users});
		}
	}, [usersSuccessAction, users, setPage, page, errors]);

	const deleteUser = (id: number) => {
		if (window.confirm('Do you really want to block the user?')) {
			blockRequestAction({id, page});
		}
	};

	const changePage = (p: number) => {
		if (p < 0)
			return;

		usersRequestAction({page: p});
		setPage(p);
	};

	return (
		<div className="flex flex-col justify-center my-2 px-4 w-full max-w-screen-lg">
			<div className="flex flex-row justify-between w-full">
				<p>filter</p>
				<p>as of today</p>
			</div>
			<div className="flex flex-row justify-center w-full">
				<button onClick={() => changePage(page - 1)}>&lt;-</button>
				<p className="mx-2">{page + 1}</p>
				<button onClick={() => changePage(page + 1)}>-&gt;</button>
			</div>
			{!requesting ? (
				<div className="self-center max-w-screen-md w-full">
					{users.data.map((user, index) => (
						<AdminUser key={index} user={user} deleteUser={() => deleteUser(user.id)} />
					))}
				</div>
			) : (
				<p className="self-center">loading...</p>
			)}
		</div>
	)
};

export default AdminUsers;
