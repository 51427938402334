import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {RootState} from '../../../store/root-reducer';

import {facebookLoginAction, loginClearAction, loginRequestAction} from './actions';
import Login from './component';
import LoginState from './state';

const mapStateToProps = (state: RootState): LoginState => state.auth.login;
const actions = {
	loginRequestAction,
	facebookLoginAction,
	loginClearAction,
};

export default withRouter(connect(mapStateToProps, actions)(Login));
