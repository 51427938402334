export interface AuthData {
  accessToken: string,
  refreshToken: string,
}

export interface ExpirableAuthData extends AuthData {
	expiry: number,
}

export default class Auth {
	static isExpired(auth: ExpirableAuthData) {
		const isExpired = Math.floor(Date.now() / 1000) > auth.expiry;

		return isExpired;
	}

	static info(auth: AuthData) {
		return JSON.parse(atob(auth.accessToken.split('.')[1]));
	}
}
