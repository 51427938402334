import {addLocale, useLocale} from 'ttag';
import {DEFAULT_LOCALE, LOCALE_STORAGE_KEY, SUPPORTED_LOCALES} from './constants';

import Storage from '../../service/storage';

export function getLocale(): SUPPORTED_LOCALES {
	const queryLocale = (new URLSearchParams(window.location.search)).get('loc');

	if (queryLocale && Object.values(SUPPORTED_LOCALES).includes(queryLocale as SUPPORTED_LOCALES))
		return queryLocale as SUPPORTED_LOCALES;
	else
		return Storage.getItem(LOCALE_STORAGE_KEY) || DEFAULT_LOCALE;
}

export function saveLocale(locale: keyof typeof SUPPORTED_LOCALES) {
	Storage.setItem(LOCALE_STORAGE_KEY, locale);
}

const locale = getLocale();

if (locale !== SUPPORTED_LOCALES.EN) {
	const translationsObj = require(`./i18n/${locale}.po.json`);
	addLocale(locale as string, translationsObj);
}

useLocale(locale as string);
