import {call, put, takeLatest} from 'redux-saga/effects';

import ClientStorage from '../../../client/storage';
import UserApi from '../../../service/api/user';

import {
    usersErrorAction,
    usersSuccessAction,
    UsersRequestAction,
    blockErrorAction,
    blockSuccessAction,
    BlockRequestAction,
} from './actions';

import {USERS_REQUEST, BLOCK_REQUEST} from './constants';
import {safe} from '../../../service/functions';

function* usersRequest(action: UsersRequestAction) {
    let client = yield call(ClientStorage.getItem);

    let users = yield call(UserApi.getAllUsers, client.auth, action.page);

    if (users.data.length === 0) {
        yield put(usersErrorAction({type: 'maxpage'}));
        return;
    }

    yield put(usersSuccessAction({users}));
}

function* blockRequest(action: BlockRequestAction) {
    let client = yield call(ClientStorage.getItem);

    yield call(UserApi.deleteUser, client.auth, action.id);

    let users = yield call(UserApi.getAllUsers, client.auth, action.page);
    yield put(usersSuccessAction({users}));

    yield put(blockSuccessAction());
}

function* onUsersError() {
    yield put(usersErrorAction({input: 'epic fail'}));
}

function* onBlockError() {
    yield put(blockErrorAction({input: 'epic fail'}));
}

export default function* AdminUsersSaga() {
    yield takeLatest(USERS_REQUEST, safe([onUsersError], usersRequest));
    yield takeLatest(BLOCK_REQUEST, safe([onBlockError], blockRequest));
}
