import React, { useEffect, useState } from 'react';
import AdminDownloadsState from './state';
import InfoIcon from '../../../assets/icons/user-info-button.svg';
import EditButton from '../../../assets/icons/edit-button.png';
import DeleteButton from '../../../assets/icons/delete-button.png';
import { FileCreateRequestArguments, FileDeleteRequestArguments, FileUpdateRequestArguments } from './actions';
import { FileData } from '../../../service/api/files';
import { InfoBox } from './ui/info-box';
import { EditBox } from './ui/edit-box';

export type AdminDownloadsProps = AdminDownloadsState & {
    filesRequestAction: () => void;
    fileDeleteRequestAction: (args: FileDeleteRequestArguments) => void;
    fileUpdateRequestAction: (args: FileUpdateRequestArguments) => void;
    fileCreateRequestAction: (args: FileCreateRequestArguments) => void;
};

export const AdminDownloads: React.FC<AdminDownloadsProps> = (props) => {
    const { filesRequestAction, files, fileDeleteRequestAction, fileUpdateRequestAction, fileCreateRequestAction } = props;
    const [isEditing, setIsEditing] = useState<number | null>(null);
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [infoShown, setInfoShown] = useState<number | null>(null);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const deleteFile = (internalName: string) => {
        if (window.confirm('Are you sure you want to delete this file?')) {
            fileDeleteRequestAction({ internalName });
        }
    };
    const startEditing = (file: FileData, index: number) => {
        setIsCreatingNew(false);
        setIsEditing(index);
        setTitle(file.title);
        setDescription(file.description);
    };
    const stopEditing = () => {
        setIsEditing(null);
        setTitle('');
        setDescription('');
    };
    const applyChanges = (fileName: string) => {
        fileUpdateRequestAction({ internalName: fileName, title, description });
        stopEditing();
    };
    const createNew = () => {
        const formData = new FormData();
        formData.append('file', file!);
        fileCreateRequestAction({title, description, file: formData})
        stopEditing();
        setIsCreatingNew(false);
    }
    useEffect(() => {
        if (!files || !files.data || !files.data.length) {
            filesRequestAction();
        }
    }, [files, filesRequestAction]);
    return (
        <div className='min-h-screen admin-downloads text-xs'>
            <div
                className='add-new-button text-white text-center py-1 w-20 cursor-pointer mt-4'
                onClick={() => {
                    if (!isCreatingNew) {
                        stopEditing();
                        setIsCreatingNew(true);
                    } else setIsCreatingNew(false);
                }}
            >
                add new +
            </div>
            {isCreatingNew && (
                <EditBox
                    {...{ title, setTitle, description, setDescription, setFile }}
                    mode='new'
                    confirmChanges={() => {
                        if (!!file) return createNew();
                        return null;
                    }}
                    cancelChanges={() => setIsCreatingNew(false)}
                />
            )}
            {!!files && (
                <div className='flex flex-col text-xs'>
                    <p className='ml-2'>list of downloads</p>
                    {files.data.map((file, index) => (
                        <div key={`file-${index}`} className='my-2'>
                            <div className='flex items-center'>
                                <div className='flex file-name w-56 pl-4 pr-4'>
                                    <p className='py-2'>{file.name}</p>
                                    <img
                                        src={InfoIcon}
                                        alt='info'
                                        className='ml-auto cursor-pointer'
                                        onClick={() => {
                                            stopEditing();
                                            setInfoShown((prev) => (prev === index ? null : index));
                                        }}
                                    />
                                </div>
                                <img
                                    src={EditButton}
                                    alt='edit'
                                    className='w-8 cursor-pointer'
                                    onClick={() => {
                                        setInfoShown(null);
                                        if (isEditing !== index) startEditing(file, index);
                                        else stopEditing();
                                    }}
                                />
                                <img src={DeleteButton} alt='delete' className='w-8 cursor-pointer' onClick={() => deleteFile(file.internalName)} />
                            </div>
                            {infoShown === index && <InfoBox {...file} />}
                            {isEditing === index && (
                                <EditBox
                                    mode='edit'
                                    {...{ title, description, setTitle, setDescription }}
                                    confirmChanges={() => applyChanges(file.internalName)}
                                    cancelChanges={() => stopEditing()}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
