import React from 'react'

export type InfoBoxProps = {
    name: string;
    title: string;
    description: string
}

export const InfoBox: React.FC<InfoBoxProps> = (props) => {
    const {name, title, description} = props;
    return (
        <div className='bg-blue text-white info-box px-4 py-1 truncate'>
            <div className='flex justify-between my-1'>
                <p>name</p>
                <p>{name}</p>
            </div>
            <div className='flex justify-between my-1'>
                <p>title</p>
                <p>{title}</p>
            </div>
            <div className='flex justify-between my-1'>
                <p className='mr-8'>description</p>
                <p>{description}</p>
            </div>
        </div>
    );
}