import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import QR from './component';
import QRState from './state';
import {RootState} from '../../store/root-reducer';
import {qrRequestAction} from "./actions";

const mapStateToProps = (state: RootState): QRState => state.qr;
const actions = {
    qrRequestAction
};

export default withRouter(connect(mapStateToProps, actions)(QR));
