import {
	LOGINS_REQUEST,
	LOGINS_ERROR,
	LOGINS_SUCCESS,
	REGISTERS_REQUEST,
	REGISTERS_ERROR,
	REGISTERS_SUCCESS,
	QRCODES_REQUEST,
	QRCODES_ERROR,
	QRCODES_SUCCESS,
} from './constants';

import {AdminStatisticsAction} from './actions';
import AdminStatisticsState from './state';

const initialState: AdminStatisticsState = {
	requestingLogins: false,
	requestingRegisters: false,
	requestingQrcodes: false,
	success: false,
	errors: {},
	logins: {
		data: []
	},
	registers: {
		data: []
	},
	qrcodes: {
		data: []
	},
};


export default function (state = initialState, action: AdminStatisticsAction): AdminStatisticsState {
	switch (action.type) {
		case LOGINS_REQUEST:
			return {
				...state, ...{
					requestingLogins: true,
					success: false,
					errors: state.errors,
				}
			};

		case LOGINS_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requestingLogins: false,
					success: true,
					logins: action.logins,
				}
			};

		case LOGINS_ERROR:
			return {
				...state, ...{
					requestingLogins: false,
					success: false,
					errors: action.errors,
				}
			};

		case REGISTERS_REQUEST:
			return {
				...state, ...{
					requestingRegisters: true,
					success: false,
					errors: state.errors,
				}
			};

		case REGISTERS_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requestingRegisters: false,
					success: true,
					registers: action.registers,
				}
			};

		case REGISTERS_ERROR:
			return {
				...state, ...{
					requestingRegisters: false,
					success: false,
					errors: action.errors,
				}
			};

		case QRCODES_REQUEST:
			return {
				...state, ...{
					requestingQrcodes: true,
					success: false,
					errors: state.errors,
				}
			};

		case QRCODES_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requestingQrcodes: false,
					success: true,
					qrcodes: action.qrcodes,
				}
			};

		case QRCODES_ERROR:
			return {
				...state, ...{
					requestingQrcodes: false,
					success: false,
					errors: action.errors,
				}
			};

		default:
			return state;
	}
}
