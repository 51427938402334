import {call, put, takeLatest} from 'redux-saga/effects';

import EventApi from '../../../service/api/event';
import {monthBoundary, safe} from '../../../service/functions';

import {
    LoginsRequestAction,
    loginsErrorAction,
    loginsSuccessAction,
    RegistersRequestAction,
    registersErrorAction,
    registersSuccessAction,
    QrcodesRequestAction,
    qrcodesErrorAction,
    qrcodesSuccessAction,
} from './actions';

import {LOGINS_REQUEST, REGISTERS_REQUEST, QRCODES_REQUEST} from './constants';

function* loginsRequest(action: LoginsRequestAction) {
    let boundary = monthBoundary(action.page)
    let logins = yield call(EventApi.getEventsSinceUntil, 'login', boundary.since, boundary.until);

    yield put(loginsSuccessAction({logins}));
}

function* onLoginsError() {
    yield put(loginsErrorAction({input: 'epic fail'}));
}

function* registersRequest(action: RegistersRequestAction) {
    let boundary = monthBoundary(action.page)
    let registers = yield call(EventApi.getEventsSinceUntil, 'register', boundary.since, boundary.until);

    yield put(registersSuccessAction({registers}));
}

function* onRegistersError() {
    yield put(registersErrorAction({input: 'epic fail'}));
}

function* qrcodesRequest(action: QrcodesRequestAction) {
    let boundary = monthBoundary(action.page)
    let qrcodes = yield call(EventApi.getEventsSinceUntil, 'qr_code', boundary.since, boundary.until);

    yield put(qrcodesSuccessAction({qrcodes}));
}

function* onQrcodesError() {
    yield put(qrcodesErrorAction({input: 'epic fail'}));
}

export default function* AdminStatisticsSaga() {
    yield takeLatest(LOGINS_REQUEST, safe([onLoginsError], loginsRequest));
    yield takeLatest(REGISTERS_REQUEST, safe([onRegistersError], registersRequest));
    yield takeLatest(QRCODES_REQUEST, safe([onQrcodesError], qrcodesRequest));
}
