import React, {useEffect} from 'react';
import {UserData} from '../../client/state';
import {LoginRefreshArguments} from '../auth/login/actions';

import hero from '../../assets/characters/hero/hero_1.png';
import trollBuilding from '../../assets/buildings/HQtrol_base2_small.png';
import stalkerBuilding from '../../assets/buildings/HQstalker_base2_small.png';
import fanatik from '../../assets/characters/fanatik/fanatik-cervena-small.png';
import hater from '../../assets/characters/hater/hater-cervena-small.png';
import stalker from '../../assets/characters/stalker/stalker-cervena-small.png';
import troll from '../../assets/characters/troll/troll-cervena-small.png';
import logoOSF from '../../assets/icons/logo_osf.png';
import logoRW from '../../assets/icons/rw_logo.png';
import logoNightCall from '../../assets/icons/nightcall_logo.png';
import logoACF from '../../assets/icons/active_citizens_fund_logo.png';
import {BrowserHistory} from "history";

export type LandingState = {
    authenticated: boolean,
    user: UserData | null,
}

export type LandingProps = LandingState & {
    loginRefreshAction: (args: LoginRefreshArguments) => void,
    logoutAction: () => void,
    history: BrowserHistory,
}

const Landing: React.FunctionComponent<LandingProps> = props => {
    const {
        authenticated,
        logoutAction,
        loginRefreshAction,
        user,
        history,
    } = props;


    useEffect(() => {
        loginRefreshAction({});
    }, [loginRefreshAction, authenticated]);

    const playGame = () => {
        if(authenticated){
            history.push('/avatar');
            return;
        }
        history.push('/login', {game: true});
    };

    return (
        <div className="landing-body h-auto m-0 p-0">
            <div className="landing-topnav">
                <h1 className="h-full flex items-center justify-center">
                    HOPE
                </h1>
                {!authenticated && (
                    <div className="landing-topnav-right">
                        <a href="/login" className="landing-login-button">
                            Login
                        </a>
                        <a href="/signup" className="landing-signup-button">
                            Sign up
                        </a>
                    </div>
                )}
                {authenticated && (
                    <div className="landing-topnav-right">
                        {user && user.name && (
                            <p className="landing-user-text">
                            {user.name.length > 18 ? user.name.split(' ')[0] : (user.name ?? "")}
                            </p>
                        )}
                        <a href="/"
                           onClick={() => {
                               logoutAction();
                           }}
                           className="landing-login-button"
                        >
                            Log out
                        </a>
                    </div>
                )}
            </div>
            <div className="landing-hero">
                <div className="landing-shade"></div>
                <img src={hero} alt="Hero" className="landing-hero_1"/>
                <div className="landing-play-button text-blue">
                    <div className='a-div flex justify-center items-center w-full'>
                        <p className="cursor-pointer" onClick={playGame}>Play the game</p>
                    </div>
                </div>
                <img src={trollBuilding} alt="Town" className="landing-town_1"/>
                <img src={stalkerBuilding} alt="town_2" className="landing-town_2"/>

                <div className="landing-lore">
                    <p>
                        Príbeh HOPE ťa zavedie do malého slovenského
                        mestečka, kde sa moderným technológiám
                        podarilo zmeniť, rozhádať a navzájom
                        znepriateliť jeho obyvateľov. Je
                        na tebe, či sa pridáš k odboju a pomôžeš
                        vrátiť mestu priateľskú atmosféru.
                        Ochrániš mesto pred cynickými trollmi,
                        namosúrenými hejtermi, radikálnymi
                        fanatikmi a sliedivými stalkermi?
                    </p>
                </div>
            </div>
            <div className="landing-fractions">
                <p>Mesto je rozdelené na následujúce 4 frakcie. Je na tebe s kým pôjdeš do súboja.</p>
            </div>
            <div className="landing-enemies">
                <img className="w-32" src={fanatik} alt="Fanatic"/>
                <img className="w-32" src={hater} alt="Hater"/>
                <img className="w-32" src={stalker} alt="Stalker"/>
                <img className="w-32" src={troll} alt="Troll"/>
            </div>
            <div className="landing-contacts">
                <h2>Naši Partneri</h2>
                <div className="landing-partners">
                    <a href="https://revolware.com">
                        <img src={logoRW} alt="Revolware logo" id="haha"/>
                    </a>
                    <a href="https://osf.sk">
                        <img src={logoOSF} alt="OSF logo"/>
                    </a>
                    <a href="https://acfslovakia.sk/">
                        <img src={logoACF} alt="ACF logo"/>
                    </a>
                </div>
                <div className='flex flex-col justify-center music-credits items-center'>
                    <h2 className=''>Hudba</h2>
                    <div className='relative'>
                        <a href="http://nightcall.sk">
                            <img src={logoNightCall} alt="NightCall logo" className='w-12 m-0'/>
                        </a>
                        <span className='absolute'>Leo Clair</span>
                    </div>
                </div>
                <div className='flex justify-center downloads'>
                    <a href="/downloads">
                        <h2 className='underline text-link cursor-pointer'>Na stiahnutie</h2>
                    </a>
                </div>
                <h2>Kde nás nájdete</h2>
                <div className="landing-social">
                    <div>
                        <a href="https://www.facebook.com/hallofpositiveengagement/" rel="noopener noreferrer" target="_blank"><i className="fa fa-facebook facebook"/></a>
                        <a href="https://www.instagram.com/hall_of_positive_engagement/" rel="noopener noreferrer" target="_blank"><i className="fa fa-instagram instagram"/></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landing;
