import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Scenario from "./component";
import {RootState} from "../../../store/root-reducer";
import {areaClearAction, areaSetAction, areasRequestAction} from "./actions";
import AreasState from "./state";
import {currentQuestionSetAction, dialogClearAction} from "../dialog/actions";
import {scoreResetAction, startTimeSetAction} from "../scorepage/actions";

const mapStateToProps = (state: RootState): AreasState => state.game.scenario;
const actions = {
    areasRequestAction,
    areaSetAction,
    areaClearAction,
    currentQuestionSetAction,
    startTimeSetAction,
    dialogClearAction,
    scoreResetAction,
}

export default withRouter(connect(mapStateToProps, actions)(Scenario));
