import Action from '../../service/action';
import { Files } from '../../service/api/files';
import { Errors } from '../../service/errors/state';
import { DOWNLOADS_ERROR, DOWNLOADS_REQUEST, DOWNLOADS_SUCCESS } from './constants';

export interface FilesErrorArguments {
    errors: Errors;
}

export interface FilesSuccesArguments {
    files: Files;
}

export interface FilesRequestAction extends Action<typeof DOWNLOADS_REQUEST> {}
export interface FilesSuccessAction extends Action<typeof DOWNLOADS_SUCCESS>, FilesSuccesArguments {}
export interface FilesErrorAction extends Action<typeof DOWNLOADS_ERROR>, FilesErrorArguments {}

export type FilesAction = FilesRequestAction | FilesSuccessAction | FilesErrorAction;

export function filesRequestAction(): FilesRequestAction {
    return {
        type: DOWNLOADS_REQUEST,
    };
}

export function filesSuccessAction(args: FilesSuccesArguments): FilesSuccessAction {
    return {
        type: DOWNLOADS_SUCCESS,
        ...args,
    };
}

export function filesErrorsAction(errors: Errors): FilesErrorAction {
    return {
        type: DOWNLOADS_ERROR,
        errors,
    };
}
