import {request} from './request';
import {AuthData} from '../auth';
import {AccountData} from './auth';

export default class AccountApi {
    /**
     * Executes a request to get the account info from the server
     */
    static get(auth: AuthData): Promise<any> {
        return request('/account').get().auth(auth).json().execute();
    }

    /**
     *
     */
    static put(auth: AuthData, data: AccountData): Promise<any> {
        return request('/account').put(data).auth(auth).json().execute();
    }
}
