import {request} from './request';
import {AuthData} from '../auth';

export interface NewQuestionData {
    areaId: number,
    question: string,
    order: number
}

export interface QuestionData {
    id: number,
    areaId: number,
    question: string,
    order: number
}

export interface NewAnswerData {
    questionId: number,
    answer: string,
    message: string,
    hint: string,
    type: string
}

export interface AnswerData {
    id: number,
    questionId: number,
    answer: string,
    message: string,
    hint: string,
    type: string
}

export default class ConversationApi {
    /**
     * Executes a request to create new question
     */
    static createQuestion(auth: AuthData, {areaId, question, order}: NewQuestionData): Promise<QuestionData> {
        return request('/conversations/question').post({areaId, question, order}).auth(auth).json().execute() as Promise<QuestionData>;
    }

    /**
     * Executes a request to update question
     */
    static updateQuestion(auth: AuthData, {areaId, question, order}: NewQuestionData, id: number): Promise<QuestionData> {
        return request('/conversations/question/' + id).put({areaId, question, order}).auth(auth).json().execute() as Promise<QuestionData>;
    }

    /**
     * Executes a request to delete question
     */
    static deleteQuestion(auth: AuthData, id: number): Promise<any> {
        return request('/conversations/question/' + id).delete().auth(auth).execute();
    }

    /**
     * Executes a request to create new asnwer
     */
    static createAnswer(auth: AuthData, {questionId, answer, message, hint, type}: NewAnswerData): Promise<AnswerData> {
        return request('/conversations/answer').post({questionId, answer, message, hint, type}).auth(auth).json().execute() as Promise<AnswerData>;
    }

    /**
     * Executes a request to update answer
     */
    static updateAnswer(auth: AuthData, {questionId, answer, message, hint, type}: NewAnswerData, id: number): Promise<AnswerData> {
        return request('/conversations/answer/' + id).put({questionId, answer, message, hint, type}).auth(auth).json().execute() as Promise<AnswerData>;
    }

    /**
     * Executes a request to delete answer
     */
    static deleteAnswer(auth: AuthData, id: number): Promise<any> {
        return request('/conversations/answer/' + id).delete().auth(auth).execute();
    }
}
