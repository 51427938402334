import React, {useEffect, useState} from 'react';

import {Link} from 'react-router-dom';
import PasswordResetState from './state';
import {PasswordResetRequestArguments} from './actions';
import Error from "../../../service/errors";
import {t} from "ttag";
import {Errors} from "../../../service/errors/state";
import {BrowserHistory} from "history";

type PasswordResetProps = PasswordResetState & {
    passwordResetRequestAction: (args: PasswordResetRequestArguments) => void,
    passwordResetErrorAction: (errors: Errors) => void,
    passwordResetClearAction: () => void,
    history: BrowserHistory,
};

const PasswordReset: React.FunctionComponent<PasswordResetProps> = props => {
    const {
        passwordResetRequestAction,
        passwordResetClearAction,
        passwordResetErrorAction,
        requesting,
        errors,
        history,
    } = props;

    const [key, setKey] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    useEffect(() => {
        passwordResetClearAction();
        setKey('');
    }, [passwordResetClearAction]);

    if (requesting)
        return (
            <p>Requesting</p>
        );

    const resetPassword = () => {
        if (password.length < 8) {
            passwordResetErrorAction({
                input: t`Password has to be at least 8 characters long!`
            });
            return;
        }

        if (password !== passwordConfirm) {
            passwordResetErrorAction({
                input: t`Password confirmation does not match the password!`
            });
            return;
        }

        passwordResetRequestAction({
            token: key,
            password: password,
            callback: () => {
                history.push('/login');
            }
        });
    }

    return (
        <div className='page-container flex flex-grow justify-center items-center'>
            <div className='main-container flex flex-col justify-center items-center pt-6 w-full max-w-full h-auto'>
                <div className='m-8 mt-10'>
                    <Link to='/' className='text-2xl underline'>go to main page</Link>
                </div>
                <div className='form-container flex flex-col justify-center'>
                    <div className='flex flex-col flex-grow justify-center m-4 mb-3'>
                        <p className='input-header'>insert key</p>
                        <input
                            className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'key' ? 'bg-warning' : 'bg-opacity-25'}`}
                            type="text"
                            name="key"
                            value={key}
                            onChange={e => setKey(e.target.value)}
                        />
                    </div>
                    <div className='flex flex-col flex-grow justify-center m-4 mb-3'>
                        <p className='input-header'>new password</p>
                        <input
                            className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'password' ? 'bg-warning' : 'bg-opacity-25'}`}
                            type="password"
                            name="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='flex flex-col flex-grow justify-center m-4 mb-8'>
                        <p className='input-header'>retype password</p>
                        <input
                            className={`text-input ${errors.hasOwnProperty('type') && errors['type'] === 'passwordConfirm' ? 'bg-warning' : 'bg-opacity-25'}`}
                            type="password"
                            name="passwordConfirm"
                            value={passwordConfirm}
                            onChange={e => setPasswordConfirm(e.target.value)}
                        />
                    </div>
                    <Error {...errors} value='key'/>
                    <Error {...errors} value='input'/>
                    <div className='change-password-div flex flex-grow justify-center  m-5 mb-8'>
                        <button onClick={resetPassword} className='change-password-button text-button text-blue'>
                            change password
                        </button>
                    </div>
                    <div className='w-full flex justify-center'>
                        <div className='signup-login-container flex flex-row flex-grow m-5 mt-4 mb-20'>
                            <Link to='/signup' className='signup-button-half text-button text-blue mr-2'>
                                sign up
                            </Link>
                            <Link to='/login' className='login-button-half text-button'>
                                login
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;
