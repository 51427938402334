import {
	USERS_REQUEST,
	USERS_ERROR,
	USERS_SUCCESS,
	BLOCK_REQUEST,
	BLOCK_ERROR,
	BLOCK_SUCCESS,
} from './constants';

import {AdminUsersAction} from './actions';
import AdminUsersState from './state';

const initialState: AdminUsersState = {
	requesting: false,
	success: false,
	errors: {},
	users: {
		data: []
	},
};

export default function (state = initialState, action: AdminUsersAction): AdminUsersState {
	switch (action.type) {
		case USERS_REQUEST:
			return {
				...state, ...{
					requesting: true,
					success: false,
					errors: state.errors,
				}
			};

		case USERS_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requesting: false,
					success: true,
					users: action.users,
				}
			};

		case USERS_ERROR:
			return {
				...state, ...{
					requesting: false,
					success: false,
					errors: action.errors,
				}
			};

		case BLOCK_REQUEST:
			return {
				...state, ...{
					requesting: true,
					success: false,
					errors: state.errors,
				}
			};

		case BLOCK_SUCCESS:
			return {
				...state, ...{
					errors: {},
					requesting: false,
					success: true,
				}
			};

		case BLOCK_ERROR:
			return {
				...state, ...{
					requesting: false,
					success: false,
					errors: action.errors,
				}
			};

		default:
			return state;
	}
}
