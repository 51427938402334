import {
	CLIENT_USER_UPDATE,
	FACEBOOK_LOGIN_REQUEST,
	LOGIN_CANCEL,
	LOGIN_CLEAR,
	LOGIN_ERROR,
	LOGIN_REFRESH,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT,
} from './constants';
import {LoginCredentials} from '../../../service/api/auth';
import ClientState from '../../../client/state';
import Action from '../../../service/action';
import {Errors} from '../../../service/errors/state';

export interface LoginRefreshArguments {
	callback?: () => void,
}
export interface LoginErrorArguments {
	errors: Errors,
}
export interface LoginRequestArguments extends LoginCredentials {
	callback?: (args: ClientState) => void,
}

export interface ClientUserUpdateAction extends Action<typeof CLIENT_USER_UPDATE> {
	data: ClientState,
}
export interface LoginRefreshAction extends Action<typeof LOGIN_REFRESH>, LoginRefreshArguments {}
export interface LoginCancelAction extends Action<typeof LOGIN_CANCEL> {}
export interface LogoutAction extends Action<typeof LOGOUT> {}
export interface FacebookLoginRequestAction extends Action<typeof FACEBOOK_LOGIN_REQUEST> {}
export interface LoginErrorAction extends Action<typeof LOGIN_ERROR>, LoginErrorArguments {}
export interface LoginRequestAction extends Action<typeof LOGIN_REQUEST>, LoginRequestArguments {}
export interface LoginClearAction extends Action<typeof LOGIN_CLEAR> {}
export interface LoginSuccessAction extends Action<typeof LOGIN_SUCCESS> {}

export type LoginAction =
	ClientUserUpdateAction |
	LoginRefreshAction |
	LoginCancelAction |
	LogoutAction |
	FacebookLoginRequestAction |
	LoginErrorAction |
	LoginRequestAction |
	LoginClearAction |
	LoginSuccessAction;

export function loginRefreshAction(arg: LoginRefreshArguments): LoginRefreshAction {
	return {
		type: LOGIN_REFRESH,
		...arg,
	};
}


export function loginErrorAction(errors: Errors): LoginErrorAction {
	return {
		type: LOGIN_ERROR,
		errors,
	};
}


export function loginRequestAction(arg: LoginRequestArguments): LoginRequestAction {
	return {
		type: LOGIN_REQUEST,
		...arg,
	};
}

export function loginClearAction(): LoginClearAction {
	return {
		type: LOGIN_CLEAR,
	};
}

export function loginCancelAction(): LoginCancelAction {
	return {
		type: LOGIN_CANCEL,
	};
}

export function logoutAction(): LogoutAction {
	return {
		type: LOGOUT,
	};
}

export function facebookLoginAction(): FacebookLoginRequestAction {
	return {
		type: FACEBOOK_LOGIN_REQUEST,
	};
}

export function loginSuccessAction(): LoginSuccessAction {
	return {
		type: LOGIN_SUCCESS,
	};
}

export function clientUserUpdateAction(data: ClientState): ClientUserUpdateAction {
	return {
		type: CLIENT_USER_UPDATE,
		data,
	};
}
