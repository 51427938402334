import { AuthData } from '../auth';
import { request, Request } from './request';

export interface FileData {
    internalName: string;
    name: string;
    title: string;
    description: string;
}

export interface UpdateFileData {
    title?: string;
    description?: string;
}

export interface NewFileResponse {
    internalName: string;
}

export interface Files {
    data: FileData[];
}

export default class FilesApi {
    /**
     * Executes a request to get an array of all files saved on the server
     */
    static get(page = 0): Promise<Files> {
        return request(`/files?page=${page}&limit=10`).get().json().execute() as Promise<Files>;
    }

    /**
     * Executes a request to post and save a downloadable file on the server
     */
    static post(auth: AuthData, fileData: FormData): Promise<NewFileResponse> {
        const myRequest = () => new Request('/files', {mode: 'cors', headers: {'Accept': 'application/json'}})
        return myRequest().postFile(fileData).auth(auth).json().execute() as Promise<NewFileResponse>
    }

    /**
     * Executes a request to download a file specified by `fileName`
     */
    static getFile(fileName: string): Promise<File> {
        return request(`/files/${fileName}`).get().execute() as Promise<File>;
    }

    /**
     * Executes a request to delete a file specified by `fileName`
     */
    static delete(auth: AuthData, fileName: string): Promise<any> {
        return request(`/files/${fileName}`).delete().auth(auth).execute();
    }

    /**
     * Executes a request to update a file specified by `fileName`
     */
    static put(auth: AuthData, fileName: string, updatedFileData: UpdateFileData): Promise<FileData> {
        return request(`/files/${fileName}`).put(updatedFileData).auth(auth).execute() as Promise<FileData>;
    }
}
