import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

// TODO: https://stackoverflow.com/a/54292872/8483877 CC-BY-SA 4.0

export type AdminHeaderProps = RouteComponentProps & {
    logoutAction: () => void;
};

const AdminHeader: React.FunctionComponent<AdminHeaderProps> = (props) => {
    const { location, logoutAction } = props;

    let title = location.pathname.split('/').pop();
    if (location.pathname === '/admin') title = 'statistics';

    const [menuOpened, setMenuOpened] = useState(false);

    return (
        <div className='flex flex-row items-center justify-between px-6 py-5 bg-filled text-white w-full'>
            <div className='flex flex-row'>
                <div className='relative'>
                    <button type='button' onClick={() => setMenuOpened(!menuOpened)} className='bg-blue mr-3'>
                        |||
                    </button>
                    {menuOpened && (
                        <div className='flex flex-col absolute top-4 left-0 px-2 bg-blue text-white'>
                            <Link to='/admin/statistics' className='my-1'>
                                statistics
                            </Link>
                            <Link to='/admin/users' className='my-1'>
                                users
                            </Link>
                            <Link to='/admin/dialogues' className='my-1'>
                                dialogues
                            </Link>
                            <Link to='/admin/downloads' className='my-1'>
                                downloads
                            </Link>
                        </div>
                    )}
                </div>
                <p>{title}</p>
            </div>
            <div>
                <button onClick={logoutAction} className='bg-blue h-8 px-2'>
                    log out
                </button>
            </div>
        </div>
    );
};

export default AdminHeader;
