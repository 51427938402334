import {
    QR_REQUEST,
    QR_SUCCESS,
    QR_ERROR
} from './constants';
import Action from '../../service/action';
import {Errors} from '../../service/errors/state';

export interface QRErrorArguments {
    errors: Errors,
}
export interface QRRequestArguments {
    callback?: () => void,
}

export interface QRErrorAction extends Action<typeof QR_ERROR>, QRErrorArguments {}
export interface QRRequestAction extends Action<typeof QR_REQUEST>, QRRequestArguments {}
export interface QRSuccessAction extends Action<typeof QR_SUCCESS> {}

export type QRAction =
    QRErrorAction |
    QRRequestAction |
    QRSuccessAction;


export function qrErrorAction(errors: Errors): QRErrorAction {
    return {
        type: QR_ERROR,
        errors,
    };
}


export function qrRequestAction(arg: QRRequestArguments): QRRequestAction {
    return {
        type: QR_REQUEST,
        ...arg,
    };
}


export function qrSuccessAction(): QRSuccessAction {
    return {
        type: QR_SUCCESS,
    };
}
