import React from "react";
import {Errors} from './state';
import inputWarning from "../../assets/icons/input_warning.svg";
import {gettext} from 'ttag';

const Error = (errors:Errors) => {
    if(!errors.hasOwnProperty(errors.value))
        return <div/>;

    const error = errors[errors.value];

    return (
        <div className="flex flex-grow justify-start mb-6">
            <span className='text-error'>
                <img src={inputWarning} alt="warning" className='input-warning'/>
                    {gettext(error)}
            </span>
        </div>
    );
}
export default Error;
