import {connect} from 'react-redux';
import Admin from './component';
import {RootState} from "../../store/root-reducer";
import {loginRefreshAction} from "../auth/login/actions";
import {UserData} from "../../client/state";

const mapStateToProps = (state: RootState): {
    user: UserData | null,
    authenticated: boolean
} => {
    return {
        user: state.client.user,
        authenticated: !!state.client.auth,
    }
}

const actions = {
    loginRefreshAction
};


export default connect(mapStateToProps, actions)(Admin);
