import {
	AREAS_REQUEST,
	AREAS_ERROR,
	AREAS_SUCCESS,
	CONVERSATIONS_REQUEST,
	CONVERSATIONS_ERROR,
	CONVERSATIONS_SUCCESS,
	CONVERSATIONS_CLEAR,
	QUESTION_UPDATE_REQUEST,
	QUESTION_UPDATE_SUCCESS,
	QUESTION_UPDATE_ERROR,
	ANSWER_UPDATE_REQUEST,
	ANSWER_UPDATE_SUCCESS,
	ANSWER_UPDATE_ERROR,
	CONVERSATION_CREATE_REQUEST,
	CONVERSATION_CREATE_SUCCESS,
	CONVERSATION_CREATE_ERROR,
	CONVERSATION_DELETE_REQUEST,
	CONVERSATION_DELETE_SUCCESS,
	CONVERSATION_DELETE_ERROR,
} from './constants';

import Action from '../../../service/action';
import {Errors} from "../../../service/errors/state";
import {Areas, AreaData, ConversationData} from '../../../service/api/area';
import {NewQuestionData, NewAnswerData} from '../../../service/api/conversation';

export interface AreasSuccessArguments {
	areas: Areas,
}

export interface AreasErrorArguments {
	errors: Errors,
}

export interface ConversationsRequestArguments {
	area: AreaData,
}

export interface ConversationsSuccessArguments {
	conversations: ConversationData,
}

export interface ConversationsErrorArguments {
	errors: Errors,
}

export interface QuestionUpdateRequestArguments {
	id: number,
	body: NewQuestionData,
	callback?: () => void,
}

export interface QuestionUpdateErrorArguments {
	errors: Errors,
}

export interface AnswerUpdateRequestArguments {
	id: number,
	body: NewAnswerData,
	callback?: () => void,
}

export interface AnswerUpdateErrorArguments {
	errors: Errors,
}

export interface ConversationCreateRequestArguments {
	data: NewQuestionData,
}

export interface ConversationCreateErrorArguments {
	errors: Errors,
}

export interface ConversationDeleteRequestArguments {
	areaId: number,
	questionId: number,
}

export interface ConversationDeleteErrorArguments {
	errors: Errors,
}

export interface AreasRequestAction extends Action<typeof AREAS_REQUEST> {}
export interface AreasSuccessAction extends Action<typeof AREAS_SUCCESS>, AreasSuccessArguments {}
export interface AreasErrorAction extends Action<typeof AREAS_ERROR>, AreasErrorArguments {}
export interface ConversationsRequestAction extends Action<typeof CONVERSATIONS_REQUEST>, ConversationsRequestArguments {}
export interface ConversationsSuccessAction extends Action<typeof CONVERSATIONS_SUCCESS>, ConversationsSuccessArguments {}
export interface ConversationsErrorAction extends Action<typeof CONVERSATIONS_ERROR>, ConversationsErrorArguments {}
export interface ConversationsClearAction extends Action<typeof CONVERSATIONS_CLEAR> {}
export interface QuestionUpdateRequestAction extends Action<typeof QUESTION_UPDATE_REQUEST>, QuestionUpdateRequestArguments {}
export interface QuestionUpdateSuccessAction extends Action<typeof QUESTION_UPDATE_SUCCESS> {}
export interface QuestionUpdateErrorAction extends Action<typeof QUESTION_UPDATE_ERROR>, QuestionUpdateErrorArguments {}
export interface AnswerUpdateRequestAction extends Action<typeof ANSWER_UPDATE_REQUEST>, AnswerUpdateRequestArguments {}
export interface AnswerUpdateSuccessAction extends Action<typeof ANSWER_UPDATE_SUCCESS> {}
export interface AnswerUpdateErrorAction extends Action<typeof ANSWER_UPDATE_ERROR>, AnswerUpdateErrorArguments {}
export interface ConversationCreateRequestAction extends Action<typeof CONVERSATION_CREATE_REQUEST>, ConversationCreateRequestArguments {}
export interface ConversationCreateSuccessAction extends Action<typeof CONVERSATION_CREATE_SUCCESS> {}
export interface ConversationCreateErrorAction extends Action<typeof CONVERSATION_CREATE_ERROR>, ConversationCreateErrorArguments {}
export interface ConversationDeleteRequestAction extends Action<typeof CONVERSATION_DELETE_REQUEST>, ConversationDeleteRequestArguments {}
export interface ConversationDeleteSuccessAction extends Action<typeof CONVERSATION_DELETE_SUCCESS> {}
export interface ConversationDeleteErrorAction extends Action<typeof CONVERSATION_DELETE_ERROR>, ConversationDeleteErrorArguments {}

export type AdminDialoguesAction =
	AreasRequestAction |
	AreasSuccessAction |
	AreasErrorAction |
	ConversationsRequestAction |
	ConversationsSuccessAction |
	ConversationsErrorAction |
	ConversationsClearAction |
	QuestionUpdateRequestAction |
	QuestionUpdateSuccessAction |
	QuestionUpdateErrorAction |
	AnswerUpdateRequestAction |
	AnswerUpdateSuccessAction |
	AnswerUpdateErrorAction |
	ConversationCreateRequestAction |
	ConversationCreateSuccessAction |
	ConversationCreateErrorAction |
	ConversationDeleteRequestAction |
	ConversationDeleteSuccessAction |
	ConversationDeleteErrorAction;

export function areasRequestAction(): AreasRequestAction {
	return {
		type: AREAS_REQUEST
	};
}

export function areasSuccessAction(args: AreasSuccessArguments): AreasSuccessAction {
    return {
        type: AREAS_SUCCESS,
        ...args
    };
}

export function areasErrorAction(errors: Errors): AreasErrorAction {
    return {
        type: AREAS_ERROR,
        errors
    };
}

export function conversationsRequestAction(args: ConversationsRequestArguments): ConversationsRequestAction {
	return {
		type: CONVERSATIONS_REQUEST,
		...args
	};
}

export function conversationsSuccessAction(args: ConversationsSuccessArguments): ConversationsSuccessAction {
    return {
        type: CONVERSATIONS_SUCCESS,
        ...args
    };
}

export function conversationsErrorAction(errors: Errors): ConversationsErrorAction {
    return {
        type: CONVERSATIONS_ERROR,
        errors
    };
}

export function conversationsClearAction(): ConversationsClearAction {
	return {
		type: CONVERSATIONS_CLEAR,
	};
}

export function questionUpdateRequestAction(args: QuestionUpdateRequestArguments): QuestionUpdateRequestAction {
	return {
		type: QUESTION_UPDATE_REQUEST,
		...args
	};
}

export function questionUpdateSuccessAction(): QuestionUpdateSuccessAction {
    return {
        type: QUESTION_UPDATE_SUCCESS,
    };
}

export function questionUpdateErrorAction(errors: Errors): QuestionUpdateErrorAction {
    return {
        type: QUESTION_UPDATE_ERROR,
        errors
    };
}

export function answerUpdateRequestAction(args: AnswerUpdateRequestArguments): AnswerUpdateRequestAction {
	return {
		type: ANSWER_UPDATE_REQUEST,
		...args
	};
}

export function answerUpdateSuccessAction(): AnswerUpdateSuccessAction {
    return {
        type: ANSWER_UPDATE_SUCCESS,
    };
}

export function answerUpdateErrorAction(errors: Errors): AnswerUpdateErrorAction {
    return {
        type: ANSWER_UPDATE_ERROR,
        errors
    };
}

export function conversationCreateRequestAction(args: ConversationCreateRequestArguments): ConversationCreateRequestAction {
	return {
		type: CONVERSATION_CREATE_REQUEST,
		...args
	};
}

export function conversationCreateSuccessAction(): ConversationCreateSuccessAction {
    return {
        type: CONVERSATION_CREATE_SUCCESS,
    };
}

export function conversationCreateErrorAction(errors: Errors): ConversationCreateErrorAction {
    return {
        type: CONVERSATION_CREATE_ERROR,
        errors
    };
}

export function conversationDeleteRequestAction(args: ConversationDeleteRequestArguments): ConversationDeleteRequestAction {
	return {
		type: CONVERSATION_DELETE_REQUEST,
		...args
	};
}

export function conversationDeleteSuccessAction(): ConversationDeleteSuccessAction {
    return {
        type: CONVERSATION_DELETE_SUCCESS,
    };
}

export function conversationDeleteErrorAction(errors: Errors): ConversationDeleteErrorAction {
    return {
        type: CONVERSATION_DELETE_ERROR,
        errors
    };
}
