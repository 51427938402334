import {connect} from 'react-redux'

import Auth, {AuthProps} from './component';
import {RootState} from '../../store/root-reducer';

const mapStateToProps = (state: RootState): AuthProps => ({
    authenticated: !!state.client.auth,
});

export default connect(mapStateToProps, null)(Auth);
