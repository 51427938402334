import {AVATAR_CLEAR, AVATAR_ERROR, AVATAR_REQUEST, AVATAR_SET, AVATAR_SUCCESS} from './constants';
import Action from '../../../service/action';
import {CharacterList, CharacterData} from "../../../service/api/character";
import {Errors} from "../../../service/errors/state";

export interface AvatarSetArguments {
    hero: CharacterData
}

export interface AvatarSuccessArguments {
    avatars: CharacterList
}

export interface AvatarErrorArguments {
    errors: Errors
}

export interface AvatarRequestAction extends Action<typeof AVATAR_REQUEST> {}
export interface AvatarSuccessAction extends Action<typeof AVATAR_SUCCESS>, AvatarSuccessArguments {}
export interface AvatarSetAction extends Action<typeof AVATAR_SET>, AvatarSetArguments {}
export interface AvatarClearAction extends Action<typeof AVATAR_CLEAR> {}
export interface AvatarErrorAction extends Action<typeof AVATAR_ERROR>, AvatarErrorArguments {}

export type AvatarAction =
    AvatarRequestAction |
    AvatarSuccessAction |
    AvatarSetAction |
    AvatarClearAction |
    AvatarErrorAction;

export function avatarRequestAction(): AvatarRequestAction {
    return {
        type: AVATAR_REQUEST
    };
}

export function avatarSuccessAction(args: AvatarSuccessArguments): AvatarSuccessAction {
    return {
        type: AVATAR_SUCCESS,
        ...args
    };
}

export function avatarSetAction(hero: CharacterData): AvatarSetAction {
    return {
        type: AVATAR_SET,
        hero
    };
}

export function avatarClearAction(): AvatarClearAction {
    return {
        type: AVATAR_CLEAR
    };
}

export function avatarErrorAction(errors: Errors): AvatarErrorAction {
    return {
        type: AVATAR_ERROR,
        errors
    };
}
