import {request} from './request';
import {AuthData} from '../auth';

export interface LoginCredentials {
	email: string,
	password: string,
}

export interface PasswordResetCredentials {
	token: string,
	password: string,
}

export interface ResetTokenCredentials {
	email: string,
}

export interface UserData {
	name: string,
	age: number,
	gender: string,
}

export interface SignupData {
	username: string,
	email: string,
	password: string,
}

export interface AccountData extends UserData {
	username: string,
}

export interface LoginFacebookCredentials {
	accessToken: string,
}

export interface FacebookLoginData {
	authResponse: {
		accessToken: string,
		data_access_expiration_time: number,
		expiresIn: number,
		grantedScopes: string,
		graphDomain: string,
		signedRequest: string,
		userID: string,
	},
	status: string,
}

export default class AuthApi {
	/**
	 * Tries to login with credentials to server
	 */
	static login({email, password}: LoginCredentials): Promise<any> {
		return request('/auth/login').post({email, password}).json().execute();
	}

	/**
	 * Tries to login with facebook token to server
	 */
	static loginFacebook({accessToken}: LoginFacebookCredentials): Promise<any> {
		return request('/auth/facebook').post({accessToken}).json().execute();
	}

	/**
	 * Refreshes authentication tokens
	 */
	static refresh(auth: AuthData): Promise<AuthData> {
		return request('/auth/refresh').post(auth).json().execute() as Promise<AuthData>;
	}

	/**
	 * Tries to reset password
	 */
	static resetPassword({token, password}: PasswordResetCredentials): Promise<any> {
		return request('/auth/password-reset').post({token, password}).json().execute();
	}

	/**
	 * Tries to request reset password token
	 */
	static resetToken({email}: ResetTokenCredentials): Promise<any> {
		return request('/auth/password-reset/get-token').post({email}).execute();
	}

	/**
	 * Tries to register a new user with signup data
	 */
	static signup(data: SignupData): Promise<any> {
		return request('/auth/register').post(data).json().execute();
	}
};
