import {
    CURRENT_QUESTION_SET,
    DIALOG_CLEAR,
    DIALOG_ERROR,
    DIALOG_REQUEST,
    DIALOG_SUCCESS,
    LAST_ANSWER_SET,
    LIVES_SET,
} from './constants';
import {DialogAction} from './actions';
import DialogState from './state';

const initialState: DialogState = {
    requesting: false,
    success: false,
    errors: {},
    conversations: null,
    currentQuestion: 0,
    lastAnswer: null,
    lives: 3,
};

export default function (state = initialState, action: DialogAction): DialogState {
    switch (action.type) {
        case DIALOG_REQUEST:
            return {
                ...state, ...{
                    requesting: true,
                    success: false,
                    errors: state.errors,
                }
            };

        case DIALOG_SUCCESS:
            return {
                ...state, ...{
                    errors: {},
                    requesting: false,
                    success: true,
                    conversations: action.conversations,
                }
            };

        case DIALOG_ERROR:
            return {
                ...state, ...{
                    requesting: false,
                    success: false,
                    errors: action.errors,
                }
            };

        case DIALOG_CLEAR:
            return {
                ...initialState,
            };

        case CURRENT_QUESTION_SET:
            return {
                ...state, ...{
                    currentQuestion: action.currentQuestion,
                }
            };

        case LAST_ANSWER_SET:
            return {
                ...state, ...{
                    lastAnswer: action.lastAnswer,
                }
            };

        case LIVES_SET:
            return {
                ...state, ...{
                    lives: action.lives,
                }
            };

        default:
            return state;
    }
}
