import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Downloads from './component';
import { RootState } from '../../store/root-reducer';
import { logoutAction, loginRefreshAction } from '../auth/login/actions';
import { filesRequestAction, filesErrorsAction, filesSuccessAction } from './actions';
import DownloadsState from './state';

const mapStateToProps = (state: RootState): DownloadsState => {
    return {
        ...state.downloads,
        authenticated: !!state.client.auth,
        user: state.client.user,
    };
};
const actions = {
    filesRequestAction,
    filesSuccessAction,
    filesErrorsAction,
    loginRefreshAction,
    logoutAction,
};

export default withRouter(connect(mapStateToProps, actions)(Downloads));
