import { AdminDownloadsAction } from './actions';
import {
    DOWNLOADS_ERROR,
    DOWNLOADS_REQUEST,
    DOWNLOADS_SUCCESS,
    FILE_CREATE_ERROR,
    FILE_CREATE_REQUEST,
    FILE_CREATE_SUCCESS,
    FILE_DELETE_ERROR,
    FILE_DELETE_REQUEST,
    FILE_DELETE_SUCCESS,
    FILE_UPDATE_ERROR,
    FILE_UPDATE_REQUEST,
    FILE_UPDATE_SUCCESS,
} from './constants';
import AdminDownloadsState from './state';

const initialState: AdminDownloadsState = {
    requesting: false,
    success: false,
    errors: {},
    files: {
        data: [],
    },
};

export default function (state = initialState, action: AdminDownloadsAction): AdminDownloadsState {
    switch (action.type) {
        case DOWNLOADS_REQUEST:
            return {
                ...state,
                ...{
                    requesting: true,
                    success: false,
                    errors: state.errors,
                },
            };

        case DOWNLOADS_SUCCESS:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: true,
                    errors: {},
                    files: action.files,
                },
            };

        case DOWNLOADS_ERROR:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: false,
                    errors: state.errors,
                },
            };

        case FILE_DELETE_REQUEST:
            return {
                ...state,
                ...{
                    requesting: true,
                    success: false,
                    errors: state.errors,
                },
            };

        case FILE_DELETE_SUCCESS:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: true,
                    errors: {},
                },
            };

        case FILE_DELETE_ERROR:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: false,
                    errors: state.errors,
                },
            };

        case FILE_UPDATE_REQUEST:
            return {
                ...state,
                ...{
                    requesting: true,
                    success: false,
                    errors: state.errors,
                },
            };

        case FILE_UPDATE_SUCCESS:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: true,
                    errors: state.errors,
                },
            };

        case FILE_UPDATE_ERROR:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: false,
                    errors: state.errors,
                },
            };

        case FILE_CREATE_REQUEST:
            return {
                ...state,
                ...{
                    requesting: true,
                    success: false,
                    errors: state.errors,
                },
            };

        case FILE_CREATE_SUCCESS:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: true,
                    errors: {},
                },
            };

        case FILE_CREATE_ERROR:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: false,
                    errors: state.errors,
                },
            };

        default:
            return state;
    }
}
