export enum GameState {
    game_won,
    game_lost,
    life_lost,
    question_passed,
    playing,
}

export default interface ScoreState {
    game_state: GameState,
    score: number,
    start_time: number,
    end_time: number,
}
