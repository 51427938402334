import {call, put, takeLatest} from 'redux-saga/effects';

import EventApi, {Event} from '../../service/api/event';

import {
    qrErrorAction, QRRequestAction,
    qrSuccessAction,
} from './actions';

import {QR_REQUEST} from './constants';
import {browserNameFromAgent, safe} from '../../service/functions';

function* qrRequest(action: QRRequestAction) {
    const userAgent = window.navigator.userAgent;
    const browserName = browserNameFromAgent(userAgent);

    const event = {
        name: 'qr_code',
        data: JSON.stringify({
            'browser': browserName,
        }),
    } as Event;
    let response = yield call(EventApi.registerEvent, event.name, event.data);

    if (response.ok || response.status === 200) {
        yield put(qrSuccessAction());
    } else {
        yield put(qrErrorAction({input: 'Couldn\'t register event'}));
    }

    if (action.callback) {
        yield call(action.callback);
    }
}

function* onError() {
    yield put(qrErrorAction({input: 'Couldn\'t register event'}));
}

export default function* QRSaga() {
    yield takeLatest(QR_REQUEST, safe([onError], qrRequest));
}
