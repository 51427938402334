import { FilesAction } from './actions';
import { DOWNLOADS_ERROR, DOWNLOADS_SUCCESS, DOWNLOADS_REQUEST } from './constants';
import DownloadsState from './state';

const initialState: DownloadsState = {
    requesting: false,
    success: false,
    errors: {},
    files: {
        data: [],
    },
    authenticated: false,
    user: null,
};

export default function (state = initialState, action: FilesAction): DownloadsState {
    switch (action.type) {
        case DOWNLOADS_REQUEST:
            return {
                ...state,
                ...{
                    requesting: true,
                    success: false,
                    errors: {},
                },
            };

        case DOWNLOADS_SUCCESS:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: true,
                    errors: {},
                    files: action.files,
                },
            };

        case DOWNLOADS_ERROR:
            return {
                ...state,
                ...{
                    requesting: false,
                    success: false,
                    errors: action.errors,
                },
            };

        default:
            return state;
    }
}
