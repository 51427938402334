import {request} from './request';
import {AuthData} from '../auth';

export interface User {
	id: number,
	username: string,
	email: string,
	password: string,
	picture: string,
	age: number,
	gender: string,
	name: string,
	blocked: boolean,
}

export interface UserPrivate {
	id: number,
	username: string,
	age: number,
	gender: string,
	picture: string,
	name: string,
	blocked: boolean,
}

export interface UsersPrivate {
	data: UserPrivate[],
}

export interface UserLogin {
	email: string,
	password: string,
}

export interface UserRegister {
	id: number,
	username: string,
	email: string,
	password: string,
	picture: string,
	age: number,
	gender: string,
	name: string,
	blocked: boolean,
}

export interface AreasHistory {
	id: number,
	areaId: number,
	userId: number,
	points: number,
	start: number,
	end: number,
}

export interface AreaHistories {
	data: AreasHistory[],
}

export interface GameData {
	id: number,
	areas: number,
	userId: number,
	characterId: number,
}

export default class UserApi {
	static getAllUsers(auth: AuthData, page: number): Promise<UsersPrivate> {
		return request(`/user/all?page=${page}&limit=16`).get().auth(auth).json().execute() as Promise<UsersPrivate>;
	}

	static getUser(auth: AuthData, id: number): Promise<UserPrivate> {
		return request('/user/' + id).get().auth(auth).json().execute() as Promise<UserPrivate>;
	}

	static deleteUser(auth: AuthData, id: number): Promise<UserPrivate> {
		return request('/user/' + id).delete().auth(auth).json().execute() as Promise<UserPrivate>;
	}

	static getUserGameData(auth: AuthData, id: number): Promise<GameData> {
		return request('/user/' + id + '/game-data').get().auth(auth).json().execute() as Promise<GameData>;
	}

	static getUserAreaHistory(auth: AuthData, id: number): Promise<AreaHistories> {
		return request('/user/' + id + '/area-history').get().auth(auth).json().execute() as Promise<AreaHistories>;
	}
}
