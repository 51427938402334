import {AVATAR_CLEAR, AVATAR_ERROR, AVATAR_REQUEST, AVATAR_SET, AVATAR_SUCCESS} from './constants';
import {AvatarAction} from './actions';
import AvatarState from "./state";

const initialState: AvatarState = {
    hero: null,
    requesting: false,
    success: false,
    errors: {},
    avatars: {
        data: []
    }
};

export default function (state = initialState, action: AvatarAction): AvatarState {
    switch (action.type) {
        case AVATAR_REQUEST:
            return {
                ...state, ...{
                    requesting: true,
                    success: false,
                    errors: state.errors,
                }
            };
        case AVATAR_SUCCESS:
            return {
                ...state, ...{
                    errors: {},
                    requesting: false,
                    success: true,
                    avatars: action.avatars,
                }
            };
        case AVATAR_SET:
            return {
                ...state, ...{
                    hero: action.hero,
                }
            };

        case AVATAR_ERROR:
            return {
                ...state, ...{
                    requesting: false,
                    success: false,
                    errors: action.errors,
                }
            };

        case AVATAR_CLEAR:
            return initialState;

        default:
            return state;
    }
}
