export const DOWNLOADS_REQUEST = 'DOWNLOADS_REQUEST';
export const DOWNLOADS_SUCCESS = 'DOWNLOADS_SUCCESS';
export const DOWNLOADS_ERROR = 'DOWNLOADS_ERROR';

export const FILE_CREATE_REQUEST = 'FILE_CREATE_REQUEST';
export const FILE_CREATE_SUCCESS = 'FILE_CREATE_SUCCESS';
export const FILE_CREATE_ERROR = 'FILE_CREATE_ERROR';

export const FILE_UPDATE_REQUEST = 'FILE_UPDATE_REQUEST';
export const FILE_UPDATE_SUCCESS = 'FILE_UPDATE_SUCCESS';
export const FILE_UPDATE_ERROR = 'FILE_UPDATE_ERROR';

export const FILE_DELETE_REQUEST = 'FILE_DELETE_REQUEST';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_ERROR = 'FILE_DELETE_ERROR';
