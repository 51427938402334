import {call, put, takeLatest} from 'redux-saga/effects';

import {RESET_TOKEN_REQUEST} from './constants';
import {resetTokenErrorAction, ResetTokenRequestAction, resetTokenSuccessAction} from './actions';
import AuthApi from '../../../service/api/auth';
import HttpResponseCode from '../../../service/api/responseCode';
import {t} from 'ttag';
import {safe} from '../../../service/functions';

function* resetToken(action: ResetTokenRequestAction) {
    for (let [key, value] of Object.entries(action)) {
        if (!value) {
            yield put(resetTokenErrorAction({input: t`Please fill ` + key + `.`, type: key}));
            return;
        }
    }
    const result = yield call(AuthApi.resetToken, action);
    if (result.status === HttpResponseCode.OK) {
        yield put(resetTokenSuccessAction());
        if (action.callback)
            yield call(action.callback);
    } else {
        const message = yield new Promise((resolve) => {
            result.json().then((result: { message: string }) => resolve(result.message));
        });
        yield put(resetTokenErrorAction({input: message}));
    }
}

function* onError() {
    yield put(resetTokenErrorAction({input: t`Reset password error.` + ' - ' + t`server not responding`}));
}

export default function* ResetTokenSaga() {
    yield takeLatest(RESET_TOKEN_REQUEST, safe([onError], resetToken));
}
