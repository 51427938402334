import {
	FACEBOOK_LOGIN_REQUEST,
	LOGIN_CANCEL,
	LOGIN_CLEAR,
	LOGIN_ERROR,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT,
} from './constants';
import {LoginAction} from './actions';
import LoginState from './state';

const InitialState: LoginState = {
	requesting: false,
	success: false,
	errors: {},
};

export default function (state = InitialState, action: LoginAction): LoginState {
	switch (action.type) {
		case LOGIN_REQUEST:
		case FACEBOOK_LOGIN_REQUEST:
			return {
				requesting: true,
				success: false,
				errors: state.errors,
			};

		case LOGIN_SUCCESS:
			return {
				requesting: false,
				success: true,
				errors: {},
			};

		case LOGIN_ERROR:
			return {
				requesting: false,
				success: false,
				errors: action.errors,
			};

		case LOGIN_CANCEL:
		case LOGIN_CLEAR:
		case LOGOUT:
			return InitialState;

		default:
			return state;
	}
}
