import React, {RefObject, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ReactCardCarousel from "react-card-carousel";
import {t} from 'ttag';
import AvatarState from "./state";
import {BrowserHistory} from "history";
import {Errors} from "../../../service/errors/state";
import {CharacterData} from "../../../service/api/character";
import introvideo from "../../../assets/video/intro_video.mp4";
import MusicToggler from '../../music-toggler';

export type AvatarProps = AvatarState & {
    avatarRequestAction: () => void,
    avatarSetAction: (hero: CharacterData) => void,
    avatarClearAction: () => void,
    hero: CharacterData | null,
    errors: Errors,
    history: BrowserHistory,
}

const Avatar: React.FunctionComponent<AvatarProps> = props => {
    const {
        avatarRequestAction,
        avatarClearAction,
        avatarSetAction,
        avatars,
        hero,
        history,
        requesting,
        success,
    } = props;

    const [character, setCharacter] = useState(0);
    const [videoPlaying, setVideoPlaying] = useState(true);
    const [videoMuted, setVideoMuted] = useState(false)
    const myRef: RefObject<any> = React.createRef();

    const scrollLeft = () => {
        myRef.current.prev();
        setCharacter((character - 1) < 0 ? (avatars.data.length - 1) : (character - 1));
    };

    const scrollRight = () => {
        myRef.current.next();
        setCharacter((character + 1) % avatars.data.length);
    };

    const confirmHero = () => {
        if (success && avatars && avatars.data) {
            let characterDataS = avatars.data[character];
            const characterData = {
                id: characterDataS.id,
                name: characterDataS.name,
                picture: characterDataS.picture,
            } as CharacterData;
            avatarSetAction(characterData);

            history.push('/scenario');
        }
    };

    const picturesUrl = process.env.REACT_APP_STATIC_ENDPOINT+"/pictures/";

    useEffect(() => {
        if (!requesting && !hero && (!avatars || !avatars.data.length)) {
            avatarClearAction();
            avatarRequestAction();
        }
        setVideoMuted((prev) => {
            if (!!window.localStorage.getItem('MUSIC_DATA')) {
                const isMuted: boolean = JSON.parse(window.localStorage.getItem('MUSIC_DATA')!)['MUSIC_MUTED']
                return isMuted;
            } else {
                return false;
            }
        })
    }, [avatarRequestAction, avatars, avatarClearAction, hero, requesting]);

    return (
        <>
        {videoPlaying && (
            <div className='absolute top-0 left-0 w-full h-screen blured-bg overflow-hidden' />
        )}
        <div className={'page-container flex flex-grow justify-center' + (videoPlaying ? ' h-screen bg-none' : '')}>
            {videoPlaying && (
                <div className='w-full flex justify-center main-container'>
                    <video src={introvideo} autoPlay={true} onEnded={()=>setVideoPlaying(false)} muted={videoMuted} className='border-8 border-black h-screen'></video>
                    <button className='absolute bottom-0 right-0 skip-video-button mr-10' onClick={()=>{
                        setVideoPlaying(false)
                    }}>Preskočiť video</button>
                    <MusicToggler handleChange={() => setVideoMuted(prev => !prev)} />
                </div>
            )}
            {!videoPlaying && (
            <div className='main-container flex flex-col justify-center items-center pt-6 w-full max-w-full h-auto top-0'>
                <div className='flex justify-center m-2'>
                    <Link to='/' className='text-link underline'>
                        {'< '}{t`back`}
                    </Link>
                </div>
                <div className='flex justify-center mt-6'>
                    {t`select avatar`}
                </div>
                {(requesting && !success) && (
                    <div className='avatar-carousel mt-2'>
                        <div className='self-center text-xl'>{t`Fetching avatars`}</div>
                    </div>
                )}
                {(!requesting && success) && (
                    <div className='avatar-carousel mt-2'>
                        {avatars.data &&
                        <ReactCardCarousel ref={myRef} autoplay={false}>
                            {avatars.data.map((value, index) => (
                                <div key={`avatar-${index}`} className='avatar-slide cursor-pointer'
                                     onClick={confirmHero}
                                     style={{backgroundImage: 'url(' + picturesUrl + value.picture + ')'}}>
                                </div>
                            ))}
                        </ReactCardCarousel>
                        }
                    </div>
                )}
                <div className='arrow-buttons flex flex-row justify-between mt-2'>
                    <button className='arrow-button-left h-8' onClick={scrollLeft}/>
                    <button className='arrow-button-right h-8' onClick={scrollRight}/>
                </div>
                <div className='select-div'>
                    <button className='avatar-select-button text-button text-blue mt-2'
                            onClick={confirmHero}>
                        {t`select`}
                    </button>
                </div>
            </div>
            )}
        </div>
        </>
    );
}

export default Avatar;
