import React, {useCallback, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {t} from 'ttag';

import correctIcon from '../../../assets/icons/green_correct_icon.svg';
import sosak from '../../../assets/icons/question-sosak.png';
import heart from '../../../assets/icons/hearth.svg';
import troll from '../../../assets/characters/troll/troll-cervena.png';
import hater from '../../../assets/characters/hater/hater-cervena.png';
import fanatik from '../../../assets/characters/fanatik/fanatik-cervena.png';
import stalker from '../../../assets/characters/stalker/stalker-cervena.png';
import {AreaData, Conversation} from "../../../service/api/area";
import {BrowserHistory} from "history";
import DialogState from "./state";
import {
    CurrentQuestionSetArguments,
    DialogRequestArguments,
    LastAnswerSetArguments,
    LivesSetArguments
} from "./actions";
import {GameState} from "../scorepage/state";
import {EndTimeSetArguments, GameStateSetArguments, ScoreSetArguments} from "../scorepage/actions";
import {Errors} from "../../../service/errors/state";
import {AnswerData} from "../../../service/api/conversation";
import {CharacterData} from "../../../service/api/character";
import {AreaHistoryPostArguments} from "../scenario/actions";
import correct1music from "../../../assets/music/spravne1.wav";
import wrong1music from "../../../assets/music/wrong1.wav";
import wrong3music from "../../../assets/music/wrong3.wav";
import MusicToggler from '../../music-toggler';

export type DialogProps = DialogState & {
    dialogRequestAction: (args: DialogRequestArguments) => void,
    currentQuestionSetAction: (args: CurrentQuestionSetArguments) => void,
    gameStateSetAction: (args: GameStateSetArguments) => void,
    livesSetAction: (args: LivesSetArguments) => void,
    scoreSetAction: (args: ScoreSetArguments) => void,
    endTimeSetAction: (args: EndTimeSetArguments) => void,
    lastAnswerSetAction: (args: LastAnswerSetArguments) => void,
    areaHistoryPostAction: (args: AreaHistoryPostArguments) => void,
    errors: Errors,
    lives: number,
    score: number,
    hero: CharacterData | null,
    area: AreaData | null,
    start_time: number,
    history: BrowserHistory,
};

const Dialog: React.FunctionComponent<DialogProps> = props => {
    const {
        dialogRequestAction,
        currentQuestionSetAction,
        gameStateSetAction,
        endTimeSetAction,
        scoreSetAction,
        livesSetAction,
        lastAnswerSetAction,
        areaHistoryPostAction,
        errors,
        lives,
        score,
        hero,
        area,
        conversations,
        currentQuestion,
        start_time,
        history,
        requesting,
        success,
        lastAnswer,
    } = props;

    const [enemy, setEnemy] = useState<string>("");
    const [dialog, setDialog] = useState<Conversation[] | null>(null);
    const [question, setQuestion] = useState("");
    const [answers, setAnswers] = useState<AnswerData[]>([]);
    const [oldQuestion, setOldQuestion] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState<AnswerData | null>(null)
    const [time, setTime] = useState(0);
    const [refresher, setRefresher] = useState<any>(null);
    const [loadTime, setLoadTime] = useState(0);
    const [animating, setAnimating] = useState(0);
    const [soundsMuted, setSoundsMuted] = useState(false);


    const enemies = {
        "hater": hater,
        "troll": troll,
        "stalker": stalker,
        "fanatik": fanatik,
    }

    const refreshTime = useCallback(() => {
        const refreshSpeed = 500;
        let fnc = setTimeout(refreshTime, refreshSpeed);
        setRefresher(fnc);
        setTime((new Date()).getTime());
    }, [])


    useEffect(() => {
        if (!area || errors.length) {
            history.push('/avatar');
        }
        if ((area && conversations === null && !requesting) || (area && !enemy)) {
            dialogRequestAction({area});
            setEnemy(area.name);
        }
        if (conversations && !dialog) {
            setDialog(conversations.data);
        }
        if (dialog && (!dialog[currentQuestion] || !dialog[currentQuestion].answers.length) && currentQuestion < dialog!.length) {
            setOldQuestion(currentQuestion);
            currentQuestionSetAction({currentQuestion: currentQuestion + 1});
        }
        if (dialog && dialog[currentQuestion] && (!question || !answers)) {
            setQuestion(dialog[currentQuestion].question);
            setAnswers(dialog[currentQuestion].answers.sort(() => .5 - Math.random()));
        }
        if (dialog && dialog[currentQuestion] && oldQuestion !== currentQuestion) {
            setQuestion(dialog[currentQuestion].question);
            setOldQuestion(currentQuestion);
            setAnswers(dialog[currentQuestion].answers.sort(() => .5 - Math.random()));
        }

        if (!requesting && start_time && refresher === null) {
            refreshTime();
        }

        if (refresher) {
            if (conversations && success)
                setTime((new Date()).getTime());
            else
                setLoadTime(loadTime => (loadTime + 1) % 3);
        }
        setSoundsMuted((prev) => {
            if (!!window.localStorage.getItem('MUSIC_DATA')) {
                const isMuted: boolean = JSON.parse(window.localStorage.getItem('MUSIC_DATA')!)['MUSIC_MUTED']
                return isMuted;
            } else {
                return false;
            }
        })
    }, [refreshTime, dialog, question, answers, area, conversations,
        currentQuestion, dialogRequestAction, enemy, history, oldQuestion,
        start_time, requesting, refresher, errors, currentQuestionSetAction, success]);

    const getEnemy = (val: string) => {
        return enemies.hasOwnProperty(val) ? (enemies as any)[val] : null;
    }

    const triggerGameLost = () => {
        gameStateSetAction({game_state: GameState.game_lost});
        clearTimeout(refresher);
        endTimeSetAction({end_time: time});
        history.push('/scorepage');
    }

    const triggerGameWon = () => {
        gameStateSetAction({game_state: GameState.game_won});
        history.push('/scorepage');
        clearTimeout(refresher);
        endTimeSetAction({end_time: time});
        areaHistoryPostAction({
            area: area!,
            data: {
                points: score + 1,
                start: Math.floor(start_time / 1000),
                end: Math.floor(time / 1000),
            }
        });
        currentQuestionSetAction({currentQuestion: 0});
    }

    const triggerLifeLost = () => {
        gameStateSetAction({game_state: GameState.life_lost});
        livesSetAction({lives: lives - 1});
        history.push('/scorepage');
        clearTimeout(refresher);
    }

    const triggerQuestionPassed = () => {
        gameStateSetAction({game_state: GameState.question_passed});
        history.push('/scorepage');
        clearTimeout(refresher);
        currentQuestionSetAction({currentQuestion: currentQuestion + 1});
    }

    const resetAnimations = () => {
        document.querySelector('.correct-icon')?.remove();
        document.querySelector('.answered-right')?.classList.remove('answered-right');
        document.querySelector('.answered-right-1')?.classList.remove('answered-right-1');
        document.querySelector('.answered-right-2')?.classList.remove('answered-right-2');
        document.querySelector('.answered-right-3')?.classList.remove('answered-right-3');
        setAnimating(0);
    }

    const triggerAnimation = (givenAnswer: AnswerData, index: number) => {
        setAnimating(1);
        if (givenAnswer.type === "r") {
            const indices = ['answer-1', 'answer-2', 'answer-3'].filter(i => i !== `answer-${index}`);
            const bubble = document.getElementById(`answer-${index}`)!;
            bubble.classList.add('answered-right', `answered-right-${index}`, 'answer-disappear');
            bubble.appendChild(Object.assign(document.createElement('img'), {
                src: correctIcon,
                className: `correct-icon correct-icon-${index}`
            }));
            document.getElementById(indices[0])?.classList.add('not-answered-anim');
            document.getElementById(indices[1])?.classList.add('not-answered-anim');
            document.querySelector('.question')!.classList.add('question-disappear');
            const music = new Audio(correct1music)
            if (!soundsMuted) music.play()
        } else if (givenAnswer.type === "s") {
            document.getElementsByClassName('heart-img')[0].classList.add('heart-anim');
            document.getElementsByClassName('dialog-container')[0].classList.add('dialog-anim');
            document.getElementById(`answer-${index}`)?.classList.add('answered-wrong');
            const music = new Audio(wrong3music);
            if (!soundsMuted) music.play();
        } else {
            const hearts = document.getElementsByClassName('heart-img');
            document.querySelector("#answers-box")?.animate([
                {opacity: 1},
                {opacity: 0}
            ], {duration: 1000, fill: 'forwards', iterations: 1});
            let j = 0;
            for (let i = 0; i < hearts.length; i++) {
                j = i
                setTimeout(() => {
                    hearts[i]?.classList.add('lose-hearts-anim')
                }, i*500)
            }
            setTimeout(() => {
                document.getElementsByClassName('dialog-container')[0].classList.add('dialog-anim-after-hearts');
            }, 2500 + (j*500))
            const music = new Audio(wrong1music);
            if (!soundsMuted) music.play();           
        }
    }

    const chooseAnswer = (answer: AnswerData) => {
        lastAnswerSetAction({lastAnswer: answer});
        if (answer.type === 'r') {
            if (lastAnswer?.type !== 's') {
                scoreSetAction({score: score + 1});
            } else {
                scoreSetAction({score: score + 0.5});
            }
            if (currentQuestion === dialog!.length - 1) {
                triggerGameWon();
            } else {
                /* *
                * TODO: ENABLE WHEN IMPLEMENTING SCOREPAGE CORRECT ANSWER PAGE
                * gameStateSetAction({game_state: GameState.playing});
                * history.push('/scorepage');
                * clearTimeout(refresher);
                * */
                triggerQuestionPassed();
            }
        } else if (answer.type === 's' && lives > 1) {
            if (lastAnswer?.answer !== answer.answer) {
                scoreSetAction({score: score + 0.5});
            }
            triggerLifeLost();
        } else {
            if (answer.type === 's' && lastAnswer?.answer !== answer.answer) scoreSetAction({score: score + 0.5})
            triggerGameLost();
        }
    }

    const getSecondsPassed = () => {
        let diff = new Date(time - start_time);
        let seconds = diff.getUTCSeconds();
        let minutes = diff.getUTCMinutes();

        if (minutes >= 59 && seconds > 58) {
            triggerGameLost();
        }

        const secondsStr = seconds < 10 ? '0' + seconds : seconds;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        return minutesStr + ':' + secondsStr;
    }

    const picturesUrl = "https://hope.revolware.com/pictures/";

    return (
        <div className={'dialog page-container flex flex-grow justify-center ' + enemy}>
            <div className='main-container flex flex-col items-center justify-center pt-6 w-full max-w-full h-auto top-0'>
                {(requesting && !success) && (
                    <div className='self-center text-xl'>{t`Starting game`}{'.'.repeat(loadTime + 1)}</div>
                )}
                {(!requesting && success) && (
                    <div key={score} className='dialog-container' onAnimationEnd={(e) => {
                        if (e.animationName === 'question-dis' || e.animationName === 'dialog-aim') {
                            resetAnimations();
                            chooseAnswer(selectedAnswer!);
                        }
                    }}>
                        <div className='header flex flex-col justify-between p-4 m-2'>
                            <div className='flex flex-row justify-between'>
                                <Link to='/scenario' onClick={() => {clearTimeout(refresher);}}
                                      className='text-link underline  mr-8'>
                                    {'< '}{t`back`}
                                </Link>
                                <div className='flex flex-row ml-8'>
                                    {lives && [...Array.from({length: lives}, (_, i) => i + 1)].map((value) => (
                                        <img key={`heart-${value}`} src={heart} alt='heart' className="heart-img"/>
                                    ))}
                                </div>
                            </div>
                            <div className='timer flex justify-center mt-6'>
                                {getSecondsPassed()}
                            </div>
                            <div className='question-container w-full mt-10'>
                                <img className='oponent flex flex-row justify-between h-full' src={getEnemy(enemy)}
                                     alt='enemy'/>
                                <div className='question-text-div flex justify-end text-blue h-full p-4 pt-4 question question-appear'>
                                    <p className='question-text text-center flex items-center justify-center'>{question}</p>
                                    <img className='question-sosak' src={sosak} alt='question'/>
                                </div>
                            </div>
                            <div className='flex flex-row justify-end items-center mt-2'>
                                <div className='flex justify-center ml-12 w-full'>
                                    <p>Vyber odpoveď</p>
                                </div>
                                <img className='hero-img' src={`${picturesUrl}${hero ? hero.picture : ""}`} alt='hero'/>
                            </div>
                            <div className='delimeter-div flex justify-center h-1 mt-2'>
                                <div className='delimeter h-full'/>
                            </div>
                            <div id="answers-box" className='flex flex-col justify-center items-center text-blue mb-10'>
                                {answers && answers.map((value, index) => (
                                    <div key={`answer-${index}`} className='answer not-answered flex flex-col'
                                         id={`answer-${index + 1}`}>
                                        <button onClick={() => {
                                            if (!animating) {
                                                triggerAnimation(value, index + 1);
                                                setSelectedAnswer(value);
                                            }
                                        }}>
                                            {value.answer}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <MusicToggler handleChange={() => setSoundsMuted(prev => !prev)} />
            </div>
        </div>
    );

}

export default Dialog;
