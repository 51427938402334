import React, {useState} from 'react';
import {UserPrivate} from '../../../service/api/user';

type AdminUserProps = {
	user: UserPrivate,
	deleteUser: () => void,
};

const AdminUser: React.FunctionComponent<AdminUserProps> = props => {
	const {
		user,
		deleteUser,
	} = props;

	const {
		id,
		username,
		age,
		gender,
		name,
	} = user;

	const [opened, setOpened] = useState(false);

	return (
		<div className="admin-users m-1">
			<div className="flex flex-row">
				<div className="flex flex-row justify-between flex-grow bg-placeholder px-2 py-1">
					<p>{name ?? (<i>{username}</i>) ?? (<i>---</i>)}</p>
					<button className="user-info-button -my-1 -mx-2 px-4 w-6" onClick={() => setOpened(!opened)}></button>
				</div>
				{/*<button className="edit-user-button bg-warning text-center ml-1 p-1 w-8"></button>*/}
				<button className="block-user-button bg-filled text-center ml-1 p-1 w-8" onClick={() => deleteUser()}></button>
			</div>
			{opened && (
				<div className="px-6 pt-1 w-full bg-blue">
					<table className="w-full text-white">
						<tbody>
							<tr>
								<td>id</td>
								<td className="text-center">{id ?? (<i>---</i>)}</td>
							</tr>
							<tr>
								<td>username</td>
								<td className="text-center">{username ?? (<i>---</i>)}</td>
							</tr>
							<tr>
								<td>age</td>
								<td className="text-center">{age ?? (<i>---</i>)}</td>
							</tr>
							<tr>
								<td>gender</td>
								<td className="text-center">{gender ?? (<i>---</i>)}</td>
							</tr>
							<tr>
								<td>name</td>
								<td className="text-center">{name ?? (<i>---</i>)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default AdminUser;
