import {call, put, takeLatest} from 'redux-saga/effects';
import {t} from 'ttag';

import ClientStorage from '../../../client/storage';
import ConversationApi from '../../../service/api/conversation';
import AreaApi from '../../../service/api/area';

import {
    areasSuccessAction,
    areasErrorAction,
    conversationsSuccessAction,
    conversationsErrorAction,
    ConversationsRequestAction,
    questionUpdateSuccessAction,
    questionUpdateErrorAction,
    QuestionUpdateRequestAction,
    answerUpdateSuccessAction,
    answerUpdateErrorAction,
    AnswerUpdateRequestAction,
    conversationCreateSuccessAction,
    conversationCreateErrorAction,
    ConversationCreateRequestAction,
    conversationDeleteSuccessAction,
    conversationDeleteErrorAction,
    ConversationDeleteRequestAction,
} from './actions';

import {
    AREAS_REQUEST,
    CONVERSATIONS_REQUEST,
    QUESTION_UPDATE_REQUEST,
    ANSWER_UPDATE_REQUEST,
    CONVERSATION_CREATE_REQUEST,
    CONVERSATION_DELETE_REQUEST,
} from './constants';
import {loginRefreshAction} from '../../auth/login/actions';
import {safe} from '../../../service/functions';

function* areasRequest() {
    let client = yield call(ClientStorage.getItem);

    let areas = yield call(AreaApi.get, client.auth);

    yield put(areasSuccessAction({areas}));
}

function* onAreasError() {
    yield put(areasErrorAction({input: t`Areas error.` + ' - ' + t`server not responding`}));
}

function* conversationsRequest(action: ConversationsRequestAction) {
    let client = yield call(ClientStorage.getItem);

    let conversations = yield call(AreaApi.getAreaConversations, client.auth, action.area);

    yield put(conversationsSuccessAction({conversations: {data: conversations}}));
}

function* onConversationsError() {
    yield put(conversationsErrorAction({input: t`Areas error.` + ' - ' + t`server not responding`}));
}

function* questionUpdateRequest(action: QuestionUpdateRequestAction) {
    let client = yield call(ClientStorage.getItem);

    yield call(ConversationApi.updateQuestion, client.auth, action.body, action.id);

    yield put(questionUpdateSuccessAction());
    if (action.callback) {
        yield call(action.callback);
    }
}

function* onQuestionError() {
    yield put(questionUpdateErrorAction({input: t`Areas error.` + ' - ' + t`server not responding`}));
}

function* answerUpdateRequest(action: AnswerUpdateRequestAction) {
    let client = yield call(ClientStorage.getItem);

    yield call(ConversationApi.updateAnswer, client.auth, action.body, action.id);

    yield put(answerUpdateSuccessAction());
    if (action.callback) {
        yield call(action.callback);
    }
}

function* onAnswersError() {
    yield put(answerUpdateErrorAction({input: t`Areas error.` + ' - ' + t`server not responding`}));
}

function* conversationCreateRequest(action: ConversationCreateRequestAction) {
    let client = yield call(ClientStorage.getItem);

    let question = yield call(ConversationApi.createQuestion, client.auth, action.data);

    yield call(ConversationApi.createAnswer, client.auth, {
        questionId: question.id,
        answer: ' ',
        message: ' ',
        hint: ' ',
        type: 'r',
    });
    yield call(ConversationApi.createAnswer, client.auth, {
        questionId: question.id,
        answer: ' ',
        message: ' ',
        hint: ' ',
        type: 's',
    });
    yield call(ConversationApi.createAnswer, client.auth, {
        questionId: question.id,
        answer: ' ',
        message: ' ',
        hint: ' ',
        type: 'w',
    });

    let conversations = yield call(AreaApi.getAreaConversations, client.auth, {id: action.data.areaId, name: ''});
    yield put(conversationsSuccessAction({conversations: {data: conversations}}));

    yield put(conversationCreateSuccessAction());
}

function* onConversationCreateError() {
    yield put(conversationCreateErrorAction({input: t`Areas error.` + ' - ' + t`server not responding`}));
}

function* conversationDeleteRequest(action: ConversationDeleteRequestAction) {
    let client = yield call(ClientStorage.getItem);

    yield call(ConversationApi.deleteQuestion, client.auth, action.questionId);

    // yield call(ConversationApi.deleteAnswer, action.idR);
    // yield call(ConversationApi.deleteAnswer, action.idS);
    // yield call(ConversationApi.deleteAnswer, action.idW);

    let conversations = yield call(AreaApi.getAreaConversations, client.auth, {id: action.areaId, name: ''});
    yield put(conversationsSuccessAction({conversations: {data: conversations}}));

    yield put(conversationDeleteSuccessAction());
}

function* onConversationDeleteError() {
    yield put(conversationDeleteErrorAction({input: t`Areas error.` + ' - ' + t`server not responding`}));
}

function* onError() {
    yield put(loginRefreshAction({}));
}

export default function* AdminDialoguesSaga() {
    yield takeLatest(AREAS_REQUEST, safe([onAreasError, onError], areasRequest));
    yield takeLatest(CONVERSATIONS_REQUEST, safe([onConversationsError, onError], conversationsRequest));
    yield takeLatest(QUESTION_UPDATE_REQUEST, safe([onQuestionError, onError], questionUpdateRequest));
    yield takeLatest(ANSWER_UPDATE_REQUEST, safe([onAnswersError, onError], answerUpdateRequest));
    yield takeLatest(CONVERSATION_CREATE_REQUEST, safe([onConversationCreateError, onError], conversationCreateRequest));
    yield takeLatest(CONVERSATION_DELETE_REQUEST, safe([onConversationDeleteError, onError], conversationDeleteRequest));
}
