import {all} from 'redux-saga/effects';

import AuthSaga from '../components/auth/sagas';
import GameSaga from '../components/game/sagas';
import AdminSaga from '../components/admin/sagas';
import QRSaga from '../components/qr/sagas';

export default function* RootSaga() {
	yield all([
		AuthSaga(),
		GameSaga(),
		AdminSaga(),
		QRSaga(),
	]);
}
