import {request} from './request';

export interface Event {
	id: number,
	name: string,
	data: string,
}

export interface Events {
	data: Event[],
}

export default class EventApi {
	static registerEvent(name:string, data:string): Promise<any> {
		return request('/event').post({name, data}).execute();
	}

	static getEvents(name: string): Promise<Events> {
		return request('/event/' + name).get().json().execute() as Promise<Events>;
	}

	static getEventsSince(name: string, since: string): Promise<Events> {
		return request(`/event/${name}?since=${since}`).get().json().execute() as Promise<Events>;
	}

	static getEventsUntil(name: string, until: string): Promise<Events> {
		return request(`/event/${name}?until=${until}`).get().json().execute() as Promise<Events>;
	}

	static getEventsSinceUntil(name: string, since: string, until: string): Promise<Events> {
		return request(`/event/${name}?since=${since}&until=${until}`).get().json().execute() as Promise<Events>;
	}
}
