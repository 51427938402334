import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {logoutAction} from "../../auth/login/actions";
import AdminHeader from './component';

const actions = {
    logoutAction,
};

export default withRouter(connect(null, actions)(AdminHeader));
