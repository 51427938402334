import {connect} from 'react-redux';
import {RootState} from "../../../store/root-reducer";
import AdminStatistics from './component';
import {loginsRequestAction, registersRequestAction, qrcodesRequestAction} from './actions';
import AdminStatisticsState from './state';

const mapStateToProps = (state: RootState): AdminStatisticsState => state.admin.statistics;

const actions = {
	loginsRequestAction,
	registersRequestAction,
	qrcodesRequestAction,
};

export default connect(mapStateToProps, actions)(AdminStatistics);
