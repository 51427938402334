import {
	LOGINS_REQUEST,
	LOGINS_SUCCESS,
	LOGINS_ERROR,
	REGISTERS_REQUEST,
	REGISTERS_SUCCESS,
	REGISTERS_ERROR,
	QRCODES_REQUEST,
	QRCODES_SUCCESS,
	QRCODES_ERROR,
} from './constants';

import Action from '../../../service/action';
import {Errors} from "../../../service/errors/state";
import {Events} from '../../../service/api/event';

export interface LoginsRequestArguments {
	page: number,
}

export interface LoginsSuccessArguments {
	logins: Events,
}

export interface LoginsErrorArguments {
	errors: Errors,
}

export interface RegistersRequestArguments {
	page: number,
}

export interface RegistersSuccessArguments {
	registers: Events,
}

export interface RegistersErrorArguments {
	errors: Errors,
}

export interface QrcodesRequestArguments {
	page: number,
}

export interface QrcodesSuccessArguments {
	qrcodes: Events,
}

export interface QrcodesErrorArguments {
	errors: Errors,
}

export interface LoginsRequestAction extends Action<typeof LOGINS_REQUEST>, LoginsRequestArguments {}
export interface LoginsSuccessAction extends Action<typeof LOGINS_SUCCESS>, LoginsSuccessArguments {}
export interface LoginsErrorAction extends Action<typeof LOGINS_ERROR>, LoginsErrorArguments {}
export interface RegistersRequestAction extends Action<typeof REGISTERS_REQUEST>, RegistersRequestArguments {}
export interface RegistersSuccessAction extends Action<typeof REGISTERS_SUCCESS>, RegistersSuccessArguments {}
export interface RegistersErrorAction extends Action<typeof REGISTERS_ERROR>, RegistersErrorArguments {}
export interface QrcodesRequestAction extends Action<typeof QRCODES_REQUEST>, QrcodesRequestArguments {}
export interface QrcodesSuccessAction extends Action<typeof QRCODES_SUCCESS>, QrcodesSuccessArguments {}
export interface QrcodesErrorAction extends Action<typeof QRCODES_ERROR>, QrcodesErrorArguments {}

export type AdminStatisticsAction =
	LoginsRequestAction |
	LoginsSuccessAction |
	LoginsErrorAction |
	RegistersRequestAction |
	RegistersSuccessAction |
	RegistersErrorAction |
	QrcodesRequestAction |
	QrcodesSuccessAction |
	QrcodesErrorAction;

export function loginsRequestAction(args: LoginsRequestArguments): LoginsRequestAction {
	return {
		type: LOGINS_REQUEST,
		...args
	};
}

export function loginsSuccessAction(args: LoginsSuccessArguments): LoginsSuccessAction {
    return {
        type: LOGINS_SUCCESS,
        ...args
    };
}

export function loginsErrorAction(errors: Errors): LoginsErrorAction {
    return {
        type: LOGINS_ERROR,
        errors
    };
}

export function registersRequestAction(args: RegistersRequestArguments): RegistersRequestAction {
	return {
		type: REGISTERS_REQUEST,
		...args
	};
}

export function registersSuccessAction(args: RegistersSuccessArguments): RegistersSuccessAction {
    return {
        type: REGISTERS_SUCCESS,
        ...args
    };
}

export function registersErrorAction(errors: Errors): RegistersErrorAction {
    return {
        type: REGISTERS_ERROR,
        errors
    };
}

export function qrcodesRequestAction(args: QrcodesRequestArguments): QrcodesRequestAction {
	return {
		type: QRCODES_REQUEST,
		...args
	};
}

export function qrcodesSuccessAction(args: QrcodesSuccessArguments): QrcodesSuccessAction {
    return {
        type: QRCODES_SUCCESS,
        ...args
    };
}

export function qrcodesErrorAction(errors: Errors): QrcodesErrorAction {
    return {
        type: QRCODES_ERROR,
        errors
    };
}
