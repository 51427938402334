import React from 'react';

import { t } from 'ttag';

const AppFooter = () => (
    <div className='h-16 bg-black text-white flex-shrink-0 flex items-center justify-center z-10'>
        <p className='footer'>
            &copy; {t`Created in programme HOPE - OSF Bratislava by`}{' '}
            <a rel='noopener noreferrer' href='https://revolware.com/' target='_blank'>
                Revolware
            </a>
            , 2020. {t`All rights reserved`}.
        </p>
    </div>
);

export default AppFooter;
