import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Dialog from "./component";

import {RootState} from "../../../store/root-reducer";
import {currentQuestionSetAction, dialogRequestAction, lastAnswerSetAction, livesSetAction} from "./actions";
import DialogState from "./state";
import {AreaData} from "../../../service/api/area";
import {CharacterData} from "../../../service/api/character";
import {endTimeSetAction, gameStateSetAction, scoreSetAction} from "../scorepage/actions";
import {areaHistoryPostAction} from "../scenario/actions";

const mapStateToProps = (state: RootState): DialogState &
    {
        score: number,
        hero: CharacterData | null,
        area: AreaData | null,
        start_time: number,
    } => {
    return {
        ...state.game.dialog,
        score: state.game.scorepage.score,
        hero: state.game.avatar.hero,
        area: state.game.scenario.area,
        start_time: state.game.scorepage.start_time,
    }
};

const actions = {
    dialogRequestAction,
    currentQuestionSetAction,
    gameStateSetAction,
    livesSetAction,
    scoreSetAction,
    lastAnswerSetAction,
    endTimeSetAction,
    areaHistoryPostAction,
};


export default withRouter(connect(mapStateToProps, actions)(Dialog));
