import {t} from "ttag";
import React, {useState} from "react";
import {BrowserHistory} from "history";
import ResetTokenState from "./state";
import {ResetTokenRequestArguments} from "./actions";
import {LoginModalContext} from "../login/component";
import Error from "../../../service/errors";

type ResetTokenProps = ResetTokenState & {
    resetTokenRequestAction: (args: ResetTokenRequestArguments) => void,
    history: BrowserHistory,
};

const ResetToken: React.FunctionComponent<ResetTokenProps> = props => {
    const {
        resetTokenRequestAction,
        history,
        errors,
    } = props;

    const [email, setEmail] = useState('');

    const resetPassword = () => {
        resetTokenRequestAction({
            email: email,
            callback: () => {
                history.push('/password-reset');
            }
        });
    };

    return (
        <div id='modal' className='w-full h-full'>
            <div id='purple-block' className='flex justify-center items-center w-full'>
                <LoginModalContext.Consumer>
                    {value => (
                    <button id='purple-block-exit'
                            onClick={value ?? (() => {})}>
                    </button>
                    )}
                </LoginModalContext.Consumer>

                <div id='purple-form' className='flex flex-col justify-center items-center'>
                    <div className='flex flex-col flex-grow justify-center mb-3 w-full'>
                        <p className='input-header'>
                            {t`enter your email`}
                        </p>
                        <input className={`text-input bg-opacity-25 h-2/3 ${errors.hasOwnProperty('type') && errors['type'] === 'email' ? 'bg-warning' : 'bg-opacity-25'}`}
                               type="email"
                               name="email"
                               value={email}
                               onChange={e => setEmail(e.target.value)}
                        />
                        <Error {...errors} value='input'/>
                    </div>

                    <button onClick={resetPassword}
                            className='request-button text-button text-blue mb-6'>
                        {t`send request`}
                    </button>

                    <p className='text-center'>
                        {t`We will send You an email with verification key to reset your password. You will be redirected after.`}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ResetToken;
